import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosForecastRiskInstance as axios } from "axios.js";

const initialState = {
  loadVsHedgeData: { list: [], loader: false },
  loadVsHedgeLongData: { list: [], loader: false },
  loadVsHedgeLongDataByBlock: { list: [], loader: false }
};
export const getRiskPostionLoadVsHedge = createAsyncThunk(
  "risk/getRiskPostionLoadVsHedge",
  () => {
    const response = axios
      .get(`/api/v1/get_position_load_vs_hedge_details`)
      .then((res) => {
        return res;
      });
    return response;
  }
);
export const getRiskPostionLoadVsHedgeLong = createAsyncThunk(
  "risk/getRiskPostionLoadVsHedgeLong",
  () => {
    const response = axios
      .get(`/api/v1/get_position_load_vs_hedge_long_details`)
      .then((res) => {
        return res;
      });
    return response;
  }
);
export const getRiskPostionLoadVsHedgeLongByBlock = createAsyncThunk(
  "risk/getRiskPostionLoadVsHedgeLongByBlock",
  () => {
    const response = axios
      .get(`/api/v1/get_position_load_vs_hedge_long_details_by_block`)
      .then((res) => {
        return res;
      });
    return response;
  }
);
export const riskPositionSliceEngie = createSlice({
  name: "risk/position/slice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRiskPostionLoadVsHedge.pending, (state) => {
        state.loadVsHedgeData.loader = true;
      })
      .addCase(
        getRiskPostionLoadVsHedge.fulfilled,
        (state, action) => {
          state.loadVsHedgeData.loader = false;
          state.loadVsHedgeData.list = action?.payload?.data.position_load_vs_hedge_details || [];
        }
      )
      .addCase(
        getRiskPostionLoadVsHedge.rejected,
        (state, action) => {
          state.loadVsHedgeData.loader = false;
        }
      )
      .addCase(getRiskPostionLoadVsHedgeLong.pending, (state) => {
        state.loadVsHedgeLongData.loader = true;
      })
      .addCase(
        getRiskPostionLoadVsHedgeLong.fulfilled,
        (state, action) => {
          state.loadVsHedgeLongData.loader = false;
          state.loadVsHedgeLongData.list = action?.payload?.data.position_load_vs_hedge_details || [];
        }
      )
      .addCase(
        getRiskPostionLoadVsHedgeLong.rejected,
        (state, action) => {
          state.loadVsHedgeLongData.loader = false;
        }
      )
      .addCase(getRiskPostionLoadVsHedgeLongByBlock.pending, (state) => {
        state.loadVsHedgeLongDataByBlock.loader = true;
      })
      .addCase(
        getRiskPostionLoadVsHedgeLongByBlock.fulfilled,
        (state, action) => {
          state.loadVsHedgeLongDataByBlock.loader = false;
          state.loadVsHedgeLongDataByBlock.list = action?.payload?.data.position_load_vs_hedge_details || [];
        }
      )
      .addCase(
        getRiskPostionLoadVsHedgeLongByBlock.rejected,
        (state, action) => {
          state.loadVsHedgeLongDataByBlock.loader = false;
        }
      );

  },
});
export default riskPositionSliceEngie.reducer;
