import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosForecastInstance as axios } from "axios.js";

const INITIAL_STATE = {
  get_filtered_data: false,

  utilityHeatMapFilterData: {
    loader: false,
    dropDownListItems: {
      batch_dates:[],
      customer_types: [],
      flow_dates: [],
      forecast_date_to_select:"",
      markets: [],
      product_types: [],
      utilities: [],
      zones: [],
    },
  },
  utility_zones_by_market: {},
  utilityHeatMapChartData: {
    loader: false,
    dataToPlot: [],
    utility_zones_by_market: [],
  },
};

export const getUtilityForecastInitialDataAsync = createAsyncThunk(
  "forecasting/getUtilityInitialData",
  async (data) => {
    const response = await axios.get("/api/v1/getZoneHeatmapData", data);
    return response;
  }
);

export const getUpdatedUtilityForecastDataAsync = createAsyncThunk(
  "forecasting/getUtilityUpdatedData",
  async (data) => {
    const response = await axios.post(
      "/api/v1/getZoneHeatmapDataWithFilter",
      data
    );
    return response;
  }
);

export const utilityForecastSlice = createSlice({
  name: "forecasting/utility",
  initialState: INITIAL_STATE,
  reducers: {
    marketOnChange: (state, action) => {
      let data_utilities = [];
      let data_zones = [];
      if (action?.payload.length) {
        for (let val of action.payload) {
          data_utilities.push(...state.utility_zones_by_market[val]?.utilities);
          data_zones.push(...state.utility_zones_by_market[val]?.zones);
        }
      } else {
        Object.keys(state.utility_zones_by_market).forEach((key, index) => {
          data_utilities.push(
            ...state.utility_zones_by_market[key]["utilities"]
          );
          data_zones.push(...state.utility_zones_by_market[key]["zones"]);
        });
      }
      state.utilityHeatMapFilterData.dropDownListItems.utilities =
        data_utilities;
      state.utilityHeatMapFilterData.dropDownListItems.zones = data_zones;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUtilityForecastInitialDataAsync.pending, (state) => {
        state.utilityHeatMapFilterData.loader = true;
        state.utilityHeatMapChartData.loader = true;
      })
      .addCase(
        getUtilityForecastInitialDataAsync.fulfilled,
        (state, action) => {
          // new code
          state.utilityHeatMapFilterData.loader = false;
          state.utilityHeatMapChartData.loader = false;

          state.utilityHeatMapFilterData.dropDownListItems = {
            batch_dates: action?.payload?.data?.batch_date || [],
            customer_types: action?.payload?.data?.customer_types || [],
            flow_dates: action?.payload?.data?.flow_dates || [],
            forecast_date_to_select: action?.payload?.data?.forecast_date_to_select || "",
            markets: action?.payload?.data?.markets || [],
            product_types: action?.payload?.data?.product_types || [],
            utilities: action?.payload?.data?.utility || [],
            zones: action?.payload?.data?.zones || [],
          };
          state.utilityHeatMapChartData.dataToPlot =
            action?.payload?.data?.zone_heatmap_data || [];

          state.utilityHeatMapChartData.utility_zones_by_market =
            action?.payload?.data?.utility_zones_by_market || [];
          // state.utilityHeatMapChartData.dataToPlot =
          //   action?.payload?.data?.zone_heatmap_data || [];

          state.utility_zones_by_market =
            action?.payload?.data?.utility_zones_by_market || {};
          state.get_filtered_data = true;
        }
      )

      .addCase(getUtilityForecastInitialDataAsync.rejected, (state) => {
        state.get_filtered_data = false;
        state.utilityHeatMapFilterData.loader = false;
        state.utilityHeatMapChartData.loader = false;
      })
      .addCase(getUpdatedUtilityForecastDataAsync.pending, (state) => {
        state.utilityHeatMapChartData.loader = true;
      })
      .addCase(
        getUpdatedUtilityForecastDataAsync.fulfilled,
        (state, action) => {
          state.utilityHeatMapChartData.loader = false;
          state.utilityHeatMapChartData.dataToPlot =
            action?.payload?.data?.zone_heatmap_data;
          // action?.payload?.data?.zone_heatmap_data?.length > 0
          //   ? action?.payload?.data?.zone_heatmap_data
          //   : [];

          // state.utilityHeatMapChartData.utility_zones_by_market =
          //   action?.payload?.data?.utility_zones_by_market?.length > 0
          //     ? action?.payload?.data?.zone_heatmap_data
          //     : state.zoneTreeMapChartData.utility_zones_by_market;
        }
      )
      .addCase(getUpdatedUtilityForecastDataAsync.rejected, (state) => {
        state.loadGraph = false;
        state.utilityHeatMapChartData.loader = false;
      });
  },
});

export const { marketOnChange } = utilityForecastSlice.actions;
export default utilityForecastSlice.reducer;
