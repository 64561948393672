import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { axiosConfigManagement } from "axios.js";
import dayjs from "dayjs";

const initialState = {
  minDate: dayjs()
    .month(dayjs().month() + 1)
    .date(1),
  maxDate: dayjs()
    .month(dayjs().month() + 1)
    .date(1)
    .year(dayjs().year() + 10),
  proposedDate: dayjs()
    .month(dayjs().month() + 1)
    .date(1),
  proposedMultiDate: [
    {
      type: "Default",
      date: dayjs()
        .month(dayjs().month() + 1)
        .date(1),
    },
  ],
  proposedDateProfile: dayjs()
    .month(dayjs().month() + 1)
    .date(1),
  dateOptions: {
    list: [
      {
        option: "Default",
        views: ["month", "year"],
        selected: true,
        defaultDate: 1,
      },
      {
        option: "On Cycle",
        views: ["month", "year"],
        selected: false,
        workingDay: 3,
      },
      {
        option: "Self Selected",
        views: ["year", "month", "day"],
        selected: false,
      },
    ],
    loader: false,
  },
  accounts: { list: [], loader: false },
  profiles: { list: [], loader: false },
  utility: { list: [], loader: false },
  customerTypeList: { list: [], loader: false },
};
export const getCustomerTypeProfilesAsync = createAsyncThunk(
  "customer/customerType",
  async (clientCode) => {
    const response = await axios.get(
      `/api/v1/general_configuration/get?clientCode=${clientCode}&configDomain=GENERAL_CUSTOMER_TYPE`
    );
    return response;
  }
);

export const getCustomerAccountListAsync = createAsyncThunk(
  "pricing/getCustomerAccountList",
  async (data) => {
    const { client_code, customerId } = data;
    const response = await axios.get(
      `/api/v1/customer/get-service-accounts?customerId=${customerId}&clientCode=${client_code}`,
      data
    );
    return response;
  }
);
export const getCustomerProfileListAsync = createAsyncThunk(
  "pricing/getCustomerProfileList",
  async () => {
    const response = await axiosConfigManagement.get(
      `/api/v1/configuration/service-profiles/get?page=1&page_size=10`
    );
    return response;
  }
);
export const getCustomerProfileListWithModelAsync = createAsyncThunk(
  "pricing/getCustomerProfileListWithModel",
  async (productModel) => {
    const response = await axiosConfigManagement.get(
      `api/v1/configuration/service-profiles/${productModel}/get`
    );
    return response;
  }
);
export const getUtilityForProfilesAsync = createAsyncThunk(
  "pricing/utility/list",
  async () => {
    const response =
      await axiosConfigManagement.get(`api/v1/configuration/utility/get
    `);
    return response;
  }
);
export const pricingAccountSlice = createSlice({
  name: "pricing/accounts",
  initialState,
  reducers: {
    selectAccountList: (state, action) => {
      state.accounts.list = action.payload;
    },
    selectProfileList: (state, action) => {
      state.profiles.list = action.payload;
    },
    selectProposedDate: (state, action) => {
      //state.proposedDate = action.payload;
      const { type, id, data } = action.payload;
      let currentDates = [...state.proposedMultiDate];

      if (type === "new") {
        currentDates.push({
          type: "Default",
          date: dayjs()
            .month(dayjs().month() + 1)
            .date(1),
        });
        state.proposedMultiDate = [...currentDates];
      } else if (type === "remove") {
        currentDates = currentDates.filter((itme, i) => i === id);
        state.proposedMultiDate = [...currentDates];
      } else if (type === "date") {
        console.log("&&&&&&&&&&&&&&&&&&&", type, id, data);
        currentDates[id].date = data;
        state.proposedMultiDate = [...currentDates];
      } else if (type === "type") {
        currentDates[id].type = data;
        state.proposedMultiDate = [...currentDates];
      }
      console.log("*****************mmmm", currentDates);

      // state.accounts.list = state.accounts.list.map((account) => {
      //   if (account.selected) account.date = action.payload;
      //   return account;
      // });
    },
    selectProposedDateProfile: (state, action) => {
      state.proposedDate = action.payload;
      state.profiles.list = state?.profiles?.list?.map((profile) => {
        if (profile.selected) profile.date = action.payload;
        return profile;
      });
    },
    selectProposedDateType: (state, action) => {
      state.dateOptions.list.forEach((item) => {
        item.selected = item.option === action.payload;
      });
      if (action.payload === "On Cycle") {
        const option = state.dateOptions.list.find(
          (x) => x.option === "On Cycle"
        );
        let newDate = state.minDate.date(option.workingDay);
        while (option.workingDay !== newDate.day())
          newDate = newDate.date(newDate.date() + 1);
        state.proposedDate = newDate;
      } else {
        state.proposedDate = state.minDate;
      }
      state.accounts.list = state.accounts.list.map((account) => {
        if (account.selected) {
          account.date = state.proposedDate;
          account.dateOptions.list.forEach((item) => {
            item.selected = item.option === action.payload;
          });
        }
        return account;
      });
    },
    selectProposedDateTypeProfile: (state, action) => {
      state.dateOptions.list.forEach((item) => {
        item.selected = item.option === action.payload;
      });
      if (action.payload === "On Cycle") {
        const option = state.dateOptions.list.find(
          (x) => x.option === "On Cycle"
        );
        let newDate = state.minDate.date(option.workingDay);
        while (option.workingDay !== newDate.day())
          newDate = newDate.date(newDate.date() + 1);
        state.proposedDate = newDate;
      } else {
        state.proposedDate = state.minDate;
      }
      state.profiles.list = state.profiles.list.map((account) => {
        if (account.selected) {
          account.date = state.proposedDate;
          account.dateOptions.list.forEach((item) => {
            item.selected = item.option === action.payload;
          });
        }
        return account;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerAccountListAsync.pending, (state) => {
        state.accounts.loader = true;
      })
      .addCase(getCustomerAccountListAsync.fulfilled, (state, action) => {
        state.accounts.loader = false;
        const responseData = action?.payload?.data?.data || [];
        state.accounts.list = responseData.map((x) => {
          return { ...x, date: {}, selected: false };
        });
      })
      .addCase(getCustomerAccountListAsync.rejected, (state) => {
        state.accounts.loader = false;
        state.accounts.list = [];
      })
      .addCase(getCustomerProfileListAsync.pending, (state) => {
        state.profiles.loader = true;
      })
      .addCase(getCustomerProfileListAsync.fulfilled, (state, action) => {
        state.profiles.loader = false;
        const responseData = action?.payload?.data?.data || [];
        state.profiles.list = responseData.map((x) => {
          return { ...x, date: {}, selected: false };
        });
      })
      .addCase(getCustomerProfileListAsync.rejected, (state) => {
        state.profiles.loader = false;
        state.profiles.list = [];
      })
      .addCase(getCustomerProfileListWithModelAsync.pending, (state) => {
        state.profiles.loader = true;
      })
      .addCase(
        getCustomerProfileListWithModelAsync.fulfilled,
        (state, action) => {
          state.profiles.loader = false;
          const responseData = action?.payload?.data?.data || [];
          state.profiles.list = responseData.map((x) => {
            return { ...x, date: {}, selected: false };
          });
        }
      )
      .addCase(getCustomerProfileListWithModelAsync.rejected, (state) => {
        state.profiles.loader = false;
        state.profiles.list = [];
      })
      .addCase(getUtilityForProfilesAsync.pending, (state) => {
        state.utility.loader = true;
        state.utility.list = [];
      })
      .addCase(getUtilityForProfilesAsync.fulfilled, (state, action) => {
        state.utility.loader = false;
        state.utility.list = action?.payload?.data?.data || [];
      })
      .addCase(getUtilityForProfilesAsync.rejected, (state, action) => {
        state.utility.loader = false;
        state.utility.list = [];
      })
      .addCase(getCustomerTypeProfilesAsync.pending, (state) => {
        state.customerTypeList.loader = true;
      })
      .addCase(getCustomerTypeProfilesAsync.fulfilled, (state, action) => {
        state.customerTypeList.loader = false;
        state.customerTypeList.list = action.payload.data.data || [];
      })
      .addCase(getCustomerTypeProfilesAsync.rejected, (state, action) => {
        state.customerTypeList.loader = false;
        state.customerTypeList.list = [];
      });
  },
});
export const {
  selectAccountList,
  selectProfileList,
  selectProposedDate,
  selectProposedDateType,
  selectProposedDateProfile,
  selectProposedDateTypeProfile,
} = pricingAccountSlice.actions;
// Action creators are generated for each case reducer function
export default pricingAccountSlice.reducer;
