import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosSettlementsForcastDemoInstance as axios } from "axios.js";

const INITIAL_STATE = {
  volumetricDownloadbleData: { laader: false, data: [] },
  volumentricData: {
    loader: false,
    data: [],
    requestBodyData: {},
  },
};

export const getSettlementVolumetricDataAsync = createAsyncThunk(
  "settlement/getSettlementVolumetricData",
  async (data) => {
    const response = await axios.get(
      `/api/v1/get_settlement_records/${data?.settlement_type}/${data?.page}/${data?.num_records}`
    );
    return response;
  }
);
export const getVolumetricDownloadableFile = createAsyncThunk(
  "settlement/getSettlementDownloadebleFile",
  async (body) => {
    // try {
    const response = await axios.post(
      `/api/v2/download_settlement_records_with_filter`,
      body
    );
    return response;
  }
);
export const settlementVolumetricFIlterDataAsync = createAsyncThunk(
  "settlement/getSettlementVolumetricData/filter",
  ({ requestData, pageNo, rowsPerPage }) => {
    console.log({ requestData });
    const response = axios
      .post(
        `api/v2/get_settlement_records_with_filter/${pageNo}/${rowsPerPage}`,
        requestData
      )
      .then((res) => {
        return res;
      });
    return response;
  }
);
export const SettlementVolumetricSlice = createSlice({
  name: "settlement/volumetric",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSettlementVolumetricDataAsync.pending, (state) => {
        state.volumentricData.loader = true;
      })
      .addCase(getSettlementVolumetricDataAsync.fulfilled, (state, action) => {
        state.volumentricData.loader = false;
        state.volumentricData.data = action?.payload?.data;
      })
      .addCase(getSettlementVolumetricDataAsync.rejected, (state) => {
        state.volumentricData.loader = false;
      })
      .addCase(settlementVolumetricFIlterDataAsync.pending, (state) => {
        state.volumentricData.loader = true;
      })
      .addCase(
        settlementVolumetricFIlterDataAsync.fulfilled,
        (state, action) => {
          state.volumentricData.loader = false;
          state.volumentricData.requestBodyData = action?.meta?.arg.requestData;
          state.volumentricData.data =
            action?.payload?.data?.settlements_records;
        }
      )
      .addCase(settlementVolumetricFIlterDataAsync.rejected, (state) => {
        state.volumentricData.loader = false;
      })
      .addCase(getVolumetricDownloadableFile.rejected, (state, action) => {
        state.volumetricDownloadbleData.loader = false;
        state.volumetricDownloadbleData.data = [];

        // changes
      })
      .addCase(getVolumetricDownloadableFile.pending, (state, action) => {
        state.volumetricDownloadbleData.loader = true;
        state.volumetricDownloadbleData.data = {};

        // changes
      })
      .addCase(getVolumetricDownloadableFile.fulfilled, (state, action) => {
        state.volumetricDownloadbleData.loader = false;
        state.volumetricDownloadbleData.data = action?.payload?.data
          ? action?.payload?.data
          : {};

        // changes
      });
  },
});

export const {} = SettlementVolumetricSlice.actions;

export default SettlementVolumetricSlice.reducer;
