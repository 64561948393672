import { Outlet } from "react-router-dom";
import MatxSuspense from "../components/customComponents/MatxSuspense";
import Footer from "./admin/Footer";
import Sidebar from "./admin/Sidebar";
import Topbar from "./admin/Topbar";


const AppLayout = (props) => {
  return (
    <MatxSuspense>
      <Sidebar/>
      <Topbar />
      <MatxSuspense>
        <Outlet />
      </MatxSuspense>
      <Footer />
    </MatxSuspense>
  );
};

export default AppLayout;
