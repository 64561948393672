import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios.js";

const initialState = {
  clientList: { list: [], loader: false, selected: null },
  productList: { list: [], loader: false, selected: null },
  pricingModelList: { list: [], loader: false, selected: null },
};

export const getClientListAsync = createAsyncThunk(
  "pricing/getClientList",
  async (_, thunkAPI) => {
    const list = thunkAPI.getState().pricingClient.clientList.list;
    if (list.length > 0) {
      return Promise.resolve({
        data: {
          data: list
        },
        status: 200,
        statusText: 'OK',
      })
    }
    else {
      const response = await axios.get("/api/v1/client/get");
      return response;
    }
  }
);

export const getProductListAsync = createAsyncThunk(
  "pricing/getProductList",
  async (client_code) => {
    const response = await axios.get(`/api/v1/product/get/${client_code}`);
    return response;
  }
);

export const getPricingModelListAsync = createAsyncThunk(
  "pricing/getPricingModelList",
  async ({ client_code, product_code }) => {
    const response = await axios.get(
      `/api/v1/pricingmodel/get/${client_code}/${product_code}`
    );
    return response;
  }
);

export const pricingClientSlice = createSlice({
  name: "pricing",
  initialState,
  reducers: {
    selectClient: (state, action) => {
      state.clientList.selected = action.payload;
    },
    selectProduct: (state, action) => {
      state.productList.selected = action.payload;
    },
    selectPricingModel: (state, action) => {
      state.pricingModelList.selected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClientListAsync.pending, (state) => {
        state.clientList.loader = true;
      })
      .addCase(getClientListAsync.fulfilled, (state, action) => {
        state.clientList.loader = false;
        state.clientList.list = action?.payload?.data?.data || [];
      })
      .addCase(getClientListAsync.rejected, (state, action) => {
        state.clientList.loader = false;
        state.clientList.list = [];
      })
      .addCase(getProductListAsync.pending, (state) => {
        state.productList.loader = true;
      })
      .addCase(getProductListAsync.fulfilled, (state, action) => {
        state.productList.loader = false;
        state.productList.list = action?.payload?.data?.data || [];
      })
      .addCase(getProductListAsync.rejected, (state, action) => {
        state.productList.loader = false;
        state.productList.list = [];
      })
      .addCase(getPricingModelListAsync.pending, (state) => {
        state.pricingModelList.loader = true;
      })
      .addCase(getPricingModelListAsync.fulfilled, (state, action) => {
        state.pricingModelList.loader = false;
        state.pricingModelList.list = action?.payload?.data?.data || [];
      })
      .addCase(getPricingModelListAsync.rejected, (state, action) => {
        state.pricingModelList.loader = false;
        state.pricingModelList.list = [];
      });
  },
});
export const { selectClient, selectProduct, selectPricingModel } =
  pricingClientSlice.actions;

// Action creators are generated for each case reducer function
export default pricingClientSlice.reducer;
