import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosForecastRiskInstance as axios } from "axios.js";

const initialState = {
  varData: { list: [], loader: false },
  market: { selected: [], list: [], filterLoader: false },
};
export const getVarDataApi = createAsyncThunk("risk/var/get", () => {
  const response = axios.get(`api/v1/get_price_change_var`).then((res) => {
    return res;
  });
  return response;
});
export const varSliceEngie = createSlice({
  name: "risk",
  initialState,
  reducers: {
    loadMarket: (state, action) => {
      state.market.list = action.payload;
      state.market.selected = [state.market.list];
    },
    selectMarket: (state, action) => {
      state.market.selected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVarDataApi.pending, (state) => {
        state.varData.loader = true;
        state.market.filterLoader = true;
      })
      .addCase(getVarDataApi.fulfilled, (state, action) => {
        state.varData.loader = false;
        state.varData.list = action?.payload?.data || [];
        state.market.list = action?.payload?.data?.trading_points || [];
        state.market.filterLoader = false;
      })
      .addCase(getVarDataApi.rejected, (state, action) => {
        state.varData.loader = false;
        state.market.filterLoader = false;
        state.results.runStatus.status = false;
      });
  },
});
export const { selectMarket, loadMarket } = varSliceEngie.actions;
export default varSliceEngie.reducer;
