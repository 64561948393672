import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosForecastInstance as axios } from "axios.js";

const INITIAL_STATE = {
  dashBoardLongtermForecastingChartData: {
    loader: false,
    dataToPlot: [],
    longTermBatchDates: {},
  },
  dashBoardLongTermForecastingDrillDownChartData: {
    loader: false,
    dataToPlot: [],
    longTermBatchDates: {},
  },
  dashBoardLongTermMarketLevelChartData: {
    loader: false,
    dataToPlot: [],
    longTermBatchDates: {},
  },
};

export const getdashboardLongTermForecastDataAsync = createAsyncThunk(
  "forecasting/getDashboardLongTerm",
  async (data) => {
    const response = await axios.get(
      `/api/v1/get_long_term_load_difference_by_zone/${data.market}`
    );
    return response;
  }
);
export const getdashboardMarketLevelLongTermForecastInitialDataAsync =
  createAsyncThunk("forecasting/getDashboardMarketLevelLongTerm", async () => {
    const response = await axios.get("/api/v1/long_short_term_load_by_market");
    return response;
  });
export const getdashboardLongTermForecastDrillDownInitialDataAsync =
  createAsyncThunk(
    "forecasting/getDashboardLongTermDrillDown",
    async (data) => {
      const response = await axios.get(
        `/api/v1/get_long_term_hourly_load_difference_by_zone?flow_month=${data.date}&market_cd=${data.market}&zone_cd=${data.zone}`
      );
      return response;
    }
  );

export const dashboardLongTermSlice = createSlice({
  name: "forecasting/dashboard/long_term",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getdashboardLongTermForecastDataAsync.pending, (state) => {
        state.dashBoardLongtermForecastingChartData.loader = true;
      })
      .addCase(
        getdashboardLongTermForecastDataAsync.fulfilled,
        (state, action) => {
          state.dashBoardLongtermForecastingChartData.loader = false;

          state.dashBoardLongtermForecastingChartData.dataToPlot =
            action?.payload?.data?.load_difference_by_zone || [];
          state.dashBoardLongtermForecastingChartData.longTermBatchDates =
            action?.payload?.data?.batch_dates || [];
        }
      )
      .addCase(getdashboardLongTermForecastDataAsync.rejected, (state) => {
        state.dashBoardLongtermForecastingChartData.loader = false;
      })
      .addCase(
        getdashboardMarketLevelLongTermForecastInitialDataAsync.pending,
        (state) => {
          state.dashBoardLongTermMarketLevelChartData.loader = true;
        }
      )
      .addCase(
        getdashboardMarketLevelLongTermForecastInitialDataAsync.fulfilled,
        (state, action) => {
          state.dashBoardLongTermMarketLevelChartData.loader = false;

          state.dashBoardLongTermMarketLevelChartData.dataToPlot =
            action?.payload?.data?.load_difference_by_market || [];
          state.dashBoardLongTermMarketLevelChartData.longTermBatchDates =
            action?.payload?.data?.batch_dates || [];
        }
      )
      .addCase(
        getdashboardMarketLevelLongTermForecastInitialDataAsync.rejected,
        (state) => {
          state.dashBoardLongTermMarketLevelChartData.loader = false;
        }
      )
      .addCase(
        getdashboardLongTermForecastDrillDownInitialDataAsync.pending,
        (state) => {
          state.dashBoardLongTermForecastingDrillDownChartData.loader = true;
        }
      )
      .addCase(
        getdashboardLongTermForecastDrillDownInitialDataAsync.fulfilled,
        (state, action) => {
          state.dashBoardLongTermForecastingDrillDownChartData.loader = false;

          state.dashBoardLongTermForecastingDrillDownChartData.dataToPlot =
            action?.payload?.data?.hourly_load_difference_by_zone || [];
          state.dashBoardLongTermForecastingDrillDownChartData.longTermBatchDates =
            action?.payload?.data?.batch_dates || [];
        }
      )
      .addCase(
        getdashboardLongTermForecastDrillDownInitialDataAsync.rejected,
        (state) => {
          state.dashBoardLongTermForecastingDrillDownChartData.loader = false;
        }
      );
  },
});

export default dashboardLongTermSlice.reducer;
