import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosForecastRiskInstance as axios } from "axios.js";

const initialState = {
  marketAreaExposureData: { list: [], loader: false },
  exposureBySeasonByLoadZoneArea: { loader: false, list: [] },
  exposureBySeasonByIso: { loader: false, list: [] },
};
export const getSummaryMarketAreaExposureDataApi = createAsyncThunk(
  "risk/summary/market/exposure/get",
  () => {
    const response = axios
      .get(`/api/v1/market_area_volume_exposure`)
      .then((res) => {
        return res;
      });
    return response;
  }
);

export const getSummarySeasonByLoadZoneAreaDataApi = createAsyncThunk(
  "risk/summary/season/exposure/get",
  () => {
    const response = axios
      .get(`/api/v1/exposure_by_season_by_market_area`)
      .then((res) => {
        return res;
      });
    return response;
  }
);
export const getSummaryExposureBySeasonDataApi = createAsyncThunk(
  "risk/summary/exposure/iso/get",
  () => {
    const response = axios
      .get(`/api/v1/exposure_by_season_by_iso`)
      .then((res) => {
        return res;
      });
    return response;
  }
);
export const summarySliceEngie = createSlice({
  name: "risk/summary",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSummaryMarketAreaExposureDataApi.pending, (state) => {
        state.marketAreaExposureData.loader = true;
        // state.market.filterLoader = true;
      })
      .addCase(
        getSummaryMarketAreaExposureDataApi.fulfilled,
        (state, action) => {
          state.marketAreaExposureData.loader = false;

          state.marketAreaExposureData.list = action?.payload?.data || [];
          //   state.market.list = action?.payload?.data?.trading_points || [];
          //   state.market.filterLoader = false;
        }
      )
      .addCase(
        getSummaryMarketAreaExposureDataApi.rejected,
        (state, action) => {
          state.marketAreaExposureData.loader = false;
          //   state.market.filterLoader = false;
        }
      )
      .addCase(getSummarySeasonByLoadZoneAreaDataApi.pending, (state) => {
        state.exposureBySeasonByLoadZoneArea.loader = true;
      })
      .addCase(
        getSummarySeasonByLoadZoneAreaDataApi.fulfilled,
        (state, action) => {
          state.exposureBySeasonByLoadZoneArea.loader = false;
          state.exposureBySeasonByLoadZoneArea.list =
            action?.payload?.data || [];
          //   state.market.list = action?.payload?.data?.trading_points || [];
          //   state.market.filterLoader = false;
        }
      )
      .addCase(
        getSummarySeasonByLoadZoneAreaDataApi.rejected,
        (state, action) => {
          state.exposureBySeasonByLoadZoneArea.loader = false;
          //   state.market.filterLoader = false;
        }
      )
      .addCase(getSummaryExposureBySeasonDataApi.pending, (state) => {
        state.exposureBySeasonByIso.loader = true;
      })
      .addCase(getSummaryExposureBySeasonDataApi.fulfilled, (state, action) => {
        state.exposureBySeasonByIso.loader = false;
        state.exposureBySeasonByIso.list = action?.payload?.data || [];
        //   state.market.list = action?.payload?.data?.trading_points || [];
        //   state.market.filterLoader = false;
      })
      .addCase(getSummaryExposureBySeasonDataApi.rejected, (state, action) => {
        state.exposureBySeasonByIso.loader = false;
        //   state.market.filterLoader = false;
      });
  },
});
// export const { selectMarket, loadMarket } = summarySliceEngie.actions;
export default summarySliceEngie.reducer;
