import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosForecastInstance as axios } from "axios.js";

const initialState = {
    usageData: {
        dropDownListItems: {
            "markets": ["ERCOT"],
            "zones": ["HOUSTON", "NORTH", "SOUTH", "WEST"],
        }, loader: false
    },
    markToMarket: { block_types: [], list: []/*dummyUsageData.data*/, loader: false },  
}

export const getInitialAssetValudationDataAsync = createAsyncThunk(
    "asset/get/initialdata",
    async (body) => {
        const response = await axios.get("/api/v1/get_usage_and_revenue_data", body);
        return response;
    }
);

export const getUpdatedAssetValudationDataAsync = createAsyncThunk(
    "asset/get/updateddata",
    async (body) => {
        const response = await axios.get("/api/v1/get_usage_and_revenue_data", body);
        return response;
    }
);

export const assetValuationSlice = createSlice({
    name: "assetvaluation/slice",
    initialState,
    reducers: {
        zoneOnChange: (state, action) => {

        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getInitialAssetValudationDataAsync.pending, (state) => {
                //state.usageData.loader = true;
                state.markToMarket.loader = true;
            })
            .addCase(getInitialAssetValudationDataAsync.fulfilled, (state, action) => {
                //state.usageData.loader = false;
                //state.usageData.dropdownList = action?.payload?.data || [];

                state.markToMarket.loader = false;
                state.markToMarket.list = action?.payload?.data.data || [];
                state.markToMarket.block_types = action?.payload?.data.block_types || [];
            })
            .addCase(getInitialAssetValudationDataAsync.rejected, (state, action) => {
                //state.usageData.loader = false;
                //state.usageData.dropdownList = [];

                state.markToMarket.loader = false;
                state.markToMarket.list = [];
                state.markToMarket.block_types = [];
            })
            // .addCase(getUpdatedAssetValudationDataAsync.pending, (state) => {
                
            //     state.markToMarket.loader = true;
            // })
            // .addCase(getUpdatedAssetValudationDataAsync.fulfilled, (state, action) => {
            //     state.markToMarket.loader = false;
            //     state.markToMarket.list = action?.payload?.data.data || [];
            //     state.markToMarket.block_types = action?.payload?.data.block_types || [];
            // })
            // .addCase(getUpdatedAssetValudationDataAsync.rejected, (state, action) => {
            //     //state.revenueData.loader = false;
            //     //state.revenueData.dropdownList = [];

            //     state.markToMarket.loader = false;
            //     state.markToMarket.list = [];
            //     state.markToMarket.block_types = [];
            // })
    },
});
export const {
    zoneOnChange
} = assetValuationSlice.actions;
// Action creators are generated for each case reducer function
export default assetValuationSlice.reducer;
