import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { axiosForecastRiskInstance as axios } from "axios.js";

const initialState = {
  market: { selected: [], list: [] },
  mtmEngieData: {
    loader: false,
    zonePriceChangeMtm: []
  }
};

export const getMtmEngieDataAsync = createAsyncThunk(
    "mtmengine/get/data",
    async (body) => {
      const response = await axios.get("/api/v1/get_price_change_mtm", body);
      return response;
    }
  );

export const mtmEngieSlice = createSlice({
  name: "mtmengie/data",
  initialState,
  reducers: {
    loadMarket: (state, action) => {
      state.market.list = action.payload;
      state.market.selected = [state.market.list[0]];
    },
    selectMarket: (state, action) => {
      state.market.selected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMtmEngieDataAsync.pending, (state) => {
        state.mtmEngieData.loader = true;
      })
      .addCase(getMtmEngieDataAsync.fulfilled, (state, action) => {
        state.mtmEngieData.loader = false;
        state.mtmEngieData.zonePriceChangeMtm = action?.payload?.data?.zone_price_change_mtm;
      })
      .addCase(getMtmEngieDataAsync.rejected, (state, action) => {
        state.mtmEngieData.loader = false;
        
      })
  },
});
export const { selectMarket, loadMarket } = mtmEngieSlice.actions;
export default mtmEngieSlice.reducer;
