import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosForecastInstance as axios } from "axios.js";

const INITIAL_STATE = {
  dataToPlot: {
    loader: false,
    summaryData: [],
  },
  dropDownFilterData: {
    loader: false,
    dropDownListItems: {
      customerTypes: [],
      markets: [],
      utilities: [],
      utilitiesByMarket: {},
    },
  },
};

export const getSummaryGraphDataSliceAsync = createAsyncThunk(
  "analytics/summaryGraphInitialData",
  async (body) => {
    const response = await axios.get(
      "/api/v1/get_latest_year_monthly_usage_v2",
    );
    return response;
  }
);

export const getSummaryGraphUpdatdDataSliceAsync = createAsyncThunk(
  "analytics/summaryGraphFilteredData",
  async (body) => {
    const response = await axios.post(
      "/api/v1/get_latest_year_filter_monthly_usage_v2",
      body
    );
    return response;
  }
);

export const summaryGraphSliceAsync = createSlice({
  name: "analytics/summaryGraph",
  initialState: INITIAL_STATE,
  reducers: {
    changeUtilities: (state, action) => {
      let utilities = action.payload.map(
        (val) =>
          state.dropDownFilterData.dropDownListItems.utilitiesByMarket[val][
            "utilities"
          ]
      );
      state.dropDownFilterData.dropDownListItems.utilities = utilities.flat(1);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSummaryGraphDataSliceAsync.pending, (state) => {
        state.dataToPlot.loader = true;
        state.dropDownFilterData.loader = true;
      })
      .addCase(getSummaryGraphDataSliceAsync.fulfilled, (state, action) => {
        state.dataToPlot.loader = false;
        state.dropDownFilterData.loader = false;

        state.dataToPlot.summaryData = action?.payload?.data.annual_usage;
        state.dropDownFilterData.dropDownListItems.customerTypes =
          action?.payload?.data?.customer_types;

        state.dropDownFilterData.dropDownListItems.markets = action?.payload
          ?.data?.utilities_by_market
          ? Object.keys(action?.payload?.data?.utilities_by_market)
          : [];

        let utilities = action?.payload?.data?.utilities_by_market
          ? Object.keys(action?.payload?.data?.utilities_by_market).map(
              (val) => action.payload.data.utilities_by_market[val]["utilities"]
            )
          : [];

        state.dropDownFilterData.dropDownListItems.utilities =
          utilities.flat(1);

        state.dropDownFilterData.dropDownListItems.utilitiesByMarket =
          action?.payload?.data?.utilities_by_market;
      })
      .addCase(getSummaryGraphDataSliceAsync.rejected, (state) => {
        state.dataToPlot.loader = false;
        state.dropDownFilterData.loader = false;
      })
      .addCase(getSummaryGraphUpdatdDataSliceAsync.pending, (state) => {
        state.dataToPlot.loader = true;
      })
      .addCase(
        getSummaryGraphUpdatdDataSliceAsync.fulfilled,
        (state, action) => {
          state.dataToPlot.loader = false;
          state.dataToPlot.summaryData = action?.payload?.data?.annual_usage;
        }
      )
      .addCase(getSummaryGraphUpdatdDataSliceAsync.rejected, (state) => {
        state.dataToPlot.loader = false;
      });
  },
});

export const { changeUtilities } = summaryGraphSliceAsync.actions;
export default summaryGraphSliceAsync.reducer;
