import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosRiskInstance as axios } from "axios.js";
import { axiosForecastRiskInstance as axiosForcast } from "axios.js";

const INITIAL_STATE = {
  get_filtered_data: false,

  shortTerPositionFilterData: {
    loader: false,
  },
  dropDownListItems: {
    zones: { loader: false, data: [] },
    markets: { loader: false, data: [] },
    serviceClass: { loader: false, data: [] },
    product: { loader: false, data: [] },
  },
  filterStatus: false,

  dropDownList: {
    zones: [],
    markets: [],
    serviceClass: [],
    product: [],
    attrition: 0,
  },

  shortTermPositionWhetherChartData: {
    loader: false,
    weather_data: [],
  },
  shortTermPositionloadVsHedgeData: { list: [], loader: false },
};

export const getShortTermPositionInitialWeatherDataAsync = createAsyncThunk(
  "position/getShortTermInitialWeatherlData",
  async (data) => {
    const response = await axiosForcast.get("/api/v1/getWeatherDataV2", data);
    return response;
  }
);
export const getShortTermPositionDropDownDataAsync = createAsyncThunk(
  "position/getShortTermDropDownData",
  async (data) => {
    const response = await axios.get(
      `/api/v1/configuration/${data.value}/short_term`,
      data
    );
    return response;
  }
);
export const getShortTermPositionInitialWeatherFilterdDataAsync =
  createAsyncThunk(
    "position/getShortTermWeather/filteredlData",
    async (data) => {
      const response = await axiosForcast.post(
        "/api/v1/getWeatherDataWithFilterV2",
        data
      );
      return response;
    }
  );

export const UpdatedShortTermPositionDataFilterAsync = createAsyncThunk(
  "position/getShortTermUpdatedDataFilter",
  async (data) => {
    const response = await axios.post(
      "/api/v1/st_position_reporting/filter_short_term_position_load_vs_hedge",
      data
    );
    return response;
  }
);
export const getRiskPostionChartLoadVsHedge = createAsyncThunk(
  "risk/getShortermPostionLoadVsHedge",
  () => {
    const response = axios
      .get(
        `/api/v1/st_position_reporting/get_short_term_position_load_vs_hedge`
      )
      .then((res) => {
        return res;
      });
    return response;
  }
);
export const shortTermPositionSlice = createSlice({
  name: "position/shortterm",
  initialState: INITIAL_STATE,
  reducers: {
    dateOneChange: (state, action) => {
      state.dateOne = action.payload;
    },
    dateTwoChange: (state, action) => {
      state.dateTwo = action.payload;
    },
    setFilterStatus: (state, action) => {
      state.filterStatus = action.payload;
    },
    marketOnChange: (state, action) => {
      state.dropDownList.markets = action.payload || [];
    },
    setDropDownListChangeNew: (state, action) => {
      const values = action?.payload?.values || [];
      const type = action?.payload?.type;
      console.log({ type });
      if (type) {
        if (type === "attrition") {
          state.dropDownList[type] = action?.payload?.values || 0;
        } else {
          state.dropDownList[type] = values;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getShortTermPositionInitialWeatherDataAsync.pending, (state) => {
        state.get_filtered_data = false;

        state.shortTerPositionFilterData.loader = true;
        state.shortTermPositionWhetherChartData.loader = true;
      })
      .addCase(
        getShortTermPositionInitialWeatherDataAsync.fulfilled,
        (state, action) => {
          state.shortTermPositionWhetherChartData.loader = false;

          state.shortTermPositionWhetherChartData.weather_data =
            action?.payload?.data?.weather_data || [];

          state.get_filtered_data = true;
        }
      )
      .addCase(
        getShortTermPositionInitialWeatherDataAsync.rejected,
        (state) => {
          state.get_filtered_data = false;

          state.shortTermPositionWhetherChartData.loader = false;
        }
      )
      .addCase(
        getShortTermPositionDropDownDataAsync.pending,
        (state, action) => {
          state.dropDownListItems = {
            ...state.dropDownListItems,
            [action.meta.arg.key]: {
              ...state.dropDownListItems[action.meta.arg.key],
              data: [],

              loader: true,
            },
            loader: true,
          };
        }
      )
      .addCase(
        getShortTermPositionDropDownDataAsync.fulfilled,
        (state, action) => {
          state.dropDownListItems = {
            ...state.dropDownListItems,
            [action.meta.arg.key]: {
              ...state.dropDownListItems[action.meta.arg.key],
              data: Object.values(action?.payload?.data?.data[0] || {})[0],
              loader: false,
            },
            loader: false,
          };
        }
      )
      .addCase(getShortTermPositionDropDownDataAsync.rejected, (state) => {
        state.dropDownListItems = {
          ...state.dropDownListItems,
          loader: false,
        };
      })
      .addCase(UpdatedShortTermPositionDataFilterAsync.pending, (state) => {
        state.shortTermPositionloadVsHedgeData.loader = true;
      })
      .addCase(
        UpdatedShortTermPositionDataFilterAsync.fulfilled,
        (state, action) => {
          console.log({ ss: action?.payload?.data });
          state.shortTermPositionloadVsHedgeData.loader = false;
          // state.shortTermForecastingConsumptionChartData.averageConsumtionDataToPlot = action?.payload?.data?.short_term_data;
          state.shortTermPositionloadVsHedgeData.list =
            action?.payload?.data?.data || [];
        }
      )
      .addCase(UpdatedShortTermPositionDataFilterAsync.rejected, (state) => {
        state.shortTermForecastingConsumptionChartData.loader = false;
      })
      .addCase(
        getShortTermPositionInitialWeatherFilterdDataAsync.pending,
        (state) => {
          state.shortTermPositionWhetherChartData.loader = true;
        }
      )
      .addCase(
        getShortTermPositionInitialWeatherFilterdDataAsync.fulfilled,
        (state, action) => {
          state.shortTermPositionWhetherChartData.loader = false;
          console.log({ filtr: action?.payload?.data });

          state.shortTermPositionWhetherChartData.weather_data =
            action?.payload?.data?.weather_data || [];
        }
      )
      .addCase(
        getShortTermPositionInitialWeatherFilterdDataAsync.rejected,
        (state) => {
          state.shortTermPositionWhetherChartData.loader = false;
        }
      )
      .addCase(getRiskPostionChartLoadVsHedge.pending, (state) => {
        state.shortTermPositionloadVsHedgeData.loader = true;
        state.shortTerPositionFilterData.loader = true;
      })
      .addCase(getRiskPostionChartLoadVsHedge.fulfilled, (state, action) => {
        state.shortTerPositionFilterData.loader = false;
        state.shortTermPositionloadVsHedgeData.loader = false;
        state.shortTermPositionloadVsHedgeData.list =
          action?.payload?.data?.data || [];
      })
      .addCase(getRiskPostionChartLoadVsHedge.rejected, (state, action) => {
        state.shortTermPositionloadVsHedgeData.loader = false;
        state.shortTerPositionFilterData.loader = false;
      });
  },
});

export const {
  marketOnChange,
  setDropDownListChangeNew,
  dateOneChange,
  dateTwoChange,
  setFilterStatus,
} = shortTermPositionSlice.actions;
export default shortTermPositionSlice.reducer;
