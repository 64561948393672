import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosForecastInstance as axios } from "axios.js";

const DATA = [
  {
    market: "PJM",
    meter_count: 1,
    utility: "CEC_DS",
  },
  {
    market: "PJM",
    meter_count: 1,
    utility: "APCO",
  },
  {
    market: "PJM",
    meter_count: 1,
    utility: "COMED_DLA1",
  },
  {
    market: "PJM",
    meter_count: 1,
    utility: "PPL_DLA1",
  },
  {
    market: "PJM",
    meter_count: 1,
    utility: "PECO_DLA1",
  },
  {
    market: "ISONE",
    meter_count: 1,
    utility: "FGE",
  },
  {
    market: "PJM",
    meter_count: 2,
    utility: "BGE_DLA1",
  },
  {
    market: "PJM",
    meter_count: 2,
    utility: "TED_NMB1",
  },
  {
    market: "PJM",
    meter_count: 3,
    utility: "JCPL_DLA1",
  },
  {
    market: "PJM",
    meter_count: 3,
    utility: "CEI_NMB3",
  },
  {
    market: "MISO",
    meter_count: 3,
    utility: "AMEREN_MO",
  },
  {
    market: "PJM",
    meter_count: 4,
    utility: "PEPCODC_DLA2",
  },
  {
    market: "PJM",
    meter_count: 4,
    utility: "PEPCOMD_DLA1",
  },
  {
    market: "PJM",
    meter_count: 7,
    utility: "RECO",
  },
  {
    market: "ISONE",
    meter_count: 18,
    utility: "LPH",
  },
  {
    market: "ISONE",
    meter_count: 25,
    utility: "NHEC",
  },
  {
    market: "ISONE",
    meter_count: 26,
    utility: "CELCO",
  },
  {
    market: "PJM",
    meter_count: 32,
    utility: "UGI",
  },
  {
    market: "ISONE",
    meter_count: 42,
    utility: "WMECO",
  },
  {
    market: "PJM",
    meter_count: 43,
    utility: "DPLM",
  },
  {
    market: "PJM",
    meter_count: 49,
    utility: "DVA",
  },
  {
    market: "ISONE",
    meter_count: 51,
    utility: "COMELEC",
  },
  {
    market: "ISONE",
    meter_count: 56,
    utility: "BHE",
  },
  {
    market: "PJM",
    meter_count: 60,
    utility: "CEC",
  },
  {
    market: "PJM",
    meter_count: 61,
    utility: "WEL",
  },
  {
    market: "ISONE",
    meter_count: 64,
    utility: "UNITILNH",
  },
  {
    market: "NYISO",
    meter_count: 67,
    utility: "CENHUD",
  },
  {
    market: "PJM",
    meter_count: 69,
    utility: "POTED",
  },
  {
    market: "ISONE",
    meter_count: 73,
    utility: "BECO",
  },
  {
    market: "NYISO",
    meter_count: 81,
    utility: "NYOR",
  },
  {
    market: "PJM",
    meter_count: 103,
    utility: "PEPCOMD",
  },
  {
    market: "NYISO",
    meter_count: 113,
    utility: "RGE",
  },
  {
    market: "MISO",
    meter_count: 119,
    utility: "AMEREN_IL",
  },
  {
    market: "PJM",
    meter_count: 131,
    utility: "PEPCODC",
  },
  {
    market: "PJM",
    meter_count: 139,
    utility: "AECO",
  },
  {
    market: "PJM",
    meter_count: 158,
    utility: "PENNP",
  },
  {
    market: "ISONE",
    meter_count: 197,
    utility: "MECO",
  },
  {
    market: "PJM",
    meter_count: 199,
    utility: "DUQ",
  },
  {
    market: "ISONE",
    meter_count: 235,
    utility: "UIC",
  },
  {
    market: "PJM",
    meter_count: 243,
    utility: "DPLD",
  },
  {
    market: "PJM",
    meter_count: 245,
    utility: "PEPCODC_DLA1",
  },
  {
    market: "ISONE",
    meter_count: 246,
    utility: "NECO",
  },
  {
    market: "ISONE",
    meter_count: 260,
    utility: "CMP",
  },
  {
    market: "NYISO",
    meter_count: 263,
    utility: "NYSEG",
  },
  {
    market: "PJM",
    meter_count: 280,
    utility: "DPL",
  },
  {
    market: "PJM",
    meter_count: 365,
    utility: "METED",
  },
  {
    market: "PJM",
    meter_count: 388,
    utility: "BGE",
  },
  {
    market: "ISONE",
    meter_count: 409,
    utility: "CLP",
  },
  {
    market: "ISONE",
    meter_count: 421,
    utility: "PSNH",
  },
  {
    market: "PJM",
    meter_count: 490,
    utility: "DUKO",
  },
  {
    market: "PJM",
    meter_count: 512,
    utility: "PECO",
  },
  {
    market: "PJM",
    meter_count: 520,
    utility: "PPL",
  },
  {
    market: "ERCOT",
    meter_count: 524,
    utility: "NEC",
  },
  {
    market: "NYISO",
    meter_count: 525,
    utility: "NIMO",
  },
  {
    market: "PJM",
    meter_count: 529,
    utility: "TED",
  },
  {
    market: "PJM",
    meter_count: 679,
    utility: "AEP_OP",
  },
  {
    market: "PJM",
    meter_count: 795,
    utility: "JCPL",
  },
  {
    market: "NYISO",
    meter_count: 866,
    utility: "CONED",
  },
  {
    market: "PJM",
    meter_count: 897,
    utility: "WPP",
  },
  {
    market: "PJM",
    meter_count: 900,
    utility: "CEI",
  },
  {
    market: "PJM",
    meter_count: 1008,
    utility: "PSEG",
  },
  {
    market: "PJM",
    meter_count: 1015,
    utility: "OED",
  },
  {
    market: "PJM",
    meter_count: 1047,
    utility: "AEP_CS",
  },
  {
    market: "PJM",
    meter_count: 1076,
    utility: "PENELEC",
  },
  {
    market: "ERCOT",
    meter_count: 2007,
    utility: "TNM",
  },
  {
    market: "ERCOT",
    meter_count: 2408,
    utility: "WTU",
  },
  {
    market: "PJM",
    meter_count: 4218,
    utility: "COMED",
  },
  {
    market: "ERCOT",
    meter_count: 8996,
    utility: "CPL",
  },
  {
    market: "ERCOT",
    meter_count: 40406,
    utility: "CNP",
  },
  {
    market: "ERCOT",
    meter_count: 44806,
    utility: "ONC",
  },
];

const INITIAL_STATE = {
  dataToPlot: {
    loading: false,
    meter_count_by_market_and_utility: [],
    meter_count_by_market_and_utility_initial_data: [],
  },
  meterCountByMarketFilterData: {
    loading: false,
    dropDownListItems: {
      markets: [],
      utilities: [],
    },
  },
};


const TOKEN = localStorage.getItem("accessToken");
const HEADER = { headers: { Authorization: `Bearer ${TOKEN}` } };

export const getNoOfMetersByISOGraphSliceAsync = createAsyncThunk(
  "analytics/metersbyisoInitialData",
  async (body) => {
    const response = await axios.get(
      "/api/v1/get_meters_count_by_market_utility",
      HEADER
    );
    return response;
  }
);

export const getUpdatedNoOfMetersByISOGraphSliceAsync = createAsyncThunk(
  "analytics/metersbyisoFilteredData",
  async (body) => {
    const response = await axios.post(
      "/api/v1/get_filtered_meters_count_by_market_utility",
      body,
      HEADER
    );
    return response;
  }
);

export const numberOfMetersByISOSlice = createSlice({
  name: "analytics/metersbyiso",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNoOfMetersByISOGraphSliceAsync.pending, (state) => {
        state.dataToPlot.loading = true;
        state.meterCountByMarketFilterData.loading = true;
      })
      .addCase(getNoOfMetersByISOGraphSliceAsync.fulfilled, (state, action) => {
        state.dataToPlot.loading = false;
        state.meterCountByMarketFilterData.loading = false;
        state.meterCountByMarketFilterData.dropDownListItems.markets = action
          .payload.data.markets
          ? action.payload.data.markets
          : [];
        state.meterCountByMarketFilterData.dropDownListItems.utilities = action
          .payload.data.utilities
          ? action.payload.data.utilities
          : [];

        state.dataToPlot.meter_count_by_market_and_utility = action
          .payload.data
          ? action.payload.data.meter_count_by_market_and_utility
          : {};

        // state.dataToPlot.meter_count_by_market_and_utility = action.payload.data
        //   ? action.payload.data.meter_count_by_market_and_utility
        //   : DATA;
      })
      .addCase(getNoOfMetersByISOGraphSliceAsync.rejected, (state, action) => {
        state.dataToPlot.loading = false;
        state.meterCountByMarketFilterData.loading = false;
      })

      .addCase(getUpdatedNoOfMetersByISOGraphSliceAsync.pending, (state) => {
        state.dataToPlot.loading = true;
      })
      .addCase(
        getUpdatedNoOfMetersByISOGraphSliceAsync.fulfilled,
        (state, action) => {
          state.dataToPlot.loading = false;
          // state.dataToPlot.meter_count_by_market_and_utility_initial_data =
          //   action.payload.data
          //     ? action.payload.data.meter_count_by_market_and_utility
          //     : DATA;

          state.dataToPlot.meter_count_by_market_and_utility = action.payload
            .data
            ? action.payload.data.meter_count_by_market_and_utility
            : {};
        }
      )
      .addCase(
        getUpdatedNoOfMetersByISOGraphSliceAsync.rejected,
        (state, action) => {
          state.dataToPlot.loading = false;
        }
      );
  },
});

// Action creators are generated for each case reducer function
export default numberOfMetersByISOSlice.reducer;
