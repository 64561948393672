import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  axiosForecastRiskInstance as axios,
  axiosRiskInstance,
} from "axios.js";

const initialState = {
  hedgeVolumeTableData: { list: [], loader: false },
  positionalDetailsChart: {
    data: [],
    loader: false,
  },
  imbalanceDetailsChart: {
    data: [],
    loader: false,
  },
  peakDetailsChart: {
    data: [],
    loader: false,
  },
};

export const getPositionChartHedgeVolumeTableDataApi = createAsyncThunk(
  "risk/positionchart/get",
  () => {
    const response = axios.get(`/api/v1/get_hedge_volume`).then((res) => {
      return res;
    });
    return response;
  }
);
export const getPositionChartDetailsDataApi = createAsyncThunk(
  "risk/positionchart/details/get",
  () => {
    const response = axios
      .get(`/api/v1/get_position_details_chart`)
      .then((res) => {
        return res;
      });
    return response;
  }
);

export const getImbalanceDetailsChartDataApi = createAsyncThunk(
  "risk/imbalance/data/get",
  () => {
    const response = axiosRiskInstance
      .get(`/api/v1/lt_position_reporting/long_term_imbalance_details`)
      .then((res) => {
        return res;
      });
    return response;
  }
);
export const getPeakDetailsChartDataApi = createAsyncThunk(
  "risk/peak/details/get",
  () => {
    const response = axiosRiskInstance
      .get(`/api/v1/lt_position_reporting/long_term_peak_details`)
      .then((res) => {
        return res;
      });
    return response;
  }
);
export const positionChartEngieSlice = createSlice({
  name: "risk/positionchart",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPositionChartHedgeVolumeTableDataApi.pending, (state) => {
        state.hedgeVolumeTableData.loader = true;
        // state.market.filterLoader = true;
      })
      .addCase(
        getPositionChartHedgeVolumeTableDataApi.fulfilled,
        (state, action) => {
          state.hedgeVolumeTableData.loader = false;

          state.hedgeVolumeTableData.list =
            action?.payload?.data?.headge_volume || [];
        }
      )
      .addCase(
        getPositionChartHedgeVolumeTableDataApi.rejected,
        (state, action) => {
          state.marketAreaExposureData.loader = false;
        }
      )
      .addCase(getPositionChartDetailsDataApi.pending, (state) => {
        state.positionalDetailsChart.loader = true;
        // state.market.filterLoader = true;
      })
      .addCase(getPositionChartDetailsDataApi.fulfilled, (state, action) => {
        state.positionalDetailsChart.loader = false;

        state.positionalDetailsChart.data =
          action?.payload?.data?.position_details || [];
        //   state.market.list = action?.payload?.data?.trading_points || [];
        //   state.market.filterLoader = false;
      })
      .addCase(getPositionChartDetailsDataApi.rejected, (state, action) => {
        state.positionalDetailsChart.loader = false;
        //   state.market.filterLoader = false;
      })
      .addCase(getImbalanceDetailsChartDataApi.pending, (state) => {
        state.imbalanceDetailsChart.loader = true;
      })
      .addCase(getImbalanceDetailsChartDataApi.fulfilled, (state, action) => {
        state.imbalanceDetailsChart.loader = false;

        state.imbalanceDetailsChart.data = action?.payload?.data?.data || [];
      })
      .addCase(getImbalanceDetailsChartDataApi.rejected, (state, action) => {
        state.imbalanceDetailsChart.loader = false;
      })
      .addCase(getPeakDetailsChartDataApi.pending, (state) => {
        state.peakDetailsChart.loader = true;
      })
      .addCase(getPeakDetailsChartDataApi.fulfilled, (state, action) => {
        state.peakDetailsChart.loader = false;
        state.peakDetailsChart.data = action?.payload?.data?.data || [];
      })
      .addCase(getPeakDetailsChartDataApi.rejected, (state, action) => {
        state.peakDetailsChart.loader = false;
      });
  },
});
// export const { selectMarket, loadMarket } = summarySliceEngie.actions;
export default positionChartEngieSlice.reducer;
