import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosForecastInstance as axios } from "axios.js";

const INITIAL_STATE = {
  longTermForecastingFilterData: {
    loader: false,
    dropDownListItems: {
      block_types: [],
      customer_types: [],
      flow_years: [],
      markets: [],
      product_types: [],
      zones: [],
      batch_dates: [],
      utility_code: [],
      load_types: [],
    },
  },
  utility_zones_by_market: {},
  longTermForecastingConsumptionChartData: {
    loader: false,
    dataToPlot: [],
    longTermDeltaBlock: {},
  },
  dropDownList: {
    block_types: [],
    customer_types: [],
    flow_years: [],
    markets: [],
    product_types: [],
    zones: [],
    batch_date: "",
    utility_code: [],
    load_type: "",
  },
  dateOne: "",
  dateTwo: "",
  filterStatus: false,
  attritionData: [],
};

export const getLongTermForecastInitialDataAsync = createAsyncThunk(
  "forecasting/getLongTermInitialData",
  async (data) => {
    const response = await axios.get("/api/v1/getLongTermData", data);
    return response;
  }
);

export const getUpdatedLongTermForecastDataAsync = createAsyncThunk(
  "forecasting/getLongTermUpdatedData",
  async (data) => {
    const response = await axios.post(
      "/api/v1/getLongTermDataWithFilterWithAttrition",
      data
    );
    return response;
  }
);

export const longTermForecastSlice = createSlice({
  name: "forecasting/longterm",
  initialState: INITIAL_STATE,
  reducers: {
    dateOneChange: (state, action) => {
      state.dateOne = action.payload;
    },
    dateTwoChange: (state, action) => {
      state.dateTwo = action.payload;
    },
    setFilterStatus: (state, action) => {
      state.filterStatus = action.payload;
    },
    attritionPercentageData: (state, action) => {
      state.attritionData = action.payload;
    },
    marketOnChange: (state, action) => {
      let load_zone = [];
      let utility = [];
      if (action?.payload.length) {
        for (let val of action?.payload) {
          load_zone.push(...state.utility_zones_by_market[val]?.zones);
          utility.push(...state.utility_zones_by_market[val]?.utilities);
        }
      }
      //  else {
      //   Object.keys(state.utility_zones_by_market).forEach((key, index) => {
      //     data.push(...state.utility_zones_by_market[key]["zones"]);
      //   });
      // }
      state.longTermForecastingFilterData.dropDownListItems.zones = load_zone;
      state.longTermForecastingFilterData.dropDownListItems.utility_code =
        utility;
      state.dropDownList.markets = action.payload || [];
      state.dropDownList.zones = load_zone || [];
      state.dropDownList.utility_code = utility || [];
    },
    setDropDownListChangeNew: (state, action) => {
      const values = action?.payload?.values || [];
      const type = action?.payload?.type;
      if (type) {
        state.dropDownList[type] = values;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLongTermForecastInitialDataAsync.pending, (state) => {
        state.longTermForecastingFilterData.loader = true;
        state.longTermForecastingConsumptionChartData.loader = true;
      })
      .addCase(
        getLongTermForecastInitialDataAsync.fulfilled,
        (state, action) => {
          state.longTermForecastingFilterData.loader = false;
          state.longTermForecastingConsumptionChartData.loader = false;
          const removeDuplicates = (list) => {
            let newData = [...new Set(list)];
            return newData;
          };
          let { allZones, allUtilities } = Object.values(
            action?.payload?.data?.utility_zones_by_market
          ).reduce(
            (tot, cur) => {
              return {
                allZones: removeDuplicates([...tot.allZones, ...cur.zones]),
                allUtilities: removeDuplicates([
                  ...tot.allUtilities,
                  ...cur.utilities,
                ]),
              };
            },

            { allZones: [], allUtilities: [] }
          );
          state.longTermForecastingFilterData.dropDownListItems = {
            zones: allZones || [],
            load_types: ["Wholesale load", "Metered load"],
            utility_code: allUtilities || [],
            block_types: action?.payload?.data?.block_types || [],
            customer_types: action?.payload?.data?.customer_types || [],
            flow_years: action?.payload?.data?.flow_years || [],
            product_types: action?.payload?.data?.product_types || [],
            markets: action?.payload?.data?.markets || [],
            batch_dates: action?.payload?.data?.batch_dates || [],
          };

          state.utility_zones_by_market =
            action?.payload?.data?.utility_zones_by_market || {};

          state.longTermForecastingConsumptionChartData.dataToPlot =
            action?.payload?.data?.long_term_data || [];
          state.longTermForecastingConsumptionChartData.longTermDeltaBlock =
            action?.payload?.data?.long_term_delta || {};
          state.dateTwo = action?.payload?.data?.batch_dates[0] || "";
          state.dateOne = action?.payload?.data?.batch_dates[1] || "";
        }
      )
      .addCase(getLongTermForecastInitialDataAsync.rejected, (state) => {
        state.longTermForecastingFilterData.loader = false;
        state.longTermForecastingConsumptionChartData.loader = false;
      })
      .addCase(getUpdatedLongTermForecastDataAsync.pending, (state) => {
        state.longTermForecastingConsumptionChartData.loader = true;
      })
      .addCase(
        getUpdatedLongTermForecastDataAsync.fulfilled,
        (state, action) => {
          state.longTermForecastingConsumptionChartData.loader = false;
          state.longTermForecastingConsumptionChartData.dataToPlot =
            action?.payload?.data?.long_term_data;
          state.longTermForecastingConsumptionChartData.longTermDeltaBlock =
            action?.payload?.data?.long_term_delta || {};
          // ?.length > 0
          //   ? action?.payload?.data?.long_term_data
          //   : state.longTermForecastingConsumptionChartData.dataToPlot;
        }
      )
      .addCase(getUpdatedLongTermForecastDataAsync.rejected, (state) => {
        state.longTermForecastingConsumptionChartData.loader = false;
      });
  },
});

export const {
  marketOnChange,
  setDropDownListChangeNew,
  dateTwoChange,
  dateOneChange,
  setFilterStatus,
  attritionPercentageData,
} = longTermForecastSlice.actions;

export default longTermForecastSlice.reducer;
