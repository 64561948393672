import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios.js";

const initialState = {
  modelItems: { list: [], loader: false },
};

export const getComponentItemListAsync = createAsyncThunk(
  "getComponentItemListAsync",
  (modelId) => {
    const response = axios
      .get(`/api/v1/pricingmodel/get/quote/item/${modelId}`)
      .then((res) => {
        return res;
      });
    return response;
  }
);

export const pricingModelComponentViewSlice = createSlice({
  name: "pricingModelComponentViewSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getComponentItemListAsync.pending, (state) => {
        state.modelItems.loader = true;
        state.modelItems.list = [];
      })
      .addCase(getComponentItemListAsync.fulfilled, (state, action) => {
        state.modelItems.loader = false;
        state.modelItems.list = action?.payload?.data?.data || [];
      })
      .addCase(getComponentItemListAsync.rejected, (state, action) => {
        state.modelItems.loader = false;
        state.modelItems.list = [];
      });
  },
});

// Action creators are generated for each case reducer function
export default pricingModelComponentViewSlice.reducer;
