import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosForecastInstance as axios } from "axios.js";

const INITIAL_STATE = {
  dashBoardSitelevelForecastingChartData: {
    loader: false,
    dataToPlot: [],
    siteLevelbatchDates: {},
  },
  dashBoardSitelevelForecastingDrillDownChartData: {
    loader: false,
    dataToPlot: [],
  },
};

export const getdashboardSitelevelForecastInitialDataAsync = createAsyncThunk(
  "forecasting/getDashboardSitelevel",
  async (data) => {
    const response = await axios.get(`/api/v1/get_sites_with_higer_delta/10`);
    return response;
  }
);

export const getdashboardSitelevelForecastDrillDownInitialDataAsync =
  createAsyncThunk(
    "forecasting/getDashboardSitelevelDrillDown",
    async (data) => {
      const response = await axios.get(
        `/api/v1/delta_drill_down_by_product/${data.site}`
      );
      return response;
    }
  );

export const dashboardSitelevelSlice = createSlice({
  name: "forecasting/dashboard/sitelevel",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getdashboardSitelevelForecastInitialDataAsync.pending,
        (state) => {
          state.dashBoardSitelevelForecastingChartData.loader = true;
        }
      )
      .addCase(
        getdashboardSitelevelForecastInitialDataAsync.fulfilled,
        (state, action) => {
          state.dashBoardSitelevelForecastingChartData.loader = false;

          state.dashBoardSitelevelForecastingChartData.dataToPlot =
            action?.payload?.data?.sites_with_higher_delta_load || [];

          state.dashBoardSitelevelForecastingChartData.siteLevelbatchDates =
            action?.payload?.data?.batch_dates || [];
        }
      )
      .addCase(
        getdashboardSitelevelForecastInitialDataAsync.rejected,
        (state) => {
          state.dashBoardSitelevelForecastingChartData.loader = false;
        }
      )
      .addCase(
        getdashboardSitelevelForecastDrillDownInitialDataAsync.pending,
        (state) => {
          state.dashBoardSitelevelForecastingDrillDownChartData.loader = true;
        }
      )
      .addCase(
        getdashboardSitelevelForecastDrillDownInitialDataAsync.fulfilled,
        (state, action) => {
          state.dashBoardSitelevelForecastingDrillDownChartData.loader = false;

          state.dashBoardSitelevelForecastingDrillDownChartData.dataToPlot =
            action?.payload?.data?.load_by_batch_date_and_product || [];
        }
      )
      .addCase(
        getdashboardSitelevelForecastDrillDownInitialDataAsync.rejected,
        (state) => {
          state.dashBoardSitelevelForecastingDrillDownChartData.loader = false;
        }
      );
  },
});

export default dashboardSitelevelSlice.reducer;
