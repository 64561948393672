import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosForecastInstance as axios } from "axios.js";

const INITIAL_STATE = {
  newData: {
    loader: false,
    filterData: [],
    chartData: [],
    day_types: [],
  },
  get_filtered_data: false,
  performanceDownloadData: { loader: false, data: {} },
  loader: false,
  loadGraph: false,
  forecast_dates: [],
  forecast_year_months: [],
  load_amounts: [],
  lse_codes: [],
  market_cd: [],
  variance_mape_data: [],
  zones: [],

  titleData: "",
  zone_by_market: {},

  forecastPerformanceFilterData: {
    loader: false,
    dropDownListItems: {
      forecast_dates: [],
      forecast_year_months: [],
      lse_codes: [],
      market_codes: [],
      zones: [],
      day_types: [],
    },
  },
  forecastPerformanceVarianceChartData: { loader: false, dataToPlot: [] },
  forecastPerformanceLoadAmountChartData: { loader: false, dataToPlot: [] },

  varianceDataActive: [{ data: [], lse_code: "" }],
  loadAmountDataActive: [{ data: [], lse_code: "" }],
};

export const getForecastPerformanceInitialDataNewAsync = createAsyncThunk(
  "forecasting/getPerformanceInitialData_new",
  async (data) => {
    const response = await axios.get(
      "/api/v1/forecast_performance_with_init_and_final_settlements",
      data
    );
    return response;
  }
);

export const getForecastPerformanceFilterDataNewAsync = createAsyncThunk(
  "forecasting/getPerformanceFilterData_new",
  async (data) => {
    const response = await axios.post(
      "/api/v1/forecast_performance_with_settlements_filter",
      data
    );
    return response;
  }
);

export const getForecastPerformanceInitialDataAsync = createAsyncThunk(
  "forecasting/getPerformanceInitialData",
  async (data) => {
    const response = await axios.get("/api/v1/forecast_performance_v2", data);
    return response;
  }
);

export const getUpdatedForecastPerformanceDataAsync = createAsyncThunk(
  "forecasting/getPerformanceUpdatedData",
  async (data) => {
    const response = await axios.post(
      "/api/v1/forecast_performance_with_filter_v2",
      data
    );
    return response;
  }
);

export const getPerformanceDownloadableFile = createAsyncThunk(
  "forecasting/performance/file/data",
  async (body) => {
    // try {
    const response = await axios.get(`/api/v1/mape_data_export`);
    return response;
  }
);

export const performanceForecastUpdatedUISlice = createSlice({
  name: "forecasting/performance",
  initialState: INITIAL_STATE,
  reducers: {
    marketOnChange: (state, action) => {
      let data = [];
      if (action?.payload.length) {
        for (let val of action?.payload) {
          data.push(...state.zone_by_market[val]?.zones);
        }
      } else {
        Object.keys(state.zone_by_market).forEach((key, index) => {
          data.push(...state.zone_by_market[key]["zones"]);
        });
      }
      state.forecastPerformanceFilterData.dropDownListItems.zones = data;
    },
    loadAmountSearchByLseCode: (state, action) => {
      state.loadAmountDataActive =
        state.forecastPerformanceLoadAmountChartData.dataToPlot.filter(
          (data) => {
            return data.lse_code === action.payload;
          }
        );
    },
    varianceSearchByLseCode: (state, action) => {
      state.varianceDataActive =
        state.forecastPerformanceVarianceChartData.dataToPlot.filter((data) => {
          return data.lse_code === action.payload;
        });
    },
    setTitle: (state, action) => {
      state.titleData = action.payload;
    },
    resetNewData: (state, action) => {
      state.newData = {
        loader: false,
        filterData: [],
        chartData: [],
        day_types: [],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getForecastPerformanceInitialDataAsync.pending, (state) => {
        state.get_filtered_data = false;
        state.loader = true;
        state.loadGraph = true;

        state.forecastPerformanceFilterData.loader = true;
        state.forecastPerformanceVarianceChartData.loader = true;
        state.forecastPerformanceLoadAmountChartData.loader = true;
      })
      .addCase(
        getForecastPerformanceInitialDataAsync.fulfilled,
        (state, action) => {
          // changes
          state.forecastPerformanceFilterData.loader = false;
          state.forecastPerformanceVarianceChartData.loader = false;
          state.forecastPerformanceLoadAmountChartData.loader = false;

          state.forecastPerformanceFilterData.dropDownListItems = {
            forecast_dates: action?.payload?.data?.forecast_dates || [],
            forecast_year_months:
              action?.payload?.data?.forecast_year_months || [],
            lse_codes: action?.payload?.data?.lse_codes || [],
            market_codes: action?.payload?.data?.market_cd || [],
            zones: action?.payload?.data?.zones || [],
            day_types: action?.payload?.data?.day_types || [],
          };

          state.forecastPerformanceVarianceChartData.dataToPlot =
            action?.payload?.data?.variance_mape_data || [];

          state.forecastPerformanceLoadAmountChartData.dataToPlot =
            action?.payload?.data?.load_amounts || [];

          state.varianceDataActive =
            [action?.payload?.data?.variance_mape_data[0]] || [];

          state.loadAmountDataActive =
            [action?.payload?.data?.load_amounts[0]] || [];

          state.titleData = action?.payload?.data?.forecast_dates.at(-1);

          state.zone_by_market = action?.payload?.data?.zone_by_market || {};
          // old
          // state.loader = false;
          // state.loadGraph = false;
          state.get_filtered_data = true;

          // state.forecast_dates = action?.payload?.data?.forecast_dates || [];
          // state.forecast_year_months =
          //   action?.payload?.data?.forecast_year_months || [];
          // state.load_amounts = action?.payload?.data?.load_amounts || [];
          // state.lse_codes = action?.payload?.data?.lse_codes || [];
          // state.market_codes = action?.payload?.data?.market_cd || [];
          // state.variance_mape_data =
          //   action?.payload?.data?.variance_mape_data || [];
          // state.zones = action?.payload?.data?.zones || [];
        }
      )
      .addCase(getForecastPerformanceInitialDataAsync.rejected, (state) => {
        state.loader = false;
        state.loadGraph = false;
        state.get_filtered_data = false;

        state.forecastPerformanceFilterData.loader = false;
        state.forecastPerformanceVarianceChartData.loader = false;
        state.forecastPerformanceLoadAmountChartData.loader = false;
      })
      .addCase(getUpdatedForecastPerformanceDataAsync.pending, (state) => {
        state.loadGraph = true;

        state.forecastPerformanceVarianceChartData.loader = true;
        state.forecastPerformanceLoadAmountChartData.loader = true;
      })
      .addCase(
        getUpdatedForecastPerformanceDataAsync.fulfilled,
        (state, action) => {
          state.loadGraph = false;

          // state.forecastPerformanceVarianceChartData.dataToPlot =
          //   action?.payload?.data?.variance_mape_data ||
          //   state.forecastPerformanceVarianceChartData.dataToPlot
          //     .variance_mape_data;

          // state.forecastPerformanceLoadAmountChartData.dataToPlot =
          //   action?.payload?.data?.load_amounts ||
          //   state.forecastPerformanceLoadAmountChartData.dataToPlot.load_amounts;
          state.varianceDataActive =
            [action?.payload?.data?.variance_mape_data[0]] ||
            state.varianceDataActive;

          state.loadAmountDataActive =
            [action?.payload?.data?.load_amounts[0]] ||
            state.loadAmountDataActive;

          state.forecastPerformanceVarianceChartData.loader = false;
          state.forecastPerformanceLoadAmountChartData.loader = false;
        }
      )
      .addCase(getUpdatedForecastPerformanceDataAsync.rejected, (state) => {
        state.loadGraph = false;

        state.forecastPerformanceVarianceChartData.loader = false;
        state.forecastPerformanceLoadAmountChartData.loader = false;
      })
      .addCase(getForecastPerformanceInitialDataNewAsync.pending, (state) => {
        state.newData.loader = true;
      })
      .addCase(
        getForecastPerformanceInitialDataNewAsync.fulfilled,
        (state, action) => {
          state.newData.loader = false;
          state.newData.filterData =
            action?.payload?.data?.market_zone_data || [];
          state.newData.chartData =
            action?.payload?.data?.variance_and_load_details || [];
          state.newData.day_types = action?.payload?.data?.day_types || [];
        }
      )
      .addCase(getForecastPerformanceInitialDataNewAsync.rejected, (state) => {
        state.newData.loader = false;
      })
      .addCase(getForecastPerformanceFilterDataNewAsync.pending, (state) => {
        state.newData.loader = true;
      })
      .addCase(
        getForecastPerformanceFilterDataNewAsync.fulfilled,
        (state, action) => {
          state.newData.loader = false;
          state.newData.chartData =
            action?.payload?.data?.variance_and_load_details || [];
        }
      )
      .addCase(getForecastPerformanceFilterDataNewAsync.rejected, (state) => {
        state.newData.loader = false;
      })
      .addCase(getPerformanceDownloadableFile.rejected, (state, action) => {
        state.performanceDownloadData.loader = false;
        state.performanceDownloadData.data = [];

        // changes
      })
      .addCase(getPerformanceDownloadableFile.pending, (state, action) => {
        state.performanceDownloadData.loader = true;
        state.performanceDownloadData.data = {};

        // changes
      })
      .addCase(getPerformanceDownloadableFile.fulfilled, (state, action) => {
        state.performanceDownloadData.loader = false;
        state.performanceDownloadData.data = action?.payload?.data
          ? action?.payload?.data
          : {};

        // changes
      });
  },
});
export const {
  loadAmountSearchByLseCode,
  varianceSearchByLseCode,
  setTitle,
  marketOnChange,
  resetNewData,
} = performanceForecastUpdatedUISlice.actions;
export default performanceForecastUpdatedUISlice.reducer;
