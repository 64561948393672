import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosForecastInstance as axios } from "axios.js";

const INITIAL_STATE = {
  get_filtered_weather_data: false,

  weather_data: [],
  markets: [],
  loaderWeather: false,
  loadWeatherGraph: false,

  weatherForecastingChartData: {
    loader: false,
    dataToPlot: [],
  },
};

export const getWeatherLongTermForecastInitialDataAsync = createAsyncThunk(
  "forecasting/getWeatherLongTermInitialData",

  async (data) => {
    const response = await axios.get(
      "/api/v1/get_normal_weather_long_term",
      data
    );
    return response;
  }
);

export const getUpdatedLongTermWeatherForecastDataAsync = createAsyncThunk(
  "forecasting/getWeatherLongTermUpdatedData",

  async (data) => {
    const response = await axios.post(
      "/api/v1/get_normal_weather_long_term_filtered",
      data
    );
    return response;
  }
);

export const weatherLongTermForecastSlice = createSlice({
  name: "forecasting/weather",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWeatherLongTermForecastInitialDataAsync.pending, (state) => {
        state.loadWeatherGraph = true;
        state.loaderWeather = true;

        state.weatherForecastingChartData.loader = true;
      })
      .addCase(
        getWeatherLongTermForecastInitialDataAsync.fulfilled,
        (state, action) => {
          state.loadWeatherGraph = false;
          state.get_filtered_weather_data = true;
          state.loaderWeather = false;

          state.markets = action?.payload?.data?.markets || [];
          state.weather_data = action?.payload?.data?.weather_data || [];

          // changes
          state.weatherForecastingChartData.loader = false;
          state.weatherForecastingChartData.dataToPlot =
            action?.payload?.data?.weather_data || [];
        }
      )
      .addCase(getWeatherLongTermForecastInitialDataAsync.rejected, (state) => {
        state.loadWeatherGraph = false;
        state.loaderWeather = false;
        state.get_filtered_weather_data = false;

        // changes
        state.weatherForecastingChartData.loader = false;
      })
      .addCase(getUpdatedLongTermWeatherForecastDataAsync.pending, (state) => {
        state.loadWeatherGraph = true;

        // changes
        state.weatherForecastingChartData.loader = true;
      })
      .addCase(
        getUpdatedLongTermWeatherForecastDataAsync.fulfilled,
        (state, action) => {
          state.loadWeatherGraph = false;
          state.weather_data =
            action?.payload?.data?.weather_data || state.weather_data;

          // changes
          state.weatherForecastingChartData.loader = false;
          state.weatherForecastingChartData.dataToPlot =
            action?.payload?.data?.weather_data?.length > 0
              ? action?.payload?.data?.weather_data
              : [];
        }
      )
      .addCase(getUpdatedLongTermWeatherForecastDataAsync.rejected, (state) => {
        state.loadWeatherGraph = false;

        // changes
        state.weatherForecastingChartData.loader = false;
      });
  },
});

export default weatherLongTermForecastSlice.reducer;
