import { combineReducers } from "redux";
import NotificationReducer from "./NotificationReducer";
import pricingClientSlice from "../slices/pricing/pricingClientSlice";
import pricingContractSlice from "../slices/pricing/pricingContractSlice";
import pricingCustomerSlice from "../slices/pricing/pricingCustomerSlice";
import pricingAccountSlice from "../slices/pricing/pricingAccountsSlice";
import sideNavigationSlice from "../slices/navigation/sideNavigationSlice";
import pricingFeeSlice from "../slices/pricing/pricingFeeSlice";
import pricingConfigProductSlice from "../slices/pricing/configruation/pricingConfigProductSlice";
import pricingRunResultSlice from "../slices/pricing/pricingRunResultSlice";
import customerDataSlice from "../slices/customer/customerDataSlice";
import customerAddAccountSlice from "../slices/customer/customerAddAccountSlice";
import customerAddSlice from "../slices/customer/customerAddSlice";
import pricingRunsDataSlice from "../slices/pricing/pricingRunsDataSlice";
import pricingConfigComponentSlice from "../slices/pricing/configruation/pricingConfigComponentSlice";
import pricingConfigNewComponentSlice from "../slices/pricing/configruation/pricingConfigNewComponent";
import pricingModelComponentViewSlice from "../slices/pricing/pricingViewModelSlice";
import pricingUploadSlice from "../slices/pricing/upload/pricingUploadSlice";

import utilityForecastSlice from "../slices/forecasting/forecastingUtilityHeatmapSlice";
import zoneTreeForecastSlice from "../slices/forecasting/forecastingZoneTreemapSlice";
import forecastingSiteLevelSlice from "../slices/forecasting/forecastingSiteLevelSlice";
import shortTermForecastSlice from "../slices/forecasting/forecastingShortTermSlice";
import longTermForecastSlice from "../slices/forecasting/forecastingLongTermSlice";
import performanceForecastSlice from "../slices/forecasting/forecastingPerformanceSlice";
import weatherForecastSlice from "../slices/forecasting/forecastingWeatherSlice";
import manageExistingCustomerSlice from "../slices/customer/manageExistingCustomerSlice";
import customerContactSlice from "../slices/customer/customerContactSlice";
import wetherScenarioSlice from "../slices/forecasting/forecastingWetherScenarios";
import numberOfResidentialSlice from "../slices/analytic/analyticsNumberOfResidentialSlice";
import mtmSlice from "../slices/risk/mtmSlice";
import varSlice from "../slices/risk/varSlice";
import annualVolumeSlice from "../slices/analytic/analyticsAnnualMwhVolumes";
import numberOfMetersByISOSlice from "../slices/analytic/numberOfMetersByISOSlice";

import summaryGraphSlice from "../slices/analytic/analyticsSummarySlice";
import assetUsageSlice from "../slices/asset/assetUsageSlice";
import assetValuationSlice from "../slices/asset/markToMarketDataSlice";
import varSliceEngie from "../slices/risk/varSliceEngie";
import summarySliceEngie from "../slices/risk/summarySliceEngie";
import mtmEngieSlice from "../slices/risk/mtmSliceEngie";
import riskPositionSliceEngie from "../slices/risk/postionSlice";
import riskDetailSliceEngie from "../slices/risk/riskDetailSlice";
import positionChartEngieSlice from "../slices/risk/positionChartEngine";
import fileUploadLogSlice from "../slices/reports/fileUploadLogSlice";
import tradeCaptureSlice from "../slices/risk/tradeCaptureSlice";
import weatherLongTermForecastSlice from "../slices/forecasting/forecastingLongTermWeatherSlice";
import configDomainSlice from "../slices/admin/configDomainSlice";
import configElementSlice from "../slices/admin/configElementSlice";
import configAttributeSlice from "../slices/admin/configAttributeSlice";
import dashboardShortTermSlice from "../slices/forecasting/dashboardSlices/dashboardShortTermSlice";
import dashboardLongTermSlice from "../slices/forecasting/dashboardSlices/dashboardLongtermSlice";
import dashboardSitelevelSlice from "../slices/forecasting/dashboardSlices/dashboardSitelevelSlice";
import dashboardMapeSlice from "../slices/forecasting/dashboardSlices/dashboardMapeSlice";
import dashboardAccountsSlice from "../slices/forecasting/dashboardSlices/dashboardAccountsSlice";
import prospectSetUploadSlice from "../slices/pricing/upload/prospectMultyUpload";
import settlementVolumetricSlice from "../slices/settlement/settlementVolumetricSlice";
import settlementFinancialsSlice from "../slices/settlement/settlementFinancialsSlice";
import shortTermPositionSlice from "../slices/risk/positionReporting/shortTermPositionSlice";
import longTermPositionSlice from "../slices/risk/positionReporting/longtermPosition";
import refreshCheckSlice from "../slices/risk/positionReporting/refreshLoadingSlice";
import serviceProfileSlice from "../slices/admin/serviceProfileSlice";
const RootReducer = combineReducers({
  notifications: NotificationReducer,
  pricingClient: pricingClientSlice,
  pricingContract: pricingContractSlice,
  pricingCustomer: pricingCustomerSlice,
  pricingCustomerAccounts: pricingAccountSlice,
  pricingFee: pricingFeeSlice,
  sideNavigation: sideNavigationSlice,
  pricingConfigProduct: pricingConfigProductSlice,
  pricingRunResult: pricingRunResultSlice,
  pricingConfigComponent: pricingConfigComponentSlice,
  customerData: customerDataSlice,
  customerAddAccount: customerAddAccountSlice,
  addCustomer: customerAddSlice,
  pricingRunsData: pricingRunsDataSlice,
  pricingConfigNewComponent: pricingConfigNewComponentSlice,
  pricingModelItems: pricingModelComponentViewSlice,
  pricingUpload: pricingUploadSlice,
  configDomain: configDomainSlice,
  configElement: configElementSlice,
  configAttribute: configAttributeSlice,
  wetherScenarioData: wetherScenarioSlice,
  utilityForecastData: utilityForecastSlice,
  zoneTreeForecastData: zoneTreeForecastSlice,
  shortTermForecastData: shortTermForecastSlice,
  forecastingSiteLevelData: forecastingSiteLevelSlice,
  longTermForecastData: longTermForecastSlice,
  performanceForecastData: performanceForecastSlice,
  weatherForecastData: weatherForecastSlice,
  weatherLongTermForecastSlice: weatherLongTermForecastSlice,
  manageExistingCustomerData: manageExistingCustomerSlice,
  customerContactData: customerContactSlice,
  shortTermPositionData: shortTermPositionSlice,
  longTermPositionData: longTermPositionSlice,
  dashboardShortTermData: dashboardShortTermSlice,
  dashboardLongTermData: dashboardLongTermSlice,
  dashboardSitelevelData: dashboardSitelevelSlice,
  dashboardMapeData: dashboardMapeSlice,
  dashboardAccountsData: dashboardAccountsSlice,
  serviceProfileSlice: serviceProfileSlice,
  annualVolumeData: annualVolumeSlice,
  numberOfMetersByISOData: numberOfMetersByISOSlice,
  summaryGraphData: summaryGraphSlice,

  numberOfResidentialData: numberOfResidentialSlice,
  mtmSliceData: mtmSlice,
  mtmEngieSliceData: mtmEngieSlice,
  varSliceData: varSlice,
  assetUsageData: assetUsageSlice,
  varSliceEngieData: varSliceEngie,
  summarySliceEngieData: summarySliceEngie,
  settlementFinancialsData: settlementFinancialsSlice,
  refreshCheckSlice: refreshCheckSlice,
  assetValuationData: assetValuationSlice,
  riskPositionEngieData: riskPositionSliceEngie,
  riskDetailSliceEngieData: riskDetailSliceEngie,
  positionChartData: positionChartEngieSlice,
  fileUploadLogData: fileUploadLogSlice,
  settlementVolumetricData: settlementVolumetricSlice,
  tradeCaptureData: tradeCaptureSlice,
  prospectSetUploadData: prospectSetUploadSlice,
});

export default RootReducer;
