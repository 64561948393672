import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { axiosConfigManagement as axios } from "axios.js";
import { axiosPricingProspectDataSet } from "axios.js";
import { object } from "yup";

const initialState = {
  validateFiles: {},
  validatePendingFiles: [],
  feildParams: { params: {} },
  summaryCustomers: {},
  summaryAccounts: [],
};
export const processAllProspectSetData = createAsyncThunk(
  "costomer/prospectSet/processProspectMultyFileData",
  async (data) => {
    const {
      guid,
      iso_code,
      utility,
      summaryAccounts,
      summaryCustomers,
      client_code,
    } = data;

    const response = await axiosPricingProspectDataSet.post(
      `/api/v1/data-load/client-prospect/load?group_identifier=${guid}`,
      {
        accounts: Object.values(summaryAccounts),
        customer: summaryCustomers,
        iso_code,
        utility,
        client_code,
      }
    );
    return response;
  }
);
export const getFilesValidateUploadProspectSet = createAsyncThunk(
  "costomer/prospectSet/getFilesValidations",
  async (data) => {
    const { client_code, guid, file, type } = data;
    const response = await axiosPricingProspectDataSet.post(
      `/api/v1/data-load/client-prospect/upload?client_code=${client_code}&group_identifier=${guid}&type=${type}`,
      file
    );
    return response;
  }
);
export const getFeildParamsPricingMultyUploadAsync = createAsyncThunk(
  "admin/prospectSet/getFeildParam",
  async (data) => {
    const { param_name } = data;
    const response = await axios.get(`/api/v1/configuration/${param_name}/get`);
    return { response, param_name };
  }
);
export const prospectSetUploadSlice = createSlice({
  name: "pricing/upload",
  initialState,
  reducers: {
    updateProspectSetAccountDetails: (state, action) => {
      if (state.summaryAccounts[action.payload.accountNumber]) {
        state.summaryAccounts[action.payload.accountNumber] = {
          ...state.summaryAccounts[action.payload.accountNumber],
          [action.payload.key]: action.payload.value,
        };
      }
    },
    updateProspectSetAccountWholeData: (state, action) => {
      if (state.summaryAccounts[action.payload.accountNumber]) {
        state.summaryAccounts[action.payload.accountNumber] = {
          ...state.summaryAccounts[action.payload.accountNumber],
          ...(action.payload.data || {}),
        };
      }
    },
    resetPricingMultiFileUplaodState: (state, action) => {
      state.validateFiles = {};
      state.feildParams = { params: {} };
      state.summaryCustomers = {};
      state.summaryAccounts = [];
    },

    updateCustomerDetails: (state, action) => {
      state.summaryCustomers = {
        ...state.summaryCustomers,
        [action.payload.key]: action.payload.value,
      };
    },
    removeFileByGuid: (state, action) => {
      let validations = { ...state.validateFiles };
      delete validations[action.payload];
      state.validateFiles = { ...validations };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFilesValidateUploadProspectSet.pending, (state, action) => {
        state.validateFiles[action?.meta?.arg?.fileId] = { loader: true };
      })
      .addCase(getFilesValidateUploadProspectSet.fulfilled, (state, action) => {
        if (action?.payload?.data?.status_code == 200) {
          let updatedData = {
            ...action?.payload?.data?.data,
            accounts: action?.payload?.data?.data?.accounts.map((i) => {
              return {
                ...i,
                file_type: action?.payload?.data?.data?.file_type,
              };
            }),
          };
          state.validateFiles[action?.meta?.arg?.fileId] = {
            ...state.validateFiles[action?.meta?.arg?.fileId],
            loader: false,
            data: updatedData,
          };
          console.log("************mnnnmmm", updatedData);
          state.summaryAccounts = Object.keys(state.validateFiles).reduce(
            (tot, cur) => {
              (state.validateFiles[cur]?.data?.accounts || []).forEach(
                (accnt, i) => {
                  if (accnt?.utility_account_number) {
                    tot[accnt?.utility_account_number] = [
                      ...Object.keys(accnt),
                      ...Object.keys({
                        ...(state.summaryAccounts[
                          accnt?.utility_account_number
                        ] || {}),
                      }),
                    ].reduce((tot, cur) => {
                      tot[`${cur}`] =
                        accnt[`${cur}`] && accnt[`${cur}`] !== null
                          ? accnt[`${cur}`]
                          : {
                              ...(state.summaryAccounts[
                                accnt?.utility_account_number
                              ] || {}),
                            }[`${cur}`];
                      return tot;
                    }, {});
                    //  {
                    //   ...accnt,
                    //   ...(state.summaryAccounts[
                    //     accnt?.utility_account_number
                    //   ] || {}),
                    // };
                  }
                }
              );

              return tot;
            },
            {}
          );

          state.summaryCustomers = [
            "client_code",
            "client_customer_number",
            "company_name",
            "customer_type_code",
            "first_name",
            "last_name",
          ].reduce(
            (tot, cur) => {
              if (
                (!tot[cur] || tot[cur] === null || tot[cur] === "") &&
                action?.payload?.data?.data?.customer &&
                action?.payload?.data?.data?.customer[cur]
              ) {
                tot[cur] = action?.payload?.data?.data?.customer[cur];
              }
              return tot;
            },
            { ...state.summaryCustomers }
          );
        } else {
          state.validateFiles[action?.meta?.arg?.fileId] = {
            ...state.validateFiles[action?.meta?.arg?.fileId],
            loader: false,
            error: action?.payload?.data?.message || "Something went wrong",
          };
        }
        // Object.keys(state.validateFiles).reduce((tot, cur, i) => {
        //   if (
        //     state.validateFiles[cur]?.data?.customer?.client_customer_number
        //   ) {
        //     tot[
        //       state.validateFiles[cur]?.data?.customer?.client_customer_number
        //     ] = state.validateFiles[cur]?.data?.customer;
        //   }
        //   return tot;
        // }, {});
      })
      .addCase(getFilesValidateUploadProspectSet.rejected, (state, action) => {
        state.validateFiles[action?.meta?.arg?.fileId] = {
          ...state.validateFiles[action?.meta?.arg?.fileId],
          loader: false,
          error: action?.error?.message,
        };
      })
      .addCase(
        getFeildParamsPricingMultyUploadAsync.fulfilled,
        (state, action) => {
          state.feildParams.params = {
            ...state.feildParams.params,
            [action?.payload?.param_name]:
              action?.payload?.response?.data?.data,
          };
        }
      )
      .addCase(getFeildParamsPricingMultyUploadAsync.rejected, (state) => {
        state.feildParams.params = {};
      })
      .addCase(processAllProspectSetData.pending, (state, action) => {
        let fileDetails = { ...state.validateFiles };
        const { filesNames } = action?.meta?.arg;

        filesNames.forEach((fileId) => {
          fileDetails[fileId].uploading = true;
        });

        state.validateFiles = { ...fileDetails };
      })
      .addCase(processAllProspectSetData.fulfilled, (state, action) => {
        if (action?.payload?.data?.status_code == 200) {
          let fileDetails = { ...state.validateFiles };
          const { filesNames } = action?.meta?.arg;
          filesNames.forEach((fileId) => {
            fileDetails[fileId].uploading = false;
            fileDetails[fileId].uploaded = true;
          });
          state.validateFiles = { ...fileDetails };
        } else {
          let fileDetails = { ...state.validateFiles };
          const { filesNames } = action?.meta?.arg;
          filesNames.forEach((fileId) => {
            fileDetails[fileId].uploading = false;
          });
          state.validateFiles = { ...fileDetails };
        }
      })
      .addCase(processAllProspectSetData.rejected, (state, action) => {
        let fileDetails = { ...state.validateFiles };
        const { filesNames } = action?.meta?.arg;
        filesNames.forEach((fileId) => {
          fileDetails[fileId].uploading = false;
        });
        state.validateFiles = { ...fileDetails };
      });
  },
});
export const {
  removeFileByGuid,
  updateCustomerDetails,
  resetPricingMultiFileUplaodState,
  updateProspectSetAccountDetails,
  updateProspectSetAccountWholeData,
  addNewPendingValidateFile,
} = prospectSetUploadSlice.actions;
// Action creators are generated for each case reducer function
export default prospectSetUploadSlice.reducer;
