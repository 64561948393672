import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios.js";

const initialState = {
  components: { list: [], loader: false },
  pricing_model_id: 0,
  new_model_code: "",
  updatedGroupFormulas: {}
};

export const getComponentListAsync = createAsyncThunk(
  "pricing/config/getComponentList",
  ({ client_code, product_code, iso_code, state_code, model_code, model_name, pricing_model_id, new_model_code }) => {
    const response = axios
      .post(
        `/api/v1/pricingmodel/get/components`,
        {
          client_code: client_code,
          product_code: product_code,
          model_code: model_code,
          iso_code: iso_code,
          state_code: state_code,
          model_name: model_name,
          pricing_model_id: pricing_model_id,
          new_model_code: new_model_code
        }
      )
      .then((res) => {
        return res;
      });
    return response;
  }
);

// export const saveModelAsync = createAsyncThunk(
//   "pricing/config/save/component",
//   ({ model_code, client_code, product_code, iso_code, state_code, pricing_model_id, new_model_code, list }) => {
//     const response = axios
//       .post(
//         `/api/v1/pricingmodel/save/${model_code}/${client_code}/${product_code}/${iso_code}/${state_code}/${pricing_model_id}/${new_model_code}`,
//         list
//       )
//       .then((res) => {
//         return res;
//       });
//     return response;
//   }
// );

export const saveModelAsync = createAsyncThunk(
  "pricing/config/save/component",
  ({ model_code, client_code, product_code, iso_code, state_code, pricing_model_id, new_model_code, list, model_new_name }) => {
    const response = axios
      .post(
        `/api/v1/pricingmodel/savemodel`,
        {
          items: list,
          model_code: model_code,
          client_code: client_code,
          product_code: product_code,
          iso_code: iso_code,
          state_code: state_code,
          pricing_model_id: pricing_model_id,
          model_new_code: new_model_code,
          model_new_name: model_new_name
        }
      )
      .then((res) => {
        return res;
      });
    return response;
  }
);

export const pricingConfigComponentSlice = createSlice({
  name: "pricing/config/component",
  initialState,
  reducers: {
    updateList: (state, action) => {
      state.components.list = action.payload;
    },
    updatedGroupFormula: (state, action) => {
      state.updatedGroupFormulas[action.payload.pricing_component_calc_group_id] = action.payload.formula;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getComponentListAsync.pending, (state) => {
        state.components.loader = true;
        state.components.list = [];
        state.pricing_model_id = 0;
        state.new_model_code = "";
      })
      .addCase(getComponentListAsync.fulfilled, (state, action) => {
        state.components.loader = false;
        let responseData = action?.payload?.data?.data?.price_items || [];
        for (const group_id in state.updatedGroupFormulas) {
          for (let price_item of responseData) {
            for (let group of price_item.component_groups) {
              if (group.pricing_component_calc_group_id.toString() === group_id) {
                group.component_aggregation_calc = state.updatedGroupFormulas[group_id];
              }
            }
          }
        }
        state.components.list = responseData;
        state.pricing_model_id = action?.payload?.data?.data?.pricing_model_id || 0;
        state.new_model_code = action?.payload?.data?.data?.new_model_code || "";
      })
      .addCase(getComponentListAsync.rejected, (state, action) => {
        state.components.loader = false;
        state.components.list = [];
        state.pricing_model_id = 0;
        state.new_model_code = "";
      });
  },
});

export const { updateList, updatedGroupFormula } = pricingConfigComponentSlice.actions;

// Action creators are generated for each case reducer function
export default pricingConfigComponentSlice.reducer;
