import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
    market: { selected: [], list: [] }
};
export const varSlice = createSlice({
    name: "customer/add",
    initialState,
    reducers: {
        loadMarket: (state, action) => {
            state.market.list = action.payload;
            state.market.selected = [state.market.list[0]];
        },
        selectMarket: (state, action) => {
            state.market.selected = action.payload;
        },
    },
    extraReducers: (builder) => {

    },
});
export const { selectMarket, loadMarket } = varSlice.actions;
export default varSlice.reducer;
