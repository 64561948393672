import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axiosForProduct, { axiosConfigManagement } from "axios.js";

const initialState = {
  serviceProfiles: {
    serviceProfileData: { list: [], total: 0 },
    loader: false,
  },
  serviceProfileParams: { params: {} },
  addNewServiceProfile: { loader: false },
  autoRunParams: { loader: false, list: [] },
  product: { list: [], loader: false },
};

export const getProductListForConfigDomainAsync = createAsyncThunk(
  "admin/serviceProfile/getProductListAsync",
  async (client_code) => {
    const response = axiosForProduct
      .get(`/api/v1/product/get/all?client_code=${client_code}`)
      .then((res) => {
        return res;
      });
    return response;
  }
);

export const getServiceProfileAsync = createAsyncThunk(
  "admin/configDomain/getServiceProfileList",
  async (data) => {
    const { page, pagesize } = data;
    const response = await axiosConfigManagement.get(
      `/api/v1/configuration/service-profiles/get?page=${page}&page_size=${pagesize}&model_info=true`
    );
    return response;
  }
);

export const postAddServiceProfileData = createAsyncThunk(
  "admin/serviceProfile/add",
  async (body) => {
    const response = await axiosConfigManagement.post(
      `/api/v1/configuration/service-profiles/add`,
      body?.payload
    );
    return response;
  }
);

export const postAddServiceProfileModalMaping = createAsyncThunk(
  "admin/serviceProfileModalMapping/add",
  async (body) => {
    const response = await axiosConfigManagement.post(
      `/api/v1/configuration/service-profiles/model-mapping?service_profile_code=${body?.sp_id}`,
      body?.data
    );
    return response;
  }
);
export const getServivceProfileParamsAsync = createAsyncThunk(
  "admin/serviceProfile/serviceProfileParam",
  async (data) => {
    const { param_name } = data;
    const response = await axiosConfigManagement.get(
      `/api/v1/configuration/${param_name}/get`
    );
    return { response, param_name };
  }
);
export const getServivceProfileAutoRunParamsAsync = createAsyncThunk(
  "admin/serviceProfile/serviceProfileAutoRunParam",
  async () => {
    const response = await axiosConfigManagement.get(
      `/api/v1/configuration/service-profiles/matrix-model`
    );
    return response;
  }
);
export const deleteServiceProfileAsync = createAsyncThunk(
  "admin/delete/serviceProfile",
  async ({ service_profile_id }) => {
    const response = await axiosConfigManagement.delete(
      `/api/v1/configuration/service-profiles/delete?sp_id=${service_profile_id}`
    );
    return response;
  }
);
export const postEditServiceProfileData = createAsyncThunk(
  "admin/serviceProfile/edit",
  async (body) => {
    const response = await axiosConfigManagement.put(
      `/api/v1/configuration/service-profiles/update`,
      body?.payload
    );
    return response;
  }
);
export const serviceProfileSlice = createSlice({
  name: "admin/serviceProfile",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getServiceProfileAsync.pending, (state) => {
        state.serviceProfiles.loader = true;
      })
      .addCase(getServiceProfileAsync.fulfilled, (state, action) => {
        state.serviceProfiles.loader = false;
        state.serviceProfiles.serviceProfileData =
          action?.payload?.data?.data || [];
      })
      .addCase(getServiceProfileAsync.rejected, (state) => {
        state.serviceProfiles.loader = false;
        state.serviceProfiles.serviceProfileData = [];
      })
      .addCase(getServivceProfileAutoRunParamsAsync.pending, (state) => {
        state.autoRunParams.loader = true;
      })
      .addCase(
        getServivceProfileAutoRunParamsAsync.fulfilled,
        (state, action) => {
          state.autoRunParams.loader = false;
          state.autoRunParams.list = action?.payload?.data?.data;
        }
      )
      .addCase(getServivceProfileAutoRunParamsAsync.rejected, (state) => {
        state.autoRunParams.loader = false;
        state.autoRunParams.list = [];
      })
      .addCase(postEditServiceProfileData.pending, (state) => {
        state.addNewServiceProfile.loader = true;
      })
      .addCase(postEditServiceProfileData.fulfilled, (state, action) => {
        state.addNewServiceProfile.loader = false;
        //state.tardeCaptureParams.list = action?.payload?.data?.data;
      })
      .addCase(postEditServiceProfileData.rejected, (state) => {
        state.addNewServiceProfile.loader = false;
      })
      .addCase(getProductListForConfigDomainAsync.pending, (state) => {
        state.product.loader = true;
      })
      .addCase(
        getProductListForConfigDomainAsync.fulfilled,
        (state, action) => {
          state.product.loader = false;
          state.product.list = action?.payload?.data?.data || [];
        }
      )
      .addCase(getProductListForConfigDomainAsync.rejected, (state, action) => {
        state.product.loader = false;
        state.product.list = [];
      })
      .addCase(getServivceProfileParamsAsync.fulfilled, (state, action) => {
        state.serviceProfileParams.params = {
          ...state.serviceProfileParams.params,
          [action?.payload?.param_name]: action?.payload?.response?.data?.data,
        };
      })
      .addCase(getServivceProfileParamsAsync.rejected, (state) => {
        state.serviceProfileParams.params = {};
      });
  },
});

export default serviceProfileSlice.reducer;
