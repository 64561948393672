import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosForecastInstance as axios } from "axios.js";

const INITIAL_STATE = {
  dashBoardShortTermForecastingChartData: {
    loader: false,
    dataToPlot: [],
    shortTermBatchDates: {},
  },
  dashBoardShortTermForecastingDrillDownChartData: {
    loader: false,
    dataToPlot: [],
    shortTermBatchDates: {},
  },
  dashBoardShortTermMarketLevelChartData: {
    loader: false,
    dataToPlot: [],
    shortTermBatchDates: {},
  },
};

export const getdashboardLongTermForecastInitialDataAsync = createAsyncThunk(
  "forecasting/getDashboardShortTerm",
  async (data) => {
    console.log("**********aaa", data);
    const response = await axios.get(
      `/api/v1/get_short_term_load_difference_by_zone/${data.market}`
    );
    return response;
  }
);
export const getdashboardMarketLevelShortTermForecastInitialDataAsync =
  createAsyncThunk("forecasting/getDashboardMarketLevelShortTerm", async () => {
    const response = await axios.get("/api/v1/get_short_term_load_by_market");
    return response;
  });
export const getdashboardShortTermForecastDrillDownInitialDataAsync =
  createAsyncThunk(
    "forecasting/getDashboardShortTermDrillDown",
    async (data) => {
      const response = await axios.get(
        `/api/v1/get_short_term_hourly_load_difference_by_zone?flow_date=${data.date}&market_cd=${data.market}&zone_cd=${data.zone}`
      );
      return response;
    }
  );

export const dashboardShortTermSlice = createSlice({
  name: "forecasting/dashboard/short_term",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getdashboardLongTermForecastInitialDataAsync.pending,
        (state) => {
          state.dashBoardShortTermForecastingChartData.loader = true;
        }
      )
      .addCase(
        getdashboardLongTermForecastInitialDataAsync.fulfilled,
        (state, action) => {
          state.dashBoardShortTermForecastingChartData.loader = false;

          state.dashBoardShortTermForecastingChartData.dataToPlot =
            action?.payload?.data?.load_difference_by_zone || [];
          state.dashBoardShortTermForecastingChartData.shortTermBatchDates =
            action?.payload?.data?.batch_dates || [];
        }
      )
      .addCase(
        getdashboardLongTermForecastInitialDataAsync.rejected,
        (state) => {
          state.dashBoardShortTermForecastingChartData.loader = false;
        }
      )
      .addCase(
        getdashboardShortTermForecastDrillDownInitialDataAsync.pending,
        (state) => {
          state.dashBoardShortTermForecastingDrillDownChartData.loader = true;
        }
      )
      .addCase(
        getdashboardShortTermForecastDrillDownInitialDataAsync.fulfilled,
        (state, action) => {
          state.dashBoardShortTermForecastingDrillDownChartData.loader = false;

          state.dashBoardShortTermForecastingDrillDownChartData.dataToPlot =
            action?.payload?.data?.hourly_load_difference_by_zone || [];
          state.dashBoardShortTermForecastingDrillDownChartData.shortTermBatchDates =
            action?.payload?.data?.batch_dates || [];
        }
      )
      .addCase(
        getdashboardShortTermForecastDrillDownInitialDataAsync.rejected,
        (state) => {
          state.dashBoardShortTermForecastingDrillDownChartData.loader = false;
        }
      )
      .addCase(
        getdashboardMarketLevelShortTermForecastInitialDataAsync.pending,
        (state) => {
          state.dashBoardShortTermMarketLevelChartData.loader = true;
        }
      )
      .addCase(
        getdashboardMarketLevelShortTermForecastInitialDataAsync.fulfilled,
        (state, action) => {
          state.dashBoardShortTermMarketLevelChartData.loader = false;

          state.dashBoardShortTermMarketLevelChartData.dataToPlot =
            action?.payload?.data?.load_difference_by_market || [];
          state.dashBoardShortTermMarketLevelChartData.shortTermBatchDates =
            action?.payload?.data?.batch_dates || [];
        }
      )
      .addCase(
        getdashboardMarketLevelShortTermForecastInitialDataAsync.rejected,
        (state) => {
          state.dashBoardShortTermMarketLevelChartData.loader = false;
        }
      );
  },
});

export default dashboardShortTermSlice.reducer;
