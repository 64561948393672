import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosSettlementInstance as axios } from "axios.js";

const INITIAL_STATE = {
  detailsDataLoder: false,
  detailsDataList: {},

  financialDataListLoader: false,
  financialDataList: [],
  financialsCategoryList: [],
  financialsCategoryListLoader: false,
};

export const getSettlementSummaryDataAsync = createAsyncThunk(
  "settlement/getSettlementSummaryData",
  async (data) => {
    const response = await axios.get(`/api/v1/settlements/financials/summary`);
    return response;
  }
);
export const getSettlementSummaryDataWithFilterAsync = createAsyncThunk(
  "settlement/getSettlementSummaryDataWithFilter",
  async (data) => {
    const response = await axios.post(
      `/api/v1/settlements/financials/summary-filter`,
      data
    );
    return response;
  }
);
export const getSettlementDetailsListDataAsync = createAsyncThunk(
  "settlement/getSettlementDetailsListData",
  async () => {
    const response = await axios.get(`/api/v1/settlements/financials/details`);
    return response;
  }
);
export const getSettlementDetailsListDataWithFilterAsync = createAsyncThunk(
  "settlement/getSettlementDetailsListDataWithFilter",
  async (data) => {
    const response = await axios.post(
      `/api/v1/settlements/financials/details-filter`,
      data
    );
    return response;
  }
);
export const getSettlementDetailsCstegoryListDataAsync = createAsyncThunk(
  "settlement/getSettlementDetailsCstegoryListData",
  async () => {
    const response = await axios.get(
      `/api/v1/settlements/financials/dataset_item`
    );
    return response;
  }
);
export const settlementFinancialsSlice = createSlice({
  name: "settlement/financials",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSettlementSummaryDataAsync.pending, (state) => {
        state.financialDataListLoader = true;
      })
      .addCase(getSettlementSummaryDataAsync.fulfilled, (state, action) => {
        state.financialDataListLoader = false;

        state.financialDataList = action?.payload?.data?.data;
      })
      .addCase(getSettlementSummaryDataAsync.rejected, (state) => {
        state.financialDataListLoader = false;
        state.financialDataList = [];
      })
      .addCase(getSettlementSummaryDataWithFilterAsync.pending, (state) => {
        state.financialDataListLoader = true;
      })
      .addCase(
        getSettlementSummaryDataWithFilterAsync.fulfilled,
        (state, action) => {
          state.financialDataListLoader = false;
          state.financialDataList = action?.payload?.data?.data;
        }
      )
      .addCase(getSettlementSummaryDataWithFilterAsync.rejected, (state) => {
        state.financialDataListLoader = false;
        state.financialDataList = [];
      })
      .addCase(getSettlementDetailsListDataAsync.pending, (state) => {
        state.detailsDataLoder = true;
      })
      .addCase(getSettlementDetailsListDataAsync.fulfilled, (state, action) => {
        state.detailsDataLoder = false;
        state.detailsDataList = action?.payload?.data?.data;
      })
      .addCase(getSettlementDetailsListDataAsync.rejected, (state) => {
        state.detailsDataLoder = false;
        state.detailsDataList = {};
      })
      .addCase(getSettlementDetailsListDataWithFilterAsync.pending, (state) => {
        state.detailsDataLoder = true;
      })
      .addCase(
        getSettlementDetailsListDataWithFilterAsync.fulfilled,
        (state, action) => {
          state.detailsDataLoder = false;

          state.detailsDataList =
            action?.payload?.data?.data?.category_details &&
            action?.payload?.data?.data?.category_details[0]
              .bill_determinant_name === null
              ? {
                  category_details: [],
                  max_date: state.detailsDataList.max_date,
                  min_date: state.detailsDataList.min_date,
                }
              : action?.payload?.data?.data;
        }
      )
      .addCase(
        getSettlementDetailsListDataWithFilterAsync.rejected,
        (state) => {
          state.detailsDataLoder = false;
          state.detailsDataList = {};
        }
      )
      .addCase(getSettlementDetailsCstegoryListDataAsync.pending, (state) => {
        state.financialsCategoryListLoader = true;
      })
      .addCase(
        getSettlementDetailsCstegoryListDataAsync.fulfilled,
        (state, action) => {
          state.financialsCategoryListLoader = false;

          state.financialsCategoryList = action?.payload?.data?.data;
        }
      )
      .addCase(getSettlementDetailsCstegoryListDataAsync.rejected, (state) => {
        state.financialsCategoryListLoader = false;
      });
  },
});

export const {} = settlementFinancialsSlice.actions;

export default settlementFinancialsSlice.reducer;
