import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosForecastInstance as axios } from "axios.js";
import { dummyUsageData } from "./dummyAssetUsageData";

const initialState = {
    usageData: {
        dropDownListItems: {
            "markets": ["ERCOT"],
            "zones": ["HOUSTON", "NORTH", "SOUTH", "WEST"],
            "books": ["Residential Fixed", "Residential Variable", "Commercial Fixed", "Commercial Variable"],
        }, loader: false
    },
    usageChartData: { block_types: [], list: []/*dummyUsageData.data*/, loader: false },
    revenueData: {
        dropDownListItems: {
            "markets": ["ERCOT"],
            "zones": ["HOUSTON", "NORTH", "SOUTH", "WEST"],
            "books": ["Residential Fixed", "Residential Variable", "Commercial Fixed", "Commercial Variable"],
        }, loader: false
    },
    revenueChartData: { block_types: [], list: []/*dummyUsageData.data*/, loader: false }
}

export const getAssetUsageRevenueAsync = createAsyncThunk(
    "asset/get/usagerevenue",
    async (body) => {
        const response = await axios.get("/api/v1/get_usage_and_revenue_data", body);
        return response;
    }
);

export const getAssetUsageAsync = createAsyncThunk(
    "asset/get/usage",
    async (body) => {
        const response = await axios.get("/api/v1/get_usage_map", body);
        return response;
    }
);

export const getAssetRevenueAsync = createAsyncThunk(
    "asset/get/revenue",
    async (body) => {
        const response = await axios.get("/api/v1/get_revenue_map", body);
        return response;
    }
);

export const assetUsageSlice = createSlice({
    name: "asset/usage/slice",
    initialState,
    reducers: {
        zoneOnChange: (state, action) => {

        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAssetUsageAsync.pending, (state) => {
                //state.usageData.loader = true;
                state.usageChartData.loader = true;
            })
            .addCase(getAssetUsageAsync.fulfilled, (state, action) => {
                //state.usageData.loader = false;
                //state.usageData.dropdownList = action?.payload?.data || [];

                state.usageChartData.loader = false;
                state.usageChartData.list = action?.payload?.data.data || [];
                state.usageChartData.block_types = action?.payload?.data.block_types || [];
            })
            .addCase(getAssetUsageAsync.rejected, (state, action) => {
                //state.usageData.loader = false;
                //state.usageData.dropdownList = [];

                state.usageChartData.loader = false;
                state.usageChartData.list = [];
                state.usageChartData.block_types = [];
            })
            .addCase(getAssetRevenueAsync.pending, (state) => {
                //state.revenueData.loader = true;
                state.revenueChartData.loader = true;
            })
            .addCase(getAssetRevenueAsync.fulfilled, (state, action) => {
                //state.revenueData.loader = false;
                //state.revenueData.dropdownList = action?.payload?.data || [];

                state.revenueChartData.loader = false;
                state.revenueChartData.list = action?.payload?.data.data || [];
                state.revenueChartData.block_types = action?.payload?.data.block_types || [];
            })
            .addCase(getAssetRevenueAsync.rejected, (state, action) => {
                //state.revenueData.loader = false;
                //state.revenueData.dropdownList = [];

                state.revenueChartData.loader = false;
                state.revenueChartData.list = [];
                state.revenueChartData.block_types = [];
            })
            .addCase(getAssetUsageRevenueAsync.pending, (state) => {
                //state.revenueData.loader = true;
                state.revenueChartData.loader = true;
                state.usageChartData.loader = true;
            })
            .addCase(getAssetUsageRevenueAsync.fulfilled, (state, action) => {
                //state.revenueData.loader = false;
                //state.revenueData.dropdownList = action?.payload?.data || [];

                state.usageChartData.loader = false;
                state.usageChartData.list = action?.payload?.data.data || [];
                state.usageChartData.block_types = action?.payload?.data.block_types || [];

                state.revenueChartData.loader = false;
                state.revenueChartData.list = action?.payload?.data.data || [];
                state.revenueChartData.block_types = action?.payload?.data.block_types || [];
            })
            .addCase(getAssetUsageRevenueAsync.rejected, (state, action) => {
                //state.revenueData.loader = false;
                //state.revenueData.dropdownList = [];

                state.usageChartData.loader = false;
                state.usageChartData.list = [];
                state.usageChartData.block_types = [];

                state.revenueChartData.loader = false;
                state.revenueChartData.list = [];
                state.revenueChartData.block_types = [];
            })
    },
});
export const {
    zoneOnChange
} = assetUsageSlice.actions;
// Action creators are generated for each case reducer function
export default assetUsageSlice.reducer;
