import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios.js";

const initialState = {
  model: { list: [], loader: false, selected: "" },
  product: { list: [], loader: false, selected: {} },
  state: { list: [], loader: false, selected: "" },
  market: { list: [], loader: false, selected: "" },
  name: "",
};

export const getModelListAsync = createAsyncThunk(
  "pricing/config/getModelListAsync",
  (client_code) => {
    const response = axios
      .get(`/api/v1/pricingmodel/get/all?client_code=${client_code}`)
      .then((res) => {
        return res;
      });
    return response;
  }
);

export const getProductListAsync = createAsyncThunk(
  "pricing/config/getProductListAsync",
  async (client_code) => {
    const response = axios
      .get(`/api/v1/product/get/all?client_code=${client_code}`)
      .then((res) => {
        return res;
      });
    return response;
  }
);

export const getStateListAsync = createAsyncThunk(
  "pricing/config/getStateListAsync",
  async ({ client_code, iso_code }) => {
    const response = axios
      .get(
        `/api/v1/market/state/get/all?client_code=${client_code}&iso_code=${iso_code}`
      )
      .then((res) => {
        return res;
      });
    return response;
  }
);

export const getMarketListAsync = createAsyncThunk(
  "pricing/config/getMarketListAsync",
  async (client_code) => {
    const response = axios
      .get(`/api/v1/market/get/all?client_code=${client_code}`)
      .then((res) => {
        return res;
      });
    return response;
  }
);

export const pricingConfigProductSlice = createSlice({
  name: "pricing/config",
  initialState,
  reducers: {
    selectModel: (state, action) => {
      state.model.selected = action.payload;
    },
    selectProduct: (state, action) => {
      state.product.selected = action.payload;
    },
    selectState: (state, action) => {
      state.state.selected = action.payload;
    },
    selectMarket: (state, action) => {
      state.market.selected = action.payload;
    },
    selectName: (state, action) => {
      state.name = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getModelListAsync.pending, (state) => {
        state.model.loader = true;
      })
      .addCase(getModelListAsync.fulfilled, (state, action) => {
        state.model.loader = false;
        state.model.list = action?.payload?.data?.data || [];
      })
      .addCase(getModelListAsync.rejected, (state, action) => {
        state.model.loader = false;
        state.model.list = [];
      })
      .addCase(getProductListAsync.pending, (state) => {
        state.product.loader = true;
      })
      .addCase(getProductListAsync.fulfilled, (state, action) => {
        state.product.loader = false;
        state.product.list = action?.payload?.data?.data || [];
      })
      .addCase(getProductListAsync.rejected, (state, action) => {
        state.product.loader = false;
        state.product.list = [];
      })
      .addCase(getStateListAsync.pending, (state) => {
        state.state.loader = true;
        state.state.selected = "";
      })
      .addCase(getStateListAsync.fulfilled, (state, action) => {
        state.state.loader = false;
        state.state.list = action?.payload?.data?.data || [];
      })
      .addCase(getStateListAsync.rejected, (state, action) => {
        state.state.loader = false;
        state.state.list = [];
      })
      .addCase(getMarketListAsync.pending, (state) => {
        state.market.loader = true;
        state.market.selected = "";
        state.state.selected = "";
      })
      .addCase(getMarketListAsync.fulfilled, (state, action) => {
        state.market.loader = false;
        state.market.list = action?.payload?.data?.data || [];
      })
      .addCase(getMarketListAsync.rejected, (state, action) => {
        state.market.loader = false;
        state.market.list = [];
      });
  },
});

export const {
  selectMarket,
  selectModel,
  selectProduct,
  selectState,
  selectName,
} = pricingConfigProductSlice.actions;

// Action creators are generated for each case reducer function
export default pricingConfigProductSlice.reducer;
