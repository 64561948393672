import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios.js";

const initialState = {
  contacts: { list: [], loader: false },
};

export const getCustomerContactListAsync = createAsyncThunk(
  "costomer/getCustomerContactList",
  async (data) => {
    const { client_code, customerId } = data;
    const response = await axios.get(
      `/api/v1/customer/contacts?customerId=${customerId}&clientCode=${client_code}`,
      data
    );
    return response;
  }
);

export const postAddOrEditContactCustomerAsync = createAsyncThunk(
  "customer/add/contact",
  async (body) => {
    if (body?.dialogType === "EditContact") {
      const response = await axios.put(
        `/api/v1/customer/contacts?customerId=${body?.customerId}&clientCode=${body?.clientCode}`,
        body?.payload
      );
      return response;
    } else {
      const response = await axios.post(
        `/api/v1/customer/contacts?customerId=${body?.customerId}&clientCode=${body?.clientCode}`,
        body?.payload
      );
      return response;
    }
  }
);

export const deleteContactCustomerAsync = createAsyncThunk(
  "customer/delete/contact",
  async (body) => {
    const response = await axios.delete(
      `/api/v1/customer/contacts?customer_contact_id=${body?.customer_contact_id}`
    );
    return response;
  }
);

export const customerContactSlice = createSlice({
  name: "customer/contacts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerContactListAsync.pending, (state) => {
        state.contacts.loader = true;
      })
      .addCase(getCustomerContactListAsync.fulfilled, (state, action) => {
        state.contacts.loader = false;
        state.contacts.list = action?.payload?.data?.data;
      })
      .addCase(getCustomerContactListAsync.rejected, (state) => {
        state.contacts.loader = false;
        state.contacts.list = [];
      })
      .addCase(postAddOrEditContactCustomerAsync.pending, (state) => {
        state.contacts.loader = true;
      })
      .addCase(postAddOrEditContactCustomerAsync.fulfilled, (state, action) => {
        state.contacts.loader = false;
      })
      .addCase(postAddOrEditContactCustomerAsync.rejected, (state) => {
        state.contacts.loader = false;
      })
      .addCase(deleteContactCustomerAsync.pending, (state) => {})
      .addCase(deleteContactCustomerAsync.fulfilled, (state, action) => {})
      .addCase(deleteContactCustomerAsync.rejected, (state) => {});
  },
});

export default customerContactSlice.reducer;
