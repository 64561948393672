import React, { createContext, useEffect, useReducer } from "react";
import jwtDecode from "jwt-decode";
import axios from "axios.js";
import {
  axiosForecastInstance,
  axiosRiskInstance,
  axiosForecastRiskInstance,
  axiosConfigManagement,
  axiosPricingProspectDataSet,
  axiosSettlementInstance,
  axiosSettlementsForcastDemoInstance,
} from "axios.js";
import MatxLoading from "../../features/components/customComponents/MatxLoading";
import { useDispatch } from "react-redux";

const initialState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
};

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decodedToken = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  return decodedToken.exp > currentTime;
};

const setSession = (accessToken, previleges, featureWiseToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("previleges", JSON.stringify(previleges));
    localStorage.setItem("featureWiseToken", JSON.stringify(featureWiseToken));
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    axiosForecastInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    axiosRiskInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    axiosForecastRiskInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    axiosSettlementInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    axiosConfigManagement.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    axiosPricingProspectDataSet.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    axiosSettlementsForcastDemoInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    if (featureWiseToken && featureWiseToken["Forecasting"]) {
      axiosForecastInstance.defaults.headers.common.Authorization = `Bearer ${featureWiseToken["Forecasting"]}`;
    }
    if (featureWiseToken && featureWiseToken["Risk"]) {
      axiosRiskInstance.defaults.headers.common.Authorization = `Bearer ${featureWiseToken["Risk"]}`;
      axiosForecastRiskInstance.defaults.headers.common.Authorization = `Bearer ${featureWiseToken["Risk"]}`;
    }
    if (featureWiseToken && featureWiseToken["Asset"]) {
      // NO CLIENT
    }
    if (featureWiseToken && featureWiseToken["Reports"]) {
      // NO CLIENT
    }
    if (featureWiseToken && featureWiseToken["Settlement"]) {
      axiosSettlementInstance.defaults.headers.common.Authorization = `Bearer ${featureWiseToken["Settlement"]}`;
      axiosSettlementsForcastDemoInstance.defaults.headers.common.Authorization = `Bearer ${featureWiseToken["Settlement"]}`;
    }
  } else {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("previleges");
    localStorage.removeItem("featureWiseToken");
    delete axios.defaults.headers.common.Authorization;
    delete axiosForecastInstance.defaults.headers.common.Authorization;
    delete axiosRiskInstance.defaults.headers.common.Authorization;
    delete axiosForecastRiskInstance.defaults.headers.common.Authorization;
    delete axiosConfigManagement.defaults.headers.common.Authorization;
    delete axiosPricingProspectDataSet.defaults.headers.common.Authorization;
    delete axiosSettlementInstance.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INIT": {
      const { isAuthenticated, user, previleges } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
        previleges,
      };
    }
    case "LOGIN": {
      const { user, previleges } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
        previleges,
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        previleges: null,
      };
    }
    case "REGISTER": {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialState,
  method: "JWT",
  login: () => Promise.resolve(),
  logout: () => {},
  register: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  const reduxDispatch = useDispatch();
  const [state, dispatch] = useReducer(reducer, initialState);

  const login = async (email, password) => {
    const response = await axios.post("/api/v1/account/get-token", {
      userName: email,
      password: password,
    });
    const { token: accessToken, tokens: featureWiseToken } =
      response?.data?.data;
    let previleges = response?.data?.data?.features;

    setSession(accessToken, previleges, featureWiseToken);

    const decodedToken = jwtDecode(accessToken);
    const user = decodedToken;
    dispatch({
      type: "LOGIN",
      payload: {
        user,
        previleges,
      },
    });
  };

  const register = async (email, username, password) => {
    const response = await axios.post("/api/auth/register", {
      email,
      username,
      password,
    });

    const { accessToken, user } = response.data.data;

    setSession(accessToken);

    dispatch({
      type: "REGISTER",
      payload: {
        user,
      },
    });
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: "LOGOUT" });
    reduxDispatch({ type: "USER_LOGOUT" });
  };

  useEffect(() => {
    (async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");
        const previleges = JSON.parse(
          window.localStorage.getItem("previleges")
        );
        const featureWiseToken = JSON.parse(
          window.localStorage.getItem("featureWiseToken")
        );

        if (accessToken && isValidToken(accessToken)) {
          //const response = await axios.get('/api/auth/profile')
          //const { user } = response.data
          setSession(accessToken, previleges, featureWiseToken);

          const decodedToken = jwtDecode(accessToken);
          const user = decodedToken;
          dispatch({
            type: "INIT",
            payload: {
              isAuthenticated: true,
              user,
              previleges,
            },
          });
        } else {
          dispatch({
            type: "INIT",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INIT",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    })();
  }, []);

  if (!state.isInitialised) {
    return <MatxLoading />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "JWT",
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
