import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosForecastInstance as axios } from "axios.js";

const INITIAL_STATE = {
  dashBoardMapeForecastingChartData: {
    loader: false,
    dataToPlot: [],
  },
  dashBoardMapeForecastingDilldownChartData: {
    loader: false,
    dataToPlot: [],
  },
  dashBoardMapeForecastingDilldownChartByZoneData: {
    loader: false,
    dataToPlot: [],
  },
};

export const getdashboardMapeForecastDataAsync = createAsyncThunk(
  "forecasting/getDashboardMape",
  async (data) => {
    const response = await axios.get(`/api/v1/get_average_mape_for_lse`);
    return response;
  }
);
export const getdashboardMapeDrilldownDataAsync = createAsyncThunk(
  "forecasting/getDashboardMape/drilldown/by/hour",
  async (data) => {
    const response = await axios.get(
      `api/v1/get_forecast_performance_by_hour/${data.market_cd}/${data.lse_code}/${data.zone_cd}`
    );
    return response;
  }
);
export const getdashboardMapeByZoneDrilldownDataAsync = createAsyncThunk(
  "forecasting/getDashboardMape/drilldown/by/zone",
  async (data) => {
    const response = await axios.get(
      `/api/v1/get_forecast_performance_by_zone/${data.market_cd}/${data.lse_code}`
    );

    return response;
  }
);

export const dashboardMapeSlice = createSlice({
  name: "forecasting/dashboard/mape",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getdashboardMapeForecastDataAsync.pending, (state) => {
        state.dashBoardMapeForecastingChartData.loader = true;
      })
      .addCase(getdashboardMapeForecastDataAsync.fulfilled, (state, action) => {
        state.dashBoardMapeForecastingChartData.loader = false;

        state.dashBoardMapeForecastingChartData.dataToPlot =
          action?.payload?.data?.avg_mape_by_lse || [];
      })
      .addCase(getdashboardMapeForecastDataAsync.rejected, (state) => {
        state.dashBoardMapeForecastingChartData.loader = false;
      })
      .addCase(getdashboardMapeDrilldownDataAsync.pending, (state) => {
        state.dashBoardMapeForecastingDilldownChartData.loader = true;
      })
      .addCase(
        getdashboardMapeDrilldownDataAsync.fulfilled,
        (state, action) => {
          state.dashBoardMapeForecastingDilldownChartData.loader = false;
          state.dashBoardMapeForecastingDilldownChartData.dataToPlot =
            action?.payload?.data?.avg_mape_by_zone || [];
        }
      )
      .addCase(getdashboardMapeDrilldownDataAsync.rejected, (state) => {
        state.dashBoardMapeForecastingDilldownChartData.loader = false;
      })

      .addCase(getdashboardMapeByZoneDrilldownDataAsync.pending, (state) => {
        state.dashBoardMapeForecastingDilldownChartByZoneData.loader = true;
      })
      .addCase(
        getdashboardMapeByZoneDrilldownDataAsync.fulfilled,
        (state, action) => {
          state.dashBoardMapeForecastingDilldownChartByZoneData.loader = false;
          state.dashBoardMapeForecastingDilldownChartByZoneData.dataToPlot =
            action?.payload?.data?.avg_mape_by_zone || [];
        }
      )
      .addCase(getdashboardMapeByZoneDrilldownDataAsync.rejected, (state) => {
        state.dashBoardMapeForecastingDilldownChartByZoneData.loader = false;
      });
  },
});

export default dashboardMapeSlice.reducer;
