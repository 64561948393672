import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosRiskInstance as axios } from "axios.js";

const INITIAL_STATE = {
  status: "",
  loader: false,
};

export const getOrSetRefreshStatus = createAsyncThunk(
  "risk/getOrSetRefreshStatus",
  (data) => {
    const response = axios
      .get(
        `/api/v1/st_position_reporting/calculate_position_reporting/${data.status}`
      )
      .then((res) => {
        return res;
      });
    return response;
  }
);

export const refreshLoadingSlice = createSlice({
  name: "position/refreshLoading",
  initialState: INITIAL_STATE,
  reducers: {
    changeStatusOfRefresh: (state, action) => {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrSetRefreshStatus.pending, (state) => {
        state.loader = true;
      })
      .addCase(getOrSetRefreshStatus.fulfilled, (state, action) => {
        console.log("********************action", action);
        state.loader = false;
        state.status = action?.payload?.data?.data?.last_session_status || "";
      })
      .addCase(getOrSetRefreshStatus.rejected, (state, action) => {
        console.log("********************action error", action);
        state.loader = false;
        state.status = "";
      });
  },
});

export const { changeStatusOfRefresh } = refreshLoadingSlice.actions;
export default refreshLoadingSlice.reducer;
