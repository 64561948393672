import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosForecastRiskInstance as axios } from "axios.js";

const initialState = {
  details: { list: [], loader: false },
};
export const getRiskDetailsData = createAsyncThunk(
  "risk/getRiskDetailsData",
  () => {
    const response = axios
      .get(`/api/v1/get_risk_details_data`)
      .then((res) => {
        return res;
      });
    return response;
  }
);
export const riskDetailSliceEngie = createSlice({
  name: "risk/details_data/slice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRiskDetailsData.pending, (state) => {
        state.details.loader = true;
      })
      .addCase(
        getRiskDetailsData.fulfilled,
        (state, action) => {
          state.details.loader = false;
          state.details.list = action?.payload?.data.risk_details_data || [];
        }
      )
      .addCase(
        getRiskDetailsData.rejected,
        (state, action) => {
          state.details.loader = false;
        }
      );
  },
});
export default riskDetailSliceEngie.reducer;
