import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios.js";
import { axiosPricingProspectDataSet } from "axios.js";

const initialState = {
  customerList: { list: [], loader: false },
  selectedCustomerDetails: null,
};

export const getCustomerListAsync = createAsyncThunk(
  "pricing/getCustomerList",
  async (data) => {
    const response = await axios.post("/api/v1/customer/search", data);
    return response;
  }
);
export const prospectDataCustomerFindAsync = createAsyncThunk(
  "pricing/prospectDataCustomerFind",
  async (data) => {
    const { client_code, FileUploadGuid } = data;
    const response = await axios.get(
      `api/v1/misc/data/customer/postview?uploadIdentifier=${FileUploadGuid}&clientCode=${client_code}`
    );
    return response;
  }
);
export const getCustomerDetailsAsync = createAsyncThunk(
  "customer/find/multi",
  async (data) => {
    const response = await axiosPricingProspectDataSet.get(
      `/api/v1/data-load/client-prospect/data/customer/postview?group_identifier=${data.FileUploadGuid}`
    );
    return response;
  }
);
export const pricingCustomerSlice = createSlice({
  name: "pricing",
  initialState,
  reducers: {
    selectCustomer: (state, action) => {
      state.selectedCustomerDetails = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerListAsync.pending, (state) => {
        state.customerList.loader = true;
      })
      .addCase(getCustomerListAsync.fulfilled, (state, action) => {
        state.customerList.loader = false;
        state.customerList.list = action?.payload?.data?.data || [];
      })
      .addCase(getCustomerListAsync.rejected, (state, action) => {
        state.customerList.loader = false;
        state.customerList.list = [];
      })
      .addCase(prospectDataCustomerFindAsync.pending, (state) => {
        state.customerList.loader = true;
      })
      .addCase(prospectDataCustomerFindAsync.fulfilled, (state, action) => {
        // Should handle the negative scenario also in a later point in case user have multiple customers
        state.customerList.loader = false;
        state.selectedCustomerDetails = action?.payload?.data?.data[0] || [];
      })
      .addCase(prospectDataCustomerFindAsync.rejected, (state, action) => {
        state.customerList.loader = false;
      })
      .addCase(getCustomerDetailsAsync.pending, (state) => {
        state.customerList.loader = false;
      })
      .addCase(getCustomerDetailsAsync.fulfilled, (state, action) => {
        state.customerList.loader = false;
        state.selectedCustomerDetails = action?.payload?.data?.data || [];
      })
      .addCase(getCustomerDetailsAsync.rejected, (state) => {
        state.customerList.loader = false;
      });
  },
});
export const { selectCustomer } = pricingCustomerSlice.actions;
// Action creators are generated for each case reducer function
export default pricingCustomerSlice.reducer;
