import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const initialState = {
  proposedDate: dayjs().date(1),
  market: "",
  contractTerms: [
    { Months: 1, Checked: false },
    { Months: 2, Checked: false },
    { Months: 3, Checked: false },
    { Months: 4, Checked: false },
    { Months: 5, Checked: false },
    { Months: 6, Checked: false },
    { Months: 7, Checked: false },
    { Months: 8, Checked: false },
    { Months: 9, Checked: false },
    { Months: 10, Checked: false },
    { Months: 11, Checked: false },
    { Months: 12, Checked: true },
    { Months: 13, Checked: false },
    { Months: 14, Checked: false },
    { Months: 15, Checked: false },
    { Months: 16, Checked: false },
    { Months: 17, Checked: false },
    { Months: 18, Checked: false },
    { Months: 19, Checked: false },
    { Months: 20, Checked: false },
    { Months: 21, Checked: false },
    { Months: 22, Checked: false },
    { Months: 23, Checked: false },
    { Months: 24, Checked: true },
    { Months: 25, Checked: false },
    { Months: 26, Checked: false },
    { Months: 27, Checked: false },
    { Months: 28, Checked: false },
    { Months: 29, Checked: false },
    { Months: 30, Checked: false },
    { Months: 31, Checked: false },
    { Months: 32, Checked: false },
    { Months: 33, Checked: false },
    { Months: 34, Checked: false },
    { Months: 35, Checked: false },
    { Months: 36, Checked: true },
    { Months: 37, Checked: false },
    { Months: 38, Checked: false },
    { Months: 39, Checked: false },
    { Months: 40, Checked: false },
    { Months: 41, Checked: false },
    { Months: 42, Checked: false },
    { Months: 43, Checked: false },
    { Months: 44, Checked: false },
    { Months: 45, Checked: false },
    { Months: 46, Checked: false },
    { Months: 47, Checked: false },
    { Months: 48, Checked: false },
    { Months: 49, Checked: false },
    { Months: 50, Checked: false },
    { Months: 51, Checked: false },
    { Months: 52, Checked: false },
    { Months: 53, Checked: false },
    { Months: 54, Checked: false },
    { Months: 55, Checked: false },
    { Months: 56, Checked: false },
    { Months: 57, Checked: false },
    { Months: 58, Checked: false },
    { Months: 59, Checked: false },
    { Months: 60, Checked: false },
    { Months: 72, Checked: false },
    { Months: 84, Checked: false },
    { Months: 96, Checked: false },
    { Months: 108, Checked: false },
    { Months: 120, Checked: false }
  ],
};

export const pricingContractSlice = createSlice({
  name: "pricing/contract",
  initialState,
  reducers: {
    selectProposedDate: (state, action) => {
      state.proposedDate = action.payload;
    },
    selectContractTerms: (state, action) => {
      state.contractTerms = action.payload;
    },
  },
});
export const { selectProposedDate, selectContractTerms } =
  pricingContractSlice.actions;

// Action creators are generated for each case reducer function
export default pricingContractSlice.reducer;
