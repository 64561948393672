import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosRiskInstance as axios } from "axios.js";

const INITIAL_STATE = {
  get_filtered_data: false,

  shortTerPositionFilterData: {
    loader: false,
  },
  dropDownListItems: {
    zones: { loader: false, data: [] },
    markets: { loader: false, data: [] },
    serviceClass: { loader: false, data: [] },
    product: { loader: false, data: [] },
  },
  filterStatus: false,

  dropDownList: {
    zones: [],
    markets: [],
    serviceClass: [],
    product: [],
    attrition: 0,
  },

  loadVsHedgeLongDataPosition: { list: [], loader: false },
  hedgeLongDataByBlock: { list: [], loader: false },
  hedgeVsLoadDataByBlockTable: { list: [], loader: false },
  longtermPositionloadHourlyData: { list: [], loader: false },
};

export const getRiskPostionLoadVsHedgeLongAsync = createAsyncThunk(
  "risk/getRiskPostionLoadVsHedgeLongAsync/long",
  () => {
    const response = axios
      .get(`api/v1/lt_position_reporting/get_long_term_position_load_vs_hedge`)
      .then((res) => {
        return res;
      });
    return response;
  }
);
export const getRiskPostionLoadVsHedgeLongByBlockTableAsync = createAsyncThunk(
  "risk/getRiskPostionLoadVsHedgeLongAsync/table/long",
  () => {
    const response = axios
      .get(
        `api/v1/lt_position_reporting/get_long_term_position_load_vs_hedge_by_block`
      )
      .then((res) => {
        return res;
      });
    return response;
  }
);
export const getRiskPostionLoadVsHedgeLongByBlockFilterTableAsync =
  createAsyncThunk(
    "risk/getRiskPostionLoadVsHedgeLongAsync/filter/table/long",
    (data) => {
      const response = axios
        .post(
          `api/v1/lt_position_reporting/filter_long_term_position_load_vs_hedge_by_block`,
          data
        )
        .then((res) => {
          return res;
        });
      return response;
    }
  );
export const getRiskPostionLoadVsHedgeLongHorlyFilterTableAsync =
  createAsyncThunk(
    "risk/getRiskPostionLoadVsHedgeLongAsync/filter/hourlytable/long",
    ({ pageNoHourly, rowsPerPageCountHorly, requestData }) => {
      const response = axios
        .post(
          `api/v1/lt_position_reporting/filter_long_term_position_load_vs_hedge_by_hour?page=${pageNoHourly}&page_size=${rowsPerPageCountHorly}`,
          requestData
        )
        .then((res) => {
          return res;
        });
      return response;
    }
  );

export const getlongTermPositionDropDownDataAsync = createAsyncThunk(
  "position/getLongTermDropDownData",
  async (data) => {
    const response = await axios.get(
      `/api/v1/configuration/${data.value}/long_term`,
      data
    );
    return response;
  }
);
export const getLongtermPositionLoadVsHedgeFilterdDataAsync = createAsyncThunk(
  "position/getLongTermDropDownData/filter",
  async (data) => {
    const response = await axios.post(
      "/api/v1/lt_position_reporting/filter_long_term_position_load_vs_hedge",
      data
    );
    return response;
  }
);
export const getLongtermPositionHedgeLongByBlockFilterdDataAsync =
  createAsyncThunk("position/getLongTerByBlock/filter", async (data) => {
    const response = await axios.post(
      "/api/v1/lt_position_reporting/filter_long_term_load_vs_hedge_by_block",
      data
    );
    return response;
  });
export const getRiskPostionLoadVsHedgeLongByBlockAsync = createAsyncThunk(
  "risk/getRiskPostionLoadVsHedgeLongBy/position",
  () => {
    const response = axios
      .get(`/api/v1/lt_position_reporting/long_term_load_vs_hedge_by_block`)
      .then((res) => {
        return res;
      });
    return response;
  }
);
export const getRiskPostionLoadVsHedgeLongByHourlyAsync = createAsyncThunk(
  "risk/getRiskPostionLoadVsHedgeLongBy/hourly/position",
  ({ pageNoHourly, rowsPerPageCountHorly }) => {
    const response = axios
      .get(
        `/api/v1/lt_position_reporting/get_long_term_position_load_vs_hedge_by_hour?page=${pageNoHourly}&page_size=${rowsPerPageCountHorly}`
      )
      .then((res) => {
        return res;
      });
    return response;
  }
);
export const longTermPositionSlice = createSlice({
  name: "position/longterm",
  initialState: INITIAL_STATE,
  reducers: {
    setFilterStatus: (state, action) => {
      state.filterStatus = action.payload;
    },
    marketOnChange: (state, action) => {
      state.dropDownList.markets = action.payload || [];
    },
    setDropDownListChangeNew: (state, action) => {
      const values = action?.payload?.values || [];
      const type = action?.payload?.type;
      if (type) {
        if (type === "attrition") {
          state.dropDownList[type] = action?.payload?.values || 0;
        } else {
          state.dropDownList[type] = values;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRiskPostionLoadVsHedgeLongAsync.pending, (state) => {
        state.loadVsHedgeLongDataPosition.loader = true;
      })
      .addCase(
        getRiskPostionLoadVsHedgeLongAsync.fulfilled,
        (state, action) => {
          state.loadVsHedgeLongDataPosition.loader = false;
          state.loadVsHedgeLongDataPosition.list =
            action?.payload?.data.data || [];
        }
      )
      .addCase(getRiskPostionLoadVsHedgeLongAsync.rejected, (state, action) => {
        state.loadVsHedgeLongDataPosition.loader = false;
      })
      .addCase(
        getLongtermPositionLoadVsHedgeFilterdDataAsync.pending,
        (state) => {
          state.loadVsHedgeLongDataPosition.loader = true;
        }
      )
      .addCase(
        getLongtermPositionLoadVsHedgeFilterdDataAsync.fulfilled,
        (state, action) => {
          state.loadVsHedgeLongDataPosition.loader = false;
          state.loadVsHedgeLongDataPosition.list =
            action?.payload?.data.data || [];
        }
      )
      .addCase(
        getLongtermPositionLoadVsHedgeFilterdDataAsync.rejected,
        (state, action) => {
          state.loadVsHedgeLongDataPosition.loader = false;
        }
      )
      .addCase(getRiskPostionLoadVsHedgeLongByBlockAsync.pending, (state) => {
        state.hedgeLongDataByBlock.loader = true;
      })
      .addCase(
        getRiskPostionLoadVsHedgeLongByBlockAsync.fulfilled,
        (state, action) => {
          state.hedgeLongDataByBlock.loader = false;
          state.hedgeLongDataByBlock.list = action?.payload?.data?.data || [];
        }
      )
      .addCase(
        getRiskPostionLoadVsHedgeLongByBlockAsync.rejected,
        (state, action) => {
          state.hedgeLongDataByBlock.loader = false;
        }
      )
      .addCase(getRiskPostionLoadVsHedgeLongByHourlyAsync.pending, (state) => {
        state.longtermPositionloadHourlyData.loader = true;
      })
      .addCase(
        getRiskPostionLoadVsHedgeLongByHourlyAsync.fulfilled,
        (state, action) => {
          state.longtermPositionloadHourlyData.loader = false;
          state.longtermPositionloadHourlyData.list =
            action?.payload?.data?.data || [];
        }
      )
      .addCase(
        getRiskPostionLoadVsHedgeLongByHourlyAsync.rejected,
        (state, action) => {
          state.longtermPositionloadHourlyData.loader = false;
        }
      )
      .addCase(
        getRiskPostionLoadVsHedgeLongHorlyFilterTableAsync.pending,
        (state) => {
          state.longtermPositionloadHourlyData.loader = true;
        }
      )
      .addCase(
        getRiskPostionLoadVsHedgeLongHorlyFilterTableAsync.fulfilled,
        (state, action) => {
          state.longtermPositionloadHourlyData.loader = false;
          state.longtermPositionloadHourlyData.list =
            action?.payload?.data?.data || [];
        }
      )
      .addCase(
        getRiskPostionLoadVsHedgeLongHorlyFilterTableAsync.rejected,
        (state, action) => {
          state.longtermPositionloadHourlyData.loader = false;
        }
      )

      .addCase(
        getRiskPostionLoadVsHedgeLongByBlockTableAsync.pending,
        (state) => {
          state.hedgeVsLoadDataByBlockTable.loader = true;
        }
      )
      .addCase(
        getRiskPostionLoadVsHedgeLongByBlockTableAsync.fulfilled,
        (state, action) => {
          state.hedgeVsLoadDataByBlockTable.loader = false;
          state.hedgeVsLoadDataByBlockTable.list =
            action?.payload?.data?.data || [];
        }
      )
      .addCase(
        getRiskPostionLoadVsHedgeLongByBlockTableAsync.rejected,
        (state, action) => {
          state.hedgeVsLoadDataByBlockTable.loader = false;
        }
      )
      .addCase(
        getRiskPostionLoadVsHedgeLongByBlockFilterTableAsync.pending,
        (state) => {
          state.hedgeVsLoadDataByBlockTable.loader = true;
        }
      )
      .addCase(
        getRiskPostionLoadVsHedgeLongByBlockFilterTableAsync.fulfilled,
        (state, action) => {
          state.hedgeVsLoadDataByBlockTable.loader = false;
          state.hedgeVsLoadDataByBlockTable.list =
            action?.payload?.data?.data || [];
        }
      )
      .addCase(
        getRiskPostionLoadVsHedgeLongByBlockFilterTableAsync.rejected,
        (state, action) => {
          state.hedgeVsLoadDataByBlockTable.loader = false;
        }
      )
      .addCase(
        getLongtermPositionHedgeLongByBlockFilterdDataAsync.pending,
        (state) => {
          state.hedgeLongDataByBlock.loader = true;
        }
      )
      .addCase(
        getLongtermPositionHedgeLongByBlockFilterdDataAsync.fulfilled,
        (state, action) => {
          state.hedgeLongDataByBlock.loader = false;
          state.hedgeLongDataByBlock.list = action?.payload?.data?.data || [];
        }
      )
      .addCase(
        getLongtermPositionHedgeLongByBlockFilterdDataAsync.rejected,
        (state, action) => {
          state.hedgeLongDataByBlock.loader = false;
        }
      )
      .addCase(
        getlongTermPositionDropDownDataAsync.pending,
        (state, action) => {
          state.dropDownListItems = {
            ...state.dropDownListItems,
            [action.meta.arg.key]: {
              ...state.dropDownListItems[action.meta.arg.key],
              data: [],

              loader: true,
            },
            loader: true,
          };
        }
      )
      .addCase(
        getlongTermPositionDropDownDataAsync.fulfilled,
        (state, action) => {
          state.dropDownListItems = {
            ...state.dropDownListItems,
            [action.meta.arg.key]: {
              ...state.dropDownListItems[action.meta.arg.key],
              data: Object.values(action?.payload?.data?.data[0] || {})[0],
              loader: false,
            },
            loader: false,
          };
        }
      )
      .addCase(getlongTermPositionDropDownDataAsync.rejected, (state) => {
        state.dropDownListItems = {
          ...state.dropDownListItems,
          loader: false,
        };
      });
  },
});

export const {
  marketOnChange,
  setDropDownListChangeNew,

  setFilterStatus,
} = longTermPositionSlice.actions;
export default longTermPositionSlice.reducer;
