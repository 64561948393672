import * as React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  IconButton,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const CustomDialog = (props) => {
  const {
    component,
    dialogTitle = "",
    openDialog = false,
    handleCloseButton,
    maxWidth = "false",
  } = props;
  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            color="primary"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };
  return (
    <div>
      <Dialog
        open={openDialog}
        maxWidth={maxWidth}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseButton}
        aria-describedby="alert-dialog-slide-description"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseButton}
        >
          {dialogTitle}
        </BootstrapDialogTitle>

        <DialogContent className="dialog-content" dividers>
          {component}
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default CustomDialog;
