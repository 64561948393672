import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosForecastInstance as axios } from "axios.js";

const initialState = {
  siteLevelData: { dropdownList: [], loader: false },
  siteLevelChartData: { list: [], loader: false },
  siteLevelDownloadData: { loader: false, data: {} },
  dateOne: "",
  dateTwo: "",
  dateBatch: "",

  filterStatus: false,
  siteLevelForecastingFilterData: {
    loader: false,
    dropDownListItems: {
      batch_dates: [],
      markets: [],
      products: [],
      utilities: [],
      year_list: [],
    },
  },
  utility_by_market: {},
  siteLevelForecastingTotalConsumptionChartData: {
    loader: false,
    totalConsumtionDataToPlot: [],
  },
  siteLevelForecastingDeltaChartData: {
    loader: false,
    deltaDataToPlot: [],
  },
};

export const getSiteLevelDataListAsync = createAsyncThunk(
  "forecasting/sitelevel/get/data",
  async (body) => {
    const response = await axios.get("/api/v1/getSiteLevelData", body);
    return response;
  }
);

export const getSiteLevelUpdateDataListAsync = createAsyncThunk(
  "forecasting/sitelevel/update/data",
  async (body) => {
    // try {
    const response = await axios.post(
      "/api/v1/getSiteLevelDataWithFilter",
      body
    );
    return response;
    // }
    // catch(err) {
    //   return rejectWithValue(err)
    // }
  }
);

export const getSiteLevelDownloadableFile = createAsyncThunk(
  "forecasting/sitelevel/file/data",
  async (body) => {
    // try {
    const response = await axios.get(
      `/api/v1/download_site_level_data?batch_date=${body.batch_date}`
    );
    return response;
  }
);

export const forecastingSiteLevelSlice = createSlice({
  name: "forecasting/sitelevel",
  initialState,
  reducers: {
    marketOnChange: (state, action) => {
      let data = [];
      if (action?.payload.length) {
        for (let val of action.payload) {
          data.push(...state.utility_by_market[val]?.utilities);
        }
      } else {
        Object.keys(state.utility_by_market).forEach((key, index) => {
          data.push(...state.utility_by_market[key]["utilities"]);
        });
      }
      state.siteLevelForecastingFilterData.dropDownListItems.utilities = data;
    },
    dateOneChange: (state, action) => {
      state.dateOne = action.payload;
    },
    dateBatchChange: (state, action) => {
      state.dateBatch = action.payload;
    },
    dateTwoChange: (state, action) => {
      state.dateTwo = action.payload;
    },
    setFilterStatus: (state, action) => {
      state.filterStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSiteLevelDataListAsync.pending, (state) => {
        state.siteLevelData.loader = true;

        // changes
        state.siteLevelForecastingFilterData.loader = true;
        state.siteLevelForecastingTotalConsumptionChartData.loader = true;
        state.siteLevelForecastingDeltaChartData.loader = true;
      })
      .addCase(getSiteLevelDataListAsync.fulfilled, (state, action) => {
        state.siteLevelData.loader = false;
        state.siteLevelData.dropdownList = action?.payload?.data || [];
        state.siteLevelChartData.list = action?.payload?.data || [];

        // changes
        state.siteLevelForecastingFilterData.loader = false;
        state.siteLevelForecastingFilterData.dropDownListItems = {
          batch_dates: action?.payload?.data?.batch_dates || [],
          markets: action?.payload?.data?.markets || [],
          products:
            action?.payload?.data?.products.sort((first, second) => {
              return first.localeCompare(second);
            }) || [],
          utilities: action?.payload?.data?.utilities || [],
          year_list: action?.payload?.data?.year_list || [],
        };

        state.utility_by_market =
          action?.payload?.data?.utility_by_market || {};

        state.siteLevelForecastingTotalConsumptionChartData.loader = false;
        state.siteLevelForecastingDeltaChartData.loader = false;
        state.siteLevelForecastingTotalConsumptionChartData.totalConsumtionDataToPlot =
          action?.payload?.data?.site_level_data || [];
        state.siteLevelForecastingDeltaChartData.deltaDataToPlot =
          action?.payload?.data?.delta_load || [];

        state.dateBatch = action?.payload?.data?.batch_dates[0] || "";
        state.dateTwo = action?.payload?.data?.batch_dates[0] || "";
        state.dateOne = action?.payload?.data?.batch_dates[1] || "";
      })
      .addCase(getSiteLevelDataListAsync.rejected, (state, action) => {
        state.siteLevelData.loader = false;
        state.siteLevelData.dropdownList = [];
        state.siteLevelForecastingFilterData.loader = false;

        // changes
        state.siteLevelForecastingTotalConsumptionChartData.loader = false;
        state.siteLevelForecastingDeltaChartData.loader = false;
      })
      .addCase(getSiteLevelUpdateDataListAsync.pending, (state) => {
        state.siteLevelChartData.loader = true;

        // changes
        state.siteLevelForecastingTotalConsumptionChartData.loader = true;
        state.siteLevelForecastingDeltaChartData.loader = true;
      })
      .addCase(getSiteLevelUpdateDataListAsync.fulfilled, (state, action) => {
        state.siteLevelChartData.loader = false;

        state.siteLevelChartData.list =
          action?.payload?.data || state.siteLevelChartData.list;
        // changes
        state.siteLevelForecastingTotalConsumptionChartData.totalConsumtionDataToPlot =
          action?.payload?.data?.site_level_data?.length > 0
            ? action?.payload?.data?.site_level_data
            : [];
        state.siteLevelForecastingDeltaChartData.deltaDataToPlot =
          // action?.payload?.data?.delta_load?.length > 0
          action?.payload?.data?.delta_load;
        // : state.siteLevelForecastingDeltaChartData.deltaDataToPlot;
        state.siteLevelForecastingTotalConsumptionChartData.loader = false;
        state.siteLevelForecastingDeltaChartData.loader = false;
      })
      .addCase(getSiteLevelUpdateDataListAsync.rejected, (state, action) => {
        state.siteLevelData.loader = false;
        state.siteLevelChartData.list = [];

        // changes
        state.siteLevelForecastingTotalConsumptionChartData.loader = false;
        state.siteLevelForecastingDeltaChartData.loader = false;
      })
      .addCase(getSiteLevelDownloadableFile.rejected, (state, action) => {
        state.siteLevelDownloadData.loader = false;
        state.siteLevelDownloadData.data = [];

        // changes
      })
      .addCase(getSiteLevelDownloadableFile.pending, (state, action) => {
        state.siteLevelDownloadData.loader = true;
        state.siteLevelDownloadData.data = {};

        // changes
      })
      .addCase(getSiteLevelDownloadableFile.fulfilled, (state, action) => {
        state.siteLevelDownloadData.loader = false;
        state.siteLevelDownloadData.data = action?.payload?.data
          ? action?.payload?.data
          : {};

        // changes
      });
  },
});
export const {
  dateOneChange,
  dateBatchChange,
  dateTwoChange,
  setFilterStatus,
  marketOnChange,
} = forecastingSiteLevelSlice.actions;
// Action creators are generated for each case reducer function
export default forecastingSiteLevelSlice.reducer;
