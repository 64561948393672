import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios.js";
import { axiosForecastInstance } from "axios.js";

const initialState = {
  existingCustomerList: {
    list: [],
    loader: false,
    total: 0,
  },
  existingCustomerDetails: {
    data: {},
    loader: false,
  },
  runForecast: { loader: false },
};
export const getExistingCustomerListAsync = createAsyncThunk(
  "existing/customer/list",
  async ({ clientCode, pageNo = 1, requestData, rowsPerPageCount }) => {
    const response = await axios.post(
      `/api/v1/customer/get?clientCode=${clientCode}&page=${pageNo}&pageSize=${rowsPerPageCount}`,
      requestData
    );
    return response;
  }
);
export const getExistingCustomerDetailsAsync = createAsyncThunk(
  "existing/customer/details",
  async ({ clientCode, customerId }) => {
    const response = await axios.get(
      `/api/v1/customer/get?clientCode=${clientCode}&customerId=${customerId}`
    );

    return response;
  }
);
export const getExistingCustomerUpdateAsync = createAsyncThunk(
  "existing/customer/update",
  async (requestBody) => {
    const response = await axios.put(`/api/v1/customer/update`, requestBody);

    return response;
  }
);
export const getExistingCustomerAddressDeleteAsync = createAsyncThunk(
  "existing/customer/address/delete",
  async ({ clientCode, addressId }) => {
    const response = await axios.delete(
      `/api/v1/customer/address/delete?clientCode=${clientCode}&addressId=${addressId}`
    );

    return response;
  }
);

export const getExistingCustomerDeleteAsync = createAsyncThunk(
  "existing/customer/delete",
  async ({ clientCode, customerId }) => {
    const response = await axios.delete(
      `/api/v1/customer/delete?clientCode=${clientCode}&customerId=${customerId}`
    );

    return response;
  }
);
export const postExistingCustomerRunForecastAsync = createAsyncThunk(
  "existing/customer/run/forecast",
  async ({ body }) => {
    const response = await axiosForecastInstance.post(
      `/api/v1/trigger_forecast`,
      body
    );

    return response;
  }
);
export const manageExistingCustomerSlice = createSlice({
  name: "existing/customer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getExistingCustomerListAsync.pending, (state) => {
        state.existingCustomerList.loader = true;
      })
      .addCase(getExistingCustomerListAsync.fulfilled, (state, action) => {
        state.existingCustomerList.list = action.payload.data.data.list || [];
        state.existingCustomerList.total = action.payload.data.data.total || 0;
        state.existingCustomerList.loader = false;
      })
      .addCase(getExistingCustomerListAsync.rejected, (state, action) => {
        state.existingCustomerList.list = [];
        state.existingCustomerList.total = 0;

        state.existingCustomerList.loader = false;
      })
      .addCase(getExistingCustomerDetailsAsync.pending, (state) => {
        state.existingCustomerDetails.loader = true;
      })
      .addCase(getExistingCustomerDetailsAsync.fulfilled, (state, action) => {
        state.existingCustomerDetails.data = action.payload.data.data || {};

        state.existingCustomerDetails.loader = false;
      })
      .addCase(getExistingCustomerDetailsAsync.rejected, (state, action) => {
        state.existingCustomerDetails.data = {};

        state.existingCustomerDetails.loader = false;
      })

      .addCase(getExistingCustomerUpdateAsync.pending, (state) => {})
      .addCase(getExistingCustomerUpdateAsync.fulfilled, (state, action) => {})
      .addCase(getExistingCustomerUpdateAsync.rejected, (state, action) => {})
      .addCase(getExistingCustomerDeleteAsync.pending, (state) => {})
      .addCase(getExistingCustomerDeleteAsync.fulfilled, (state, action) => {})
      .addCase(getExistingCustomerDeleteAsync.rejected, (state, action) => {})
      .addCase(postExistingCustomerRunForecastAsync.pending, (state) => {
        state.runForecast.loader = true;
      })
      .addCase(
        postExistingCustomerRunForecastAsync.fulfilled,
        (state, action) => {
          state.runForecast.loader = false;
        }
      )
      .addCase(
        postExistingCustomerRunForecastAsync.rejected,
        (state, action) => {
          state.runForecast.loader = false;
        }
      );
  },
});

export default manageExistingCustomerSlice.reducer;
