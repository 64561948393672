import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios.js";

const initialState = {
  customer: { customerId: "" },
};
export const postAddCustomerAsync = createAsyncThunk(
  "customer/add/new",
  async (data) => {
    const response = await axios.post(`/api/v1/customer/add`, data);
    return response;
  }
);
export const postAddCustomerAddressAsync = createAsyncThunk(
  "customer/add/new/address",
  async ({ entityId, requestBody, type }) => {
    const response = await axios.post(
      `/api/v1/customer/${type}/add?entityId=${entityId}`,
      requestBody
    );
    return response;
  }
);
export const postUpdateCustomerAddressAsync = createAsyncThunk(
  "customer/add/update/address",
  async ({ body }) => {
    const response = await axios.put(`/api/v1/customer/address/update`, body);
    return response;
  }
);
export const customerAddSlice = createSlice({
  name: "customer/add",
  initialState,
  reducers: {
    updateCustomerID: (state) => {
      state.customer.customerId = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postAddCustomerAsync.pending, (state) => {})
      .addCase(postAddCustomerAsync.fulfilled, (state, action) => {
        state.customer =
          { customerId: action?.payload?.data?.data?.value } || {};
      })
      .addCase(postAddCustomerAsync.rejected, (state, action) => {
        state.customer = {};
      })
      .addCase(postAddCustomerAddressAsync.pending, (state) => {})
      .addCase(postAddCustomerAddressAsync.fulfilled, (state, action) => {})
      .addCase(postAddCustomerAddressAsync.rejected, (state, action) => {})
      .addCase(postUpdateCustomerAddressAsync.pending, (state) => {})
      .addCase(postUpdateCustomerAddressAsync.fulfilled, (state, action) => {})
      .addCase(postUpdateCustomerAddressAsync.rejected, (state, action) => {});
  },
});
export const { updateCustomerID } = customerAddSlice.actions;

export default customerAddSlice.reducer;
