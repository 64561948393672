import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { axiosPricingProspectDataSet } from "axios.js";
import axiosForIp from "axios";

const initialState = {
  uploadData: {
    loader: false,
    previewData: {},
  },
  uploadDataCustomer: { loader: false },
  IP: {},
  FileUploadGuid: "",
  FileUploadGuidMultiple: "",
};

export const postUploadDatasAsync = createAsyncThunk(
  "pricing/upload/new",
  async (data) => {
    const { client_code, file, templateType, IP, clientcurve } = data;

    let url;
    let axiosType;
    if (data?.comment) {
      url = `/api/v1/misc/${templateType}?clientCode=${client_code}${data?.comment}${data?.purpose}&ip=${IP}`;
    } else {
      url = `/api/v1/misc/${templateType}?clientCode=${client_code}&ip=${IP}`;
    }
    if (templateType === "data/loadProfileForcastedIntervalUsage") {
      url = `api/v1/data-load/forecasted_usage/load?client_code=${client_code}`;
      axiosType = axiosPricingProspectDataSet;
    } else {
      axiosType = axios;
    }

    let reqBody;
    if (
      templateType === "data/ComponentDataCurve/load" ||
      templateType === "data/ComponentDataCurveV2/load"
    ) {
      reqBody = { file: file, clientcurve: clientcurve };
    } else {
      reqBody = { file: file };
    }

    let headers = {
      "Content-Type": `multipart/form-data: boundary=add-random-characters`,
    };
    const response = await axiosType
      .post(url, reqBody, {
        headers,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  }
);
export const postUploadDataCustomerAsync = createAsyncThunk(
  "pricing/upload/new/against/customer",
  async (data) => {
    const { client_code, file, templateType, customerId, IP } = data;
    console.log({ templateType });
    let reqBody = { file: file, customerId: customerId };
    let headers = {
      "Content-Type": `multipart/form-data: boundary=add-random-characters`,
    };
    let consolidateApi;
    let axiosType;
    if (templateType === "ProspectDataSet") {
      consolidateApi = `api/v1/data-load/consolidated-prospect/load?client_code=${client_code}`;
      axiosType = axiosPricingProspectDataSet;
    } else {
      consolidateApi = `/api/v1/misc/data/${templateType}/load?clientCode=${client_code}&ip=${IP}`;
      axiosType = axios;
    }
    const response = await axiosType
      .post(consolidateApi, reqBody, { headers })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return response;
  }
);
export const getIPAsync = createAsyncThunk(
  "pricing/upload/new/IP",
  async () => {
    const response = await axiosForIp
      .get("https://www.cloudflare.com/cdn-cgi/trace")
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return response;
  }
);
export const getUsageDataListAsync = createAsyncThunk(
  "costomer/getUsageDataList",
  async (data) => {
    const { client_code, count, pageNo, type, uploadIdentifier } = data;
    const response = await axios.get(
      `/api/v1/misc/data/usage/${type}/postview?uploadIdentifier=${uploadIdentifier}&clientCode=${client_code}&page=${pageNo}&pageSize=${count}`,
      data
    );
    return response;
  }
);
export const getUsageDataListByCustomerIdAsync = createAsyncThunk(
  "costomer/getUsageDataList/byCustomerId",
  async (data) => {
    const {
      client_code,
      count,
      totalRowsLoaded,
      customerId,
      uan,
      type,
      UsageStartDateFrom,
      UsageStartDateTo,
      UsageQualifierCode,
      TimeOfUseCode,
      UnitOfMeasureCode,
      CommodityTypeCode,
      ServiceProfileCode,
    } = data;

    const payload = {
      customerId: customerId,
      clientCode: client_code,
      UAN: uan,
      CommodityTypeCode: CommodityTypeCode,
      ServiceProfileCode: ServiceProfileCode,
      UsageQualifierCode: UsageQualifierCode,
      TimeOfUseCode: TimeOfUseCode,
      UnitOfMeasureCode: UnitOfMeasureCode,
      UsageStartDateFrom: UsageStartDateFrom,
      UsageStartDateTo: UsageStartDateTo,
    };
    const response = await axios.post(
      `/api/v1/customer/${type}/get?totalRowsLoaded=${totalRowsLoaded}&pageSize=${count}`,
      payload
    );

    return response;
  }
);

export const pricingUploadSlice = createSlice({
  name: "pricing/upload",
  initialState,
  reducers: {
    UpdateFileUploadGuid: (state, action) => {
      state.FileUploadGuid = action?.payload;
    },
    UploadFileGuidForMultiple: (state, action) => {
      state.FileUploadGuidMultiple = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postUploadDatasAsync.pending, (state) => {
        state.uploadData.loader = true;
      })
      .addCase(postUploadDatasAsync.fulfilled, (state, action) => {
        state.uploadData.loader = false;

        state.uploadData.previewData = action?.payload?.data?.data;
      })
      .addCase(postUploadDatasAsync.rejected, (state) => {
        state.uploadData.loader = false;
      })
      .addCase(postUploadDataCustomerAsync.pending, (state) => {
        state.uploadDataCustomer.loader = true;
      })
      .addCase(postUploadDataCustomerAsync.fulfilled, (state, action) => {
        state.uploadDataCustomer.loader = false;
      })
      .addCase(postUploadDataCustomerAsync.rejected, (state) => {
        state.uploadDataCustomer.loader = false;
      })
      .addCase(getIPAsync.pending, (state) => {})
      .addCase(getIPAsync.fulfilled, (state, action) => {
        const ipPattern = /ip=([^\n]+)/;
        const match = action?.payload?.data?.match(ipPattern);

        if (match && match.length >= 2) {
          const ipAddress = match[1];
          state.IP = ipAddress;
        } else {
          console.log("No IP address found.");
          state.IP = "";
        }
      })

      .addCase(getIPAsync.rejected, (state) => {})
      .addCase(getUsageDataListAsync.pending, (state) => {
        state.uploadData.loader = true;
      })
      .addCase(getUsageDataListAsync.fulfilled, (state, action) => {
        state.uploadData.loader = false;
        state.uploadData.previewData = action?.payload?.data?.data;
      })
      .addCase(getUsageDataListAsync.rejected, (state) => {
        state.uploadData.loader = false;
      })
      .addCase(getUsageDataListByCustomerIdAsync.pending, (state) => {
        state.uploadData.loader = true;
      })
      .addCase(getUsageDataListByCustomerIdAsync.fulfilled, (state, action) => {
        state.uploadData.loader = false;
        state.uploadData.previewData = action?.payload?.data?.data;
      })
      .addCase(getUsageDataListByCustomerIdAsync.rejected, (state) => {
        state.uploadData.loader = false;
      });
  },
});
export const { UpdateFileUploadGuid, UploadFileGuidForMultiple } =
  pricingUploadSlice.actions;

// Action creators are generated for each case reducer function
export default pricingUploadSlice.reducer;
