import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosForecastInstance as axios } from "axios.js";

const INITIAL_STATE = {
  get_filtered_data: false,

  zoneTreeMapFilterData: {
    loader: false,
    dropDownListItems: {
      batch_dates:[],
      customer_types: [],
      flow_dates: [],
      forecast_date_to_select:"",
      markets: [],
      product_types: [],
      utilities: [],
      zones: [],
    },
  },
  utility_zones_by_market: {},
  zoneTreeMapChartData: { loader: false, dataToPlot: [] },
};

export const getZoneTreemapForecastInitialDataAsync = createAsyncThunk(
  "forecasting/getZoneTreemapInitialData",
  async (data) => {
    const response = await axios.get("/api/v1/getZoneTreeMapData", data);
    return response;
  }
);

export const getUpdatedZoneTreemapForecastDataAsync = createAsyncThunk(
  "forecasting/getZoneTreemapUpdatedData",
  async (data) => {
    const response = await axios.post(
      "/api/v1/getZoneTreeMapDataWithFilter",
      data
    );
    return response;
  }
);

export const zoneTreeForecastSlice = createSlice({
  name: "forecasting/zonetree",
  initialState: INITIAL_STATE,
  reducers: {
    marketOnChange: (state, action) => {
      let data_utilities = [];
      let data_zones = [];
      if (action?.payload.length) {
        for (let val of action.payload) {
          data_utilities.push(...state.utility_zones_by_market[val]?.utilities);
          data_zones.push(...state.utility_zones_by_market[val]?.zones);
        }
      } else {
        Object.keys(state.utility_zones_by_market).forEach((key, index) => {
          data_utilities.push(
            ...state.utility_zones_by_market[key]["utilities"]
          );
          data_zones.push(...state.utility_zones_by_market[key]["zones"]);
        });
      }
      state.zoneTreeMapFilterData.dropDownListItems.utilities = data_utilities;
      state.zoneTreeMapFilterData.dropDownListItems.zones = data_zones;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getZoneTreemapForecastInitialDataAsync.pending, (state) => {
        // changes
        state.zoneTreeMapFilterData.loader = true;
        state.zoneTreeMapChartData.loader = true;
      })
      .addCase(
        getZoneTreemapForecastInitialDataAsync.fulfilled,
        (state, action) => {
          // changes
          state.zoneTreeMapFilterData.loader = false;
          state.zoneTreeMapChartData.loader = false;

          state.zoneTreeMapFilterData.dropDownListItems = {
            batch_dates: action?.payload?.data?.batch_date || [],
            customer_types: action?.payload?.data?.customer_types || [],
            flow_dates: action?.payload?.data?.flow_dates || [],
            forecast_date_to_select: action?.payload?.data?.forecast_date_to_select || "",
            markets: action?.payload?.data?.markets || [],
            product_types: action?.payload?.data?.product_types || [],
            utilities: action?.payload?.data?.utility || [],
            zones: action?.payload?.data?.zones || [],
          };
          state.zoneTreeMapChartData.dataToPlot =
            action?.payload?.data?.zone_treemap_data || [];
          state.utility_zones_by_market =
            action?.payload?.data?.utility_zones_by_market || {};
        }
      )
      .addCase(getZoneTreemapForecastInitialDataAsync.rejected, (state) => {
        state.zoneTreeMapFilterData.loader = false;
        state.zoneTreeMapChartData.loader = false;

        state.get_filtered_data = false;
      })
      .addCase(getUpdatedZoneTreemapForecastDataAsync.pending, (state) => {
        state.zoneTreeMapChartData.loader = true;
      })
      .addCase(
        getUpdatedZoneTreemapForecastDataAsync.fulfilled,
        (state, action) => {
          state.zoneTreeMapChartData.loader = false;
          state.zoneTreeMapChartData.dataToPlot =
            action?.payload?.data?.zone_treemap_data?.length > 0
              ? action?.payload?.data?.zone_treemap_data
              : state.zoneTreeMapChartData.dataToPlot;
        }
      )
      .addCase(getUpdatedZoneTreemapForecastDataAsync.rejected, (state) => {
        state.zoneTreeMapChartData.loader = false;
      });
  },
});

export const { marketOnChange } = zoneTreeForecastSlice.actions;
export default zoneTreeForecastSlice.reducer;
