export const navigations = [
  {
    name: "Data Management",
    path: "datamanagement",
    icon: "fas fa-fw fa-address-book",
    feature: "datamanagement",
    children: [
      { name: "New Customer", path: "datamanagement", default: true },
      {
        name: "Manage Existing Customer",
        path: "datamanagement/customer/existing",
      },
      { name: "Data Upload", path: "datamanagement/customer/data/uploads" },
    ],
  },
  {
    name: "Pricing",
    path: "pricing",
    icon: "fas fa-fw fa-tag",
    feature: "pricing",
    children: [
      {
        name: "Custom",
        path: "pricing",
        privilege: "pricing.custom",
        default: true,
        child: [
          {
            name: "Pricing Setup",
            privilege: "pricing.custom.setup",
            path: "pricing",
            default: true,
          },
          {
            name: "Pricing Status",
            privilege: "pricing.custom.status",
            path: "pricing/status",
          },
          {
            name: "Model Configuration",
            privilege: "pricing.custom.configuration",
            path: "pricing/configuration",
          },
        ],
      },
      {
        name: "Matrix",
        path: "pricing/matrix",
        privilege: "pricing.matrix",
        child: [
          {
            name: "Pricing Setup",
            privilege: "pricing.matrix.setup",
            path: "pricing/matrix",
            default: true,
          },
          {
            name: "Pricing Status",
            privilege: "pricing.matrix.status",
            path: "pricing/matrix/status",
          },
          {
            name: "Model Configuration",
            privilege: "pricing.matrix.configuration",
            path: "pricing/matrix/configuration",
          },
          {
            name: "Download",

            path: "pricing/matrix/download",
          },
        ],
      },
      {
        name: "Price Curves",
        privilege: "pricing.curves",
        path: "pricing/curve",
      },

      // { name: "Data Uploads", path: "pricing/uploads" },
      //{ name: "Pricing Output", path: "pricing/runs" },
    ],
    // subChildrens: [{ name: "Pricing Status", path: "pricing/status" }],
  },
  {
    name: "Forecasting",
    path: "forecasting",
    icon: "fas fa-fw fa-cloud",
    feature: "forecasting",
    children: [
      {
        name: "Dashboard",
        path: "forecasting",
        privilege: "forecasting.dashboard",
        default: true,
      },
      {
        name: "Reports and Analytics",
        path: "forecasting/reports",
      },
      { name: "Run Forecast", path: "forecasting/run", disable: true },
      { name: "Configuration", path: "forecasting/config", disable: true },
    ],
  },
  {
    name: "Renewables",
    path: "renewable",
    icon: "fas fa-fw fa-warning",
    feature: "renewable",
  },
  {
    name: "Risk Management",
    path: "riskreport",
    icon: "fas fa-fw fa-cloud",
    feature: "riskreport",

    children: [
      {
        name: "Position Reporting",
        path: "riskreport",
        privilege: "riskreport.position",
        default: true,
      },
      {
        name: "Trade Capture",
        path: "riskreport/trade",
        privilege: "riskreport.tradecapture",
      },
      {
        name: "Cost/Variance Analysis",
        path: "riskreport/analysis",
        privilege: "riskreport.costvariance",
        disable: true,
      },
    ],
  },
  {
    name: "Settlements",
    path: "settlement",
    icon: "fas fa-fw fa-book",
    feature: "settlement",
    children: [
      { name: "Volumetric", path: "settlement", default: true },
      { name: "Financials", path: "settlement/financial" },
    ],
  },
  {
    name: "Asset Valuation",
    path: "asset",
    icon: "fas fa-fw fa-folder-open",
    feature: "asset",
    children: [
      { name: "Asset View", path: "asset", default: true },
      { name: "Mark To Market", path: "asset/marktomarket" },
    ],
  },
  {
    name: "Analytics",
    path: "analytics",
    icon: "fas fa-fw fa-chart-area",
    feature: "analytics",
  },
  {
    name: "Reports",
    path: "reports",
    icon: "fas fa-fw fa-file-arrow-down",
    feature: "reports",
    children: [
      { name: "File Upload Audit Logs", path: "reports", default: true },
    ],
  },
  {
    name: "Admin",
    path: "admin",
    icon: "fas fa-fw fa-user",
    feature: "admin",
    children: [
      { name: "User Management", path: "admin" },
      {
        name: "Config Management",
        path: "admin/config-domain",
        default: true,
      },
      {
        name: "Manage Service Profile",
        path: "admin/service-profile",
      },
    ],
  },
];
