import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios.js";

const initialState = {
  customerTypeList: { list: [], loader: false },
  customerSubTypeList: { list: [], loader: false },
};
export const getCustomerTypeAsync = createAsyncThunk(
  "customer/customerType",
  async (clientCode) => {
    const response = await axios.get(
      `/api/v1/general_configuration/get?clientCode=${clientCode}&configDomain=GENERAL_CUSTOMER_TYPE`
    );
    return response;
  }
);
export const getCustomerSubTypeAsync = createAsyncThunk(
  "customer/customerSubType",
  async ({ clientCode, customerType }) => {
    const response = await axios.get(
      `/api/v1/general_configuration/get?clientCode=${clientCode}&configDomain=GENERAL_CUSTOMER_TYPE&configElement=${customerType}`
    );
    return response;
  }
);
export const customerDataSlice = createSlice({
  name: "customer/data",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerTypeAsync.pending, (state) => {
        state.customerTypeList.loader = true;
      })
      .addCase(getCustomerTypeAsync.fulfilled, (state, action) => {
        state.customerTypeList.loader = false;
        state.customerTypeList.list = action.payload.data.data || [];
      })
      .addCase(getCustomerTypeAsync.rejected, (state, action) => {
        state.customerTypeList.loader = false;
        state.customerTypeList.list = [];
      })
      .addCase(getCustomerSubTypeAsync.pending, (state) => {
        state.customerSubTypeList.loader = true;
      })
      .addCase(getCustomerSubTypeAsync.fulfilled, (state, action) => {
        state.customerSubTypeList.loader = false;
        state.customerSubTypeList.list = action.payload.data.data || [];
      })
      .addCase(getCustomerSubTypeAsync.rejected, (state, action) => {
        state.customerSubTypeList.loader = false;
        state.customerSubTypeList.list = [];
      });
  },
});

export default customerDataSlice.reducer;
