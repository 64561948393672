import axios from "axios";

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
});

export const axiosSettlementsForcastDemoInstance = axios.create({
  baseURL: `${process.env.REACT_APP_FORECAST_API_URL}`,
});
const errorHandler = (error) => {
  if (error.response.status === 401) {
    window.location = "/session/signin";
  } else {
    return Promise.reject(
      (error.response && error.response.data) || "Something went wrong!"
    );
  }
};
const errorHandlerConfigManagement = (error) => {
  if (error?.response?.status === 401) {
    window.location = "/session/signin";
  } else {
    return Promise.reject(
      error?.response?.data?.detail[0].msg || "Something went wrong"
    );
  }
};

axiosSettlementsForcastDemoInstance.interceptors.response.use(
  (response) => response,
  (error) => errorHandlerConfigManagement(error)
);
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => errorHandler(error)
);

export default axiosInstance;

export const axiosForecastInstance = axios.create({
  baseURL: `${process.env.REACT_APP_FORECAST_API_URL}`,
});

axiosForecastInstance.interceptors.response.use(
  (response) => response,
  (error) => errorHandler(error)
);
export const axiosSettlementInstance = axios.create({
  baseURL: `${process.env.REACT_APP_SETTLEMENT_API_URL}`,
});

axiosSettlementInstance.interceptors.response.use(
  (response) => response,
  (error) => errorHandler(error)
);

export const axiosForecastRiskInstance = axios.create({
  baseURL: `${process.env.REACT_APP_FORECAST_API_URL}`,
});

axiosForecastRiskInstance.interceptors.response.use(
  (response) => response,
  (error) => errorHandler(error)
);

export const axiosRiskInstance = axios.create({
  baseURL: `${process.env.REACT_APP_RISK_MANAGEMENT_API_URL}`,
});

axiosRiskInstance.interceptors.response.use(
  (response) => response,
  (error) => errorHandler(error)
);

export const axiosConfigManagement = axios.create({
  baseURL: `${process.env.REACT_APP_CONFIG_MANAGEMENT_API_BASE_URL}`,
});

axiosConfigManagement.interceptors.response.use(
  (response) => response,
  (error) => errorHandlerConfigManagement(error)
);
export const axiosPricingProspectDataSet = axios.create({
  baseURL: `${process.env.REACT_APP_PRICING_PROSPECT_DATA_SET_API_URL}`,
});
axiosPricingProspectDataSet.interceptors.response.use(
  (response) => response,
  (error) => errorHandlerConfigManagement(error)
);
