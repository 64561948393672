import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios.js";

const initialState = {
  fileUploadData: { list: [], loader: false },
  fileDownloadData: { data: {}, loader: false },
  fileViewData: { data: {}, loader: false },
};

export const getFileUploadDataApi = createAsyncThunk(
  "get/upload/data/logs",
  ({ client_code, page, pageSize }) => {
    const response = axios
      .get(
        `/api/v1/misc/get-logs/${client_code}?page=${page}&pageSize=${pageSize}`
      )
      .then((res) => {
        return res;
      });
    return response;
  }
);
export const getFileDownloadApi = createAsyncThunk(
  "get/upload/data/download",
  ({ fileName, fileKey, client_code }) => {
    const response = axios
      .get(
        `/api/v1/misc/get-file/${client_code}?fileKey=${fileKey}&fileName=${fileName}`,
        { responseType: "arraybuffer" }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log({ err });
      });

    return response;
  }
);
export const getFileDetailsApi = createAsyncThunk(
  "get/upload/data/view",
  ({ uuid }) => {
    const response = axios
      .get(`api/v1/misc/get-log?identifier=${uuid}`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log({ err });
      });

    return response;
  }
);

export const fileUploadLogSlice = createSlice({
  name: "upload/logs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFileUploadDataApi.pending, (state) => {
        state.fileUploadData.loader = true;
      })
      .addCase(getFileUploadDataApi.fulfilled, (state, action) => {
        state.fileUploadData.loader = false;
        state.fileUploadData.list = action?.payload?.data || [];
      })
      .addCase(getFileUploadDataApi.rejected, (state, action) => {
        state.fileUploadData.loader = false;
      })
      .addCase(getFileDownloadApi.pending, (state) => {
        state.fileDownloadData.loader = true;
      })
      .addCase(getFileDownloadApi.fulfilled, (state, action) => {
        state.fileDownloadData.loader = false;
        state.fileUploadData.data = action?.payload?.data || {};
      })
      .addCase(getFileDownloadApi.rejected, (state, action) => {
        state.fileUploadData.loader = false;
      })
      .addCase(getFileDetailsApi.pending, (state) => {
        state.fileViewData.loader = true;
      })
      .addCase(getFileDetailsApi.fulfilled, (state, action) => {
        state.fileDownloadData.loader = false;
        state.fileViewData.data = action?.payload?.data || {};
      })
      .addCase(getFileDetailsApi.rejected, (state, action) => {
        state.fileViewData.loader = false;
      });
  },
});
export default fileUploadLogSlice.reducer;
