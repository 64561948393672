import { Autocomplete, Container, Grid, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import {
  getCustomerListAsync,
  selectCustomer,
} from "app/redux/slices/pricing/pricingCustomerSlice";
import CustomButton from "features/components/customComponents/CustomButton";
import CustomTextField from "features/components/customComponents/CustomTextField";
import { LongDateFormat, searchCriteria } from "features/CustomDatas";
import { useState } from "react";
import { FieldSkeliton } from "features/components/customComponents/CustomSkeleton";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";

const SearchCustomer = ({ handleCloseButton }) => {
  const dispatch = useDispatch();

  const pricingClientState = useSelector((state) => state.pricingClient);
  const pricingCustomerState = useSelector((state) => state.pricingCustomer);
  const [searchField, setSearchField] = useState("");
  const [searchCriteriaField, setSearchCriteria] = useState("name");
  const textChange = (e) => {
    setSearchField(e.target.value);
  };

  const onChangeSearchCriteria = (e, val) => {
    setSearchCriteria(val);
  };
  const searchApiTrigger = () => {
    let data = {
      searchTerm: searchField,
      searchCriteria: searchCriteriaField,
      clientCode: pricingClientState.clientList.selected.client_code,
    };
    dispatch(getCustomerListAsync(data));
  };
  const handleCheckChildElement = (event, item) => {
    dispatch(selectCustomer(item));
    setTimeout(() => {
      handleCloseButton();
    }, 300);
  };
  return (
    <div style={{ minWidth: 1000, minHeight: 200 }}>
      <Container>
        <p>
          Begin typing the name of your customer name, then make your selection
          from the list.
        </p>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} sm={5}>
            <CustomTextField
              name="search"
              label="Search"
              variant="outlined"
              required
              size="small"
              fullWidth
              margin="normal"
              onChange={textChange}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <Autocomplete
              disablePortal
              name="search_criteria"
              margin="normal"
              id="ddlsearch_criteria"
              defaultValue={searchCriteriaField}
              options={searchCriteria}
              onChange={onChangeSearchCriteria}
              size="small"
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Criteria"
                  required
                  margin="normal"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <CustomButton
              name="Search"
              margin="normal"
              endIcon={<SearchIcon fontSize="small" />}
              fullWidth
              disabled={searchField.length === 0}
              onClick={searchApiTrigger}
              style={{
                marginTop: 5,
                minWidth: 100,
                minHeight: 30,
                textTransform: "none",
                color: "white",
              }}
            />
          </Grid>
        </Grid>
        {pricingCustomerState.customerList.loader ? (
          <FieldSkeliton />
        ) : (
          <>
            {pricingCustomerState.customerList.list ? (
              pricingCustomerState.customerList.list.length ? (
                <div className="row" style={{ paddingTop: 20 }}>
                  <div className="col-md-12">
                    <div className="form-group">
                      <div className="table-responsive">
                        <table className="table table-bordered" id="accounts">
                          <tr>
                            {" "}
                            <th className="colu1">Customer ID</th>
                            <th> Customer Information</th>
                            <th> Client Customer No.</th>
                            <th> Status Code</th> <th>Created Date</th>
                            <th className="colu2"> Select</th>
                          </tr>

                          {pricingCustomerState.customerList.list.length
                            ? pricingCustomerState.customerList.list
                                .slice()
                                .sort((a, b) => a.customerId - b.customerId)
                                .map((item) => (
                                  <tr>
                                    <td>
                                      <div className="form-check form-check-inline">
                                        <label
                                          className="form-check-label"
                                          for="inlineCheckbox1"
                                        >
                                          {item.customerId}
                                        </label>
                                      </div>
                                    </td>
                                    <td>
                                      {item.firstName}
                                      {item.lastName} {""}
                                      {item.companyName}
                                    </td>
                                    <td>{item.clientCustomerNumber}</td>
                                    <td>{item?.status}</td>{" "}
                                    <td>
                                      {dayjs(item?.createdTs).format(
                                        `${LongDateFormat}`
                                      )}
                                    </td>
                                    <td>
                                      <CustomButton
                                        name="select"
                                        onClick={(event) =>
                                          handleCheckChildElement(event, item)
                                        }
                                      />
                                    </td>
                                  </tr>
                                ))
                            : ""}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </>
        )}
      </Container>
    </div>
  );
};
export default SearchCustomer;
