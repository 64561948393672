import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";

export const searchCriteria = [
  "name",
  "first-name",
  "last-name",
  "company-name",
  "account",
];

export const RiskManagementUser =
  "engie@firefly-es.com,gc@firefly-es.com,test@firefly-es.com,ff@firefly-es.com";

export const NewRiskUserEngie = (userName) => {
  return userName === "engie@firefly-es.com";
};

export const NewRiskUser = (userName) => {
  return RiskManagementUser.indexOf(userName) > -1 || userName.indexOf("@goodcharlie.com") > -1 || userName.indexOf("standardge") > -1;
};

export const DateFormat = "MM/DD/YYYY";
export const LongDateFormat = "MM/DD/YYYY hh:mm A";
export const StringDateFormat = "YYYY-MM-DDTHH:mm:ssZZ";
export const MonthAndYearDateFormat = "MMM-YYYY";
export const YearMonthDateFormate = "YYYY-MM-DD";

export const TermTableData = {
  12: 52.1,
  24: 51.83,
  36: 51.98,
};

export const SyncfusionLicenseKey =
  "ORg4AjUWIQA/Gnt2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5Xd0djXnpWcHZVR2db";
//export const SyncfusionLicenseKey="MTY4NzU1M0AzMjMxMmUzMTJlMzMzNVAxYkNCcFNzZ3FsQnZqcHUydGl1LzZlWDBNUFlxc29laE1ZMGpFR3N0ckU9;Mgo+DSMBaFt+QHFqVkNrWE5FfkBAXWFKblJ2T2BQdVpxZCQ7a15RRnVfRlxiSH5Qf0FiWnhecg==;Mgo+DSMBMAY9C3t2VFhhQlJBfVtdXGtWfFN0RnNYf1RzfV9CYUwgOX1dQl9gSXpSd0RjWnZacXdcRGI=;Mgo+DSMBPh8sVXJ1S0d+X1RPckBDVXxLflF1VWJZdV15flFHcC0sT3RfQF5jTX9Sd0VgUHtfdHVVRA==;MTY4NzU1N0AzMjMxMmUzMTJlMzMzNU9zaG4ra2EyRW5jN2pGeUhPMjV0Y2EraVhuQUZqbktpSFc0dFZsUmM4SVU9;NRAiBiAaIQQuGjN/V0d+XU9Hc1RGQmJBYVF2R2BJfl56d1xMZF1BNQtUQF1hSn5Xd0RiWXxXdHVRQ2Fe;ORg4AjUWIQA/Gnt2VFhhQlJBfVtdXGtWfFN0RnNYf1RzfV9CYUwgOX1dQl9gSXpSd0RjWnZacXBTQGI=;MTY4NzU2MEAzMjMxMmUzMTJlMzMzNW53cVFhTmgzRVpmSUN2WFlFcUVTRm1ibzg0cHF6SW1ReTExVWt4NmV2TEk9;MTY4NzU2MUAzMjMxMmUzMTJlMzMzNWk1ci9pSGxOdWFnRGI0a0F2aTMvVktPUVhERFVlbHZEbXBSQkpQbUMvU0k9;MTY4NzU2MkAzMjMxMmUzMTJlMzMzNWZLRDBDU3Z3NGppSzFzeGRtdVJWSUtrV29NMHBoeXVjSGk2MGZTYXdBWHM9;MTY4NzU2M0AzMjMxMmUzMTJlMzMzNWN2WlZkdlhxMC8vNkVPUDR0MXBndDI0OVpLTmVRVDkzMWFrNWNRRjJNUE09;MTY4NzU2NEAzMjMxMmUzMTJlMzMzNVAxYkNCcFNzZ3FsQnZqcHUydGl1LzZlWDBNUFlxc29laE1ZMGpFR3N0ckU9";

export const ResultData = [
  {
    term: 12,
    value: 52.0974645724555,
    loads: [
      { key: "Retail", value: 9276 },
      { key: "Wholesale", value: 9865 },
    ],
    powers: [
      { key: "7x24 Block", value: 1.11 },
      { key: "5x16 Block", value: 1.23 },
      { key: "2x16 Block", value: 0.83 },
      { key: "7x8 Block", value: 0.94 },
    ],
    components: [
      {
        key: "Energy",
        cssClass: "table-primary",
        selected: true,
        effect: 0.6,
        value: 46.83,
        hideExpandRows: true,
        expandableComponents: [
          {
            key: "7x24",
            value: 1.11,
            effect: 0.1,
            selected: true,
          },
          {
            key: "5x16",
            value: 1.23,
            effect: 0.06,
            selected: true,
          },
          {
            key: "2x16",
            value: 0.83,
            effect: 0.03,
            selected: true,
          },
          {
            key: "7x8",
            value: 0.94,
            effect: 0.01,
            selected: true,
          },
        ],
        childComponents: [
          {
            key: "Zonal Price",
            value: 46.68,
            effect: 0.2,
            selected: true,
          },
          {
            key: "Fin-to-Phys Basis",
            value: 0.15,
            effect: 0.2,
            selected: true,
          },
        ],
      },
      {
        key: "Losses",
        cssClass: "table-primary",
        selected: true,
        effect: 0.06,
        value: 0.0,
        childComponents: [
          {
            key: "T & D",
            value: 0,
            effect: 0.6,
            selected: true,
          },
          {
            key: "UFE",
            value: 0,
            effect: 0,
            selected: true,
          },
        ],
      },
      {
        key: "Energy Premiums",
        cssClass: "table-primary",
        selected: true,
        effect: 0.01,
        value: 0.25,
        childComponents: [
          {
            key: "Weather risk",
            value: 0.0,
            effect: 0.6,
            selected: true,
          },
          {
            key: "Shaping Premium",
            value: 0.25,
            effect: 0.4,
            selected: true,
          },
        ],
      },
      {
        key: "Capacity",
        cssClass: "table-primary",
        selected: true,
        effect: 0.1,
        value: -0.064,
        childComponents: [
          {
            key: "RUC Capacity Short",
            value: 0.005,
            effect: 0.6,
            selected: true,
          },
          {
            key: "RUC Clawback",
            value: -0.069,
            effect: 0.2,
            selected: true,
          },
          {
            key: "RUC Make Whole-Uplift",
            value: 0,
            effect: 0.1,
            selected: true,
          },
          {
            key: "DA - Make Whole",
            value: 0,
            effect: 0.1,
            selected: true,
          },
        ],
      },
      {
        key: "Ancillaries",
        cssClass: "table-primary",
        selected: true,
        effect: 0.04,
        value: 3.39753653837533,
        childComponents: [
          {
            key: "Regulation Up",
            value: 0.06,
            effect: 0.06,
            selected: true,
          },
          {
            key: "Regulation Down",
            value: 0.02,
            effect: 0.3,
            selected: true,
          },
          {
            key: "Responsive Reverse",
            value: 1.38,
            effect: 0.1,
            selected: true,
          },
          {
            key: "Non-Spin",
            value: 1.92,
            effect: 0.1,
            selected: true,
          },
          {
            key: "Black Start",
            value: 0.02,
            effect: 0.1,
            selected: true,
          },
        ],
      },
      {
        key: "ISO Fees, Admin + Other",
        cssClass: "table-primary",
        selected: true,
        effect: 0.03,
        value: 0.717179624600758,
        childComponents: [
          {
            key: "Admin Fee",
            value: 0.56,
            effect: 0.2,
            selected: true,
          },
          {
            key: "ERS",
            value: 0.01,
            effect: 0.1,
            selected: true,
          },
          {
            key: "Base Point Deviation",
            value: -0.02,
            effect: 0.1,
            selected: true,
          },
          {
            key: "Revenue Neutrality",
            value: 0.09,
            effect: 0.1,
            selected: true,
          },
          {
            key: "RMR",
            value: 0.0,
            effect: 0.1,
            selected: true,
          },
          {
            key: "Voltage Support",
            value: 0.0,
            effect: 0.1,
            selected: true,
          },
          {
            key: "LA Reliability AS Imbalance",
            value: 0.05,
            effect: 0.1,
            selected: true,
          },
          {
            key: "LA Ancillary Imbalance Revenue",
            value: -0.07,
            effect: 0.1,
            selected: true,
          },
          {
            key: "Fuel Supply Service",
            value: 0.1,
            effect: 0.1,
            selected: true,
          },
        ],
      },
      {
        key: "ERCOT Securitization charges",
        cssClass: "table-primary",
        selected: true,
        effect: 0.1,
        value: 0.25,
        childComponents: [
          {
            key: "ERCOT Securitization",
            value: 0.21,
            effect: 0.6,
            selected: true,
          },
          {
            key: "ERCOT Uplift Charge",
            value: 0.04,
            effect: 0.2,
            selected: true,
          },
        ],
      },
      {
        key: "CRR",
        cssClass: "table-primary",
        selected: true,
        effect: 0.02,
        value: -2.73,
        childComponents: [
          {
            key: "CRR",
            value: -2.73,
            effect: 1,
            selected: true,
          },
        ],
      },
      {
        key: "RPS",
        cssClass: "table-primary",
        selected: true,
        effect: 0.04,
        value: 3.4439407135493,
        childComponents: [
          {
            key: "RPS (Recs)",
            value: 3.44,
            effect: 1,
            selected: true,
          },
        ],
      },
    ],
  },
  {
    term: 24,
    value: 51.8253674379357,
    loads: [
      { key: "Retail", value: 18516 },
      { key: "Wholesale", value: 19691 },
    ],
    powers: [
      { key: "7x24 Block", value: 1.05 },
      { key: "5x16 Block", value: 1.23 },
      { key: "2x16 Block", value: 0.83 },
      { key: "7x8 Block", value: 0.94 },
    ],
    components: [
      {
        key: "Energy",
        cssClass: "table-primary",
        selected: true,
        effect: 0.6,
        value: 46.3,
        hideExpandRows: true,
        expandableComponents: [
          {
            key: "7x24",
            value: 1.05,
            effect: 0.1,
            selected: true,
          },
          {
            key: "5x16",
            value: 1.23,
            effect: 0.06,
            selected: true,
          },
          {
            key: "2x16",
            value: 0.83,
            effect: 0.03,
            selected: true,
          },
          {
            key: "7x8",
            value: 0.94,
            effect: 0.01,
            selected: true,
          },
        ],
        childComponents: [
          {
            key: "Zonal Price",
            value: 46.15,
            effect: 0.2,
            selected: true,
          },
          {
            key: "Fin-to-Phys Basis",
            value: 0.15,
            effect: 0.2,
            selected: true,
          },
        ],
      },
      {
        key: "Losses",
        cssClass: "table-primary",
        selected: true,
        effect: 0.06,
        value: 0.0,
        childComponents: [
          {
            key: "T & D",
            value: 0,
            effect: 0.6,
            selected: true,
          },
          {
            key: "UFE",
            value: 0,
            effect: 0,
            selected: true,
          },
        ],
      },
      {
        key: "Energy Premiums",
        cssClass: "table-primary",
        selected: true,
        effect: 0.01,
        value: 0.25,
        childComponents: [
          {
            key: "Weather risk",
            value: 0.0,
            effect: 0.6,
            selected: true,
          },
          {
            key: "Shaping Premium",
            value: 0.25,
            effect: 0.4,
            selected: true,
          },
        ],
      },
      {
        key: "Capacity",
        cssClass: "table-primary",
        selected: true,
        effect: 0.1,
        value: -0.064,
        childComponents: [
          {
            key: "RUC Capacity Short",
            value: 0.005,
            effect: 0.6,
            selected: true,
          },
          {
            key: "RUC Clawback",
            value: -0.069,
            effect: 0.2,
            selected: true,
          },
          {
            key: "RUC Make Whole-Uplift",
            value: 0,
            effect: 0.1,
            selected: true,
          },
          {
            key: "DA - Make Whole",
            value: 0,
            effect: 0.1,
            selected: true,
          },
        ],
      },
      {
        key: "Ancillaries",
        cssClass: "table-primary",
        selected: true,
        effect: 0.04,
        value: 3.17,
        childComponents: [
          {
            key: "Regulation Up",
            value: 0.05,
            effect: 0.06,
            selected: true,
          },
          {
            key: "Regulation Down",
            value: 0.02,
            effect: 0.3,
            selected: true,
          },
          {
            key: "Responsive Reverse",
            value: 1.27,
            effect: 0.1,
            selected: true,
          },
          {
            key: "Non-Spin",
            value: 1.81,
            effect: 0.1,
            selected: true,
          },
          {
            key: "Black Start",
            value: 0.02,
            effect: 0.1,
            selected: true,
          },
        ],
      },
      {
        key: "ISO Fees, Admin + Other",
        cssClass: "table-primary",
        selected: true,
        effect: 0.03,
        value: 0.717179624600758,
        childComponents: [
          {
            key: "Admin Fee",
            value: 0.56,
            effect: 0.2,
            selected: true,
          },
          {
            key: "ERS",
            value: 0.01,
            effect: 0.1,
            selected: true,
          },
          {
            key: "Base Point Deviation",
            value: -0.02,
            effect: 0.1,
            selected: true,
          },
          {
            key: "Revenue Neutrality",
            value: 0.09,
            effect: 0.1,
            selected: true,
          },
          {
            key: "RMR",
            value: 0.0,
            effect: 0.1,
            selected: true,
          },
          {
            key: "Voltage Support",
            value: 0.0,
            effect: 0.1,
            selected: true,
          },
          {
            key: "LA Reliability AS Imbalance",
            value: 0.05,
            effect: 0.1,
            selected: true,
          },
          {
            key: "LA Ancillary Imbalance Revenue",
            value: -0.07,
            effect: 0.1,
            selected: true,
          },
          {
            key: "Fuel Supply Service",
            value: 0.1,
            effect: 0.1,
            selected: true,
          },
        ],
      },
      {
        key: "ERCOT Securitization charges",
        cssClass: "table-primary",
        selected: true,
        effect: 0.1,
        value: 0.25,
        childComponents: [
          {
            key: "ERCOT Securitization",
            value: 0.21,
            effect: 0.6,
            selected: true,
          },
          {
            key: "ERCOT Uplift Charge",
            value: 0.04,
            effect: 0.2,
            selected: true,
          },
        ],
      },
      {
        key: "CRR",
        cssClass: "table-primary",
        selected: true,
        effect: 0.02,
        value: -2.73,
        childComponents: [
          {
            key: "CRR",
            value: -2.73,
            effect: 1,
            selected: true,
          },
        ],
      },
      {
        key: "RPS",
        cssClass: "table-primary",
        selected: true,
        effect: 0.04,
        value: 3.4439407135493,
        childComponents: [
          {
            key: "RPS (Recs)",
            value: 3.93,
            effect: 1,
            selected: true,
          },
        ],
      },
    ],
  },
  {
    term: 36,
    value: 51.9792049691411,
    loads: [
      { key: "Retail", value: 27784 },
      { key: "Wholesale", value: 29538 },
    ],
    powers: [
      { key: "7x24 Block", value: 1.06 },
      { key: "5x16 Block", value: 1.23 },
      { key: "2x16 Block", value: 0.83 },
      { key: "7x8 Block", value: 0.94 },
    ],
    components: [
      {
        key: "Energy",
        cssClass: "table-primary",
        selected: true,
        effect: 0.6,
        value: 46.29,
        hideExpandRows: true,
        expandableComponents: [
          {
            key: "7x24",
            value: 1.06,
            effect: 0.1,
            selected: true,
          },
          {
            key: "5x16",
            value: 1.23,
            effect: 0.06,
            selected: true,
          },
          {
            key: "2x16",
            value: 0.83,
            effect: 0.03,
            selected: true,
          },
          {
            key: "7x8",
            value: 0.94,
            effect: 0.01,
            selected: true,
          },
        ],
        childComponents: [
          {
            key: "Zonal Price",
            value: 46.14,
            effect: 0.2,
            selected: true,
          },
          {
            key: "Fin-to-Phys Basis",
            value: 0.15,
            effect: 0.2,
            selected: true,
          },
        ],
      },
      {
        key: "Losses",
        cssClass: "table-primary",
        selected: true,
        effect: 0.06,
        value: 0.0,
        childComponents: [
          {
            key: "T & D",
            value: 0,
            effect: 0.6,
            selected: true,
          },
          {
            key: "UFE",
            value: 0,
            effect: 0,
            selected: true,
          },
        ],
      },
      {
        key: "Energy Premiums",
        cssClass: "table-primary",
        selected: true,
        effect: 0.01,
        value: 0.25,
        childComponents: [
          {
            key: "Weather risk",
            value: 0.0,
            effect: 0.6,
            selected: true,
          },
          {
            key: "Shaping Premium",
            value: 0.25,
            effect: 0.4,
            selected: true,
          },
        ],
      },
      {
        key: "Capacity",
        cssClass: "table-primary",
        selected: true,
        effect: 0.1,
        value: -0.064,
        childComponents: [
          {
            key: "RUC Capacity Short",
            value: 0.005,
            effect: 0.6,
            selected: true,
          },
          {
            key: "RUC Clawback",
            value: -0.069,
            effect: 0.2,
            selected: true,
          },
          {
            key: "RUC Make Whole-Uplift",
            value: 0,
            effect: 0.1,
            selected: true,
          },
          {
            key: "DA - Make Whole",
            value: 0,
            effect: 0.1,
            selected: true,
          },
        ],
      },
      {
        key: "Ancillaries",
        cssClass: "table-primary",
        selected: true,
        effect: 0.04,
        value: 3.1,
        childComponents: [
          {
            key: "Regulation Up",
            value: 0.05,
            effect: 0.06,
            selected: true,
          },
          {
            key: "Regulation Down",
            value: 0.02,
            effect: 0.3,
            selected: true,
          },
          {
            key: "Responsive Reverse",
            value: 1.24,
            effect: 0.1,
            selected: true,
          },
          {
            key: "Non-Spin",
            value: 1.78,
            effect: 0.1,
            selected: true,
          },
          {
            key: "Black Start",
            value: 0.02,
            effect: 0.1,
            selected: true,
          },
        ],
      },
      {
        key: "ISO Fees, Admin + Other",
        cssClass: "table-primary",
        selected: true,
        effect: 0.03,
        value: 0.717179624600758,
        childComponents: [
          {
            key: "Admin Fee",
            value: 0.56,
            effect: 0.2,
            selected: true,
          },
          {
            key: "ERS",
            value: 0.01,
            effect: 0.1,
            selected: true,
          },
          {
            key: "Base Point Deviation",
            value: -0.02,
            effect: 0.1,
            selected: true,
          },
          {
            key: "Revenue Neutrality",
            value: 0.09,
            effect: 0.1,
            selected: true,
          },
          {
            key: "RMR",
            value: 0.0,
            effect: 0.1,
            selected: true,
          },
          {
            key: "Voltage Support",
            value: 0.0,
            effect: 0.1,
            selected: true,
          },
          {
            key: "LA Reliability AS Imbalance",
            value: 0.05,
            effect: 0.1,
            selected: true,
          },
          {
            key: "LA Ancillary Imbalance Revenue",
            value: -0.07,
            effect: 0.1,
            selected: true,
          },
          {
            key: "Fuel Supply Service",
            value: 0.1,
            effect: 0.1,
            selected: true,
          },
        ],
      },
      {
        key: "ERCOT Securitization charges",
        cssClass: "table-primary",
        selected: true,
        effect: 0.1,
        value: 0.25,
        childComponents: [
          {
            key: "ERCOT Securitization",
            value: 0.21,
            effect: 0.6,
            selected: true,
          },
          {
            key: "ERCOT Uplift Charge",
            value: 0.04,
            effect: 0.2,
            selected: true,
          },
        ],
      },
      {
        key: "CRR",
        cssClass: "table-primary",
        selected: true,
        effect: 0.02,
        value: -2.73,
        childComponents: [
          {
            key: "CRR",
            value: -2.73,
            effect: 1,
            selected: true,
          },
        ],
      },
      {
        key: "RPS",
        cssClass: "table-primary",
        selected: true,
        effect: 0.04,
        value: 4.17,
        childComponents: [
          {
            key: "RPS (Recs)",
            value: 4.17,
            effect: 1,
            selected: true,
          },
        ],
      },
    ],
  },
];
export const riscComponent = [
  "All",
  "Block",
  "Block Index",
  "Evergreen",
  "Fixed Price",
  "Heat Rate",
  "Heat Rate Block",
  "HRB Index",
  "Index RT",
  "Loss FPFR RTIND",
  "Loss Index Fix",
  "Unassigned",
];
export const item = ["All"];
export const products = [
  "All",
  "BI",
  "Evergreen Current",
  "FPFR",
  "HR",
  "HRB",
  "PCTF",
  "RTIND",
  "UNDEF",
];

export const blockType = [
  <FormGroup>
    <FormControlLabel control={<Checkbox defaultChecked />} label="all" />
    <FormControlLabel control={<Checkbox defaultChecked />} label="2x16" />
    <FormControlLabel control={<Checkbox />} label="5x16" />
    <FormControlLabel control={<Checkbox />} label="7x8" />
  </FormGroup>,
];
export const iso = [
  <FormGroup>
    <FormControlLabel control={<Checkbox defaultChecked />} label="all" />
    <FormControlLabel control={<Checkbox defaultChecked />} label="ERCOT" />
    <FormControlLabel control={<Checkbox defaultChecked />} label="ISONE" />
    <FormControlLabel control={<Checkbox defaultChecked />} label="MISO" />
    <FormControlLabel control={<Checkbox defaultChecked />} label="NYISO" />
    <FormControlLabel control={<Checkbox defaultChecked />} label="PJM" />
  </FormGroup>,
];
export const zone = ["All"];
export const productType = [
  <FormGroup>
    <FormControlLabel control={<Checkbox defaultChecked />} label="all" />
    <FormControlLabel
      control={<Checkbox defaultChecked />}
      label="block index"
    />
    <FormControlLabel control={<Checkbox />} label="Current Evergreen" />
    <FormControlLabel control={<Checkbox />} label="Fixed Price" />
    <FormControlLabel control={<Checkbox />} label="Future Evergreen" />
    <FormControlLabel control={<Checkbox />} label="Heat Rate" />
  </FormGroup>,
];
export const customerType = [
  <FormGroup>
    <FormControlLabel control={<Checkbox defaultChecked />} label="all" />
    <FormControlLabel
      control={<Checkbox defaultChecked />}
      label="Commercial"
    />
    <FormControlLabel control={<Checkbox />} label="Residential" />
  </FormGroup>,
];
export const years = [
  <FormGroup>
    <FormControlLabel control={<Checkbox defaultChecked />} label="all" />
    <FormControlLabel control={<Checkbox defaultChecked />} label="2022" />
    <FormControlLabel control={<Checkbox />} label="2023" />
    <FormControlLabel control={<Checkbox />} label="2024" />
    <FormControlLabel control={<Checkbox />} label="2025" />
  </FormGroup>,
];
export const batchDate = ["1/24/2023", "1/22/2023", "1/23/2023", "1/24/2023"];
export const risc = [
  <FormGroup>
    <FormControlLabel control={<Checkbox defaultChecked />} label="all" />
    <FormControlLabel control={<Checkbox defaultChecked />} label="BLOCK" />
    <FormControlLabel control={<Checkbox />} label="BLOCK INDEX" />
    <FormControlLabel control={<Checkbox />} label="EVERGREEN" />
    <FormControlLabel control={<Checkbox />} label="FIXED PRICE" />
  </FormGroup>,
];
export const MatrixServiceProfiles = [
  "ERCOT-TX-CNP-RES-HOUSTON-RESLOWR_COAST-RESLOWR_COAST-DEFAULT-D",
  "ERCOT-TX-CNP-RES-SOUTH-RESLOWR_SOUTH-RESLOWR_SOUTH-DEFAULT-D",
  "ERCOT-TX-CPL-RES-SOUTH-RESLOWR_SCENT-RESLOWR_SCENT-DEFAULT-A",
  "ERCOT-TX-ONC-RES-NORTH-RESLOWR_NCENT-RESLOWR_NCENT-DEFAULT-A",
  "ERCOT-TX-TNM-RES-HOUSTON-RESLOWR_COAST-RESLOWR_COAST-DEFAULT-D",
  "ERCOT-TX-TNM-RES-NORTH-RESLOWR_NCENT-RESLOWR_NCENT-DEFAULT-D",
  "ERCOT-TX-TNM-RES-WEST-RESLOWR_WEST-RESLOWR_WEST-DEFAULT-D",
  "ERCOT-TX-WTU-RES-WEST-RESLOWR_NORTH-RESLOWR_NORTH-DEFAULT-A",
  "ERCOT-TX-CNP-SCOM-HOUSTON-BUSMEDLF_COAST-BUSMEDLF_COAST-DEFAULT-D",
  "ERCOT-TX-CNP-SCOM-SOUTH-BUSMEDLF_SOUTH-BUSMEDLF_SOUTH-DEFAULT-D",
  "ERCOT-TX-CPL-SCOM-SOUTH-BUSMEDLF_SCENT-BUSMEDLF_SCENT-DEFAULT-A",
  "ERCOT-TX-ONC-SCOM-NORTH-BUSMEDLF_NCENT-BUSMEDLF_NCENT-DEFAULT-A",
  "ERCOT-TX-TNM-SCOM-HOUSTON-BUSMEDLF_COAST-BUSMEDLF_COAST-DEFAULT-D",
  "ERCOT-TX-TNM-SCOM-NORTH-BUSMEDLF_NCENT-BUSMEDLF_NCENT-DEFAULT-D",
  "ERCOT-TX-TNM-SCOM-WEST-BUSMEDLF_WEST-BUSMEDLF_WEST-DEFAULT-D",
  "ERCOT-TX-WTU-SCOM-WEST-BUSMEDLF_NORTH-BUSMEDLF_NORTH-DEFAULT-A",
  "ERCOT-TX-CNP-MCOM-HOUSTON-BUSHILF_COAST-BUSHILF_COAST-DEFAULT-D",
  "ERCOT-TX-CNP-MCOM-SOUTH-BUSHILF_SOUTH-BUSHILF_SOUTH-DEFAULT-D",
  "ERCOT-TX-CPL-MCOM-SOUTH-BUSHILF_SCENT-BUSHILF_SCENT-DEFAULT-A",
  "ERCOT-TX-ONC-MCOM-NORTH-BUSHILF_NCENT-BUSHILF_NCENT-DEFAULT-A",
  "ERCOT-TX-TNM-MCOM-HOUSTON-BUSHILF_COAST-BUSHILF_COAST-DEFAULT-D",
  "ERCOT-TX-TNM-MCOM-NORTH-BUSHILF_NCENT-BUSHILF_NCENT-DEFAULT-D",
  "ERCOT-TX-TNM-MCOM-WEST-BUSHILF_WEST-BUSHILF_WEST-DEFAULT-D",
  "ERCOT-TX-WTU-MCOM-WEST-BUSHILF_NORTH-BUSHILF_NORTH-DEFAULT-A",
];
