import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  active: "Data Management",
  submenuActive: "",
};

export const sideNavigationSlice = createSlice({
  name: "navigation/sidebar",
  initialState,
  reducers: {
    selectNavigation: (state, action) => {
      state.active = action.payload;
    },
    selectSubmenuNavigation: (state, action) => {
      state.submenuActive = action.payload;
    },
  },
});
export const { selectNavigation, selectSubmenuNavigation } =
  sideNavigationSlice.actions;

// Action creators are generated for each case reducer function
export default sideNavigationSlice.reducer;
