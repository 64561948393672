import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosForecastInstance as axios } from "axios.js";

const INITIAL_STATE = {
  selectedTab: 0,
  attrionData: { loading: false, data: [] },
  weatherData: {
    loader: false,
    filters: {
      temp_bands: [],
      markets: [],
      load_zones: [],
      product_types: [],
      scenario_years: [],
      max_date: null,
      min_date: null,
      customer_type: [],
    },
    yearChartData: {
      loader: false,
      hourly_load: [],
    },
    tempBandChartData: {
      loader: false,
      hourly_load: [],
    },
    churnData: {
      loader: false,
      hourly_load: [],
    },
  },
};

export const getWetherInitialDataAsync = createAsyncThunk(
  "forecasting/getWetherInitialDataAsync",
  async (data) => {
    const response = await axios.get("/api/v1/getWeatherScenarioData", data);
    return response;
  }
);

export const getChurnInitialDataAsync = createAsyncThunk(
  "forecasting/getChurnScenarioData",
  async (data) => {
    const response = await axios.get("/api/v1/getChurnScenarioData", data);
    return response;
  }
);

export const getTemparatureBandInitialDataAsync = createAsyncThunk(
  "forecasting/getTemparatureBandScenarioData",
  async (data) => {
    const response = await axios.get("/api/v1/getTempBandData", data);
    return response;
  }
);
export const getAttritionPercentageData = createAsyncThunk(
  "forecasting/getAttritionData",
  async (data) => {
    const response = await axios.get("/api/v1/get_attrition");
    return response;
  }
);

export const createAttritionPercentageData = createAsyncThunk(
  "forecasting/createAttritionData",
  async (data) => {
    const response = await axios.post("/api/v1/upsert_attrition", data);
    return response;
  }
);
export const getFilteredChurnData = createAsyncThunk(
  "forecasting/getFilteredChurnData",
  async (data) => {
    const response = await axios.post(`/api/v1/getFilteredChurnData`, data);
    return response;
  }
);

export const getFilterTempBandData = createAsyncThunk(
  "forecasting/getFilterTempBandData",
  async (data) => {
    const response = await axios.post(`/api/v1/getFilteredTempbandData`, data);
    return response;
  }
);

export const getFilterScenarioYearData = createAsyncThunk(
  "forecasting/getFilterScenarioYearData",
  async (data) => {
    const response = await axios.post(
      `/api/v1/getFilteredWeatherScenarioData`,
      data
    );
    return response;
  }
);

export const wetherScenarioSlice = createSlice({
  name: "forecasting/wetherScenario",
  initialState: INITIAL_STATE,
  reducers: {
    changeTab: (state, action) => {
      state.selectedTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWetherInitialDataAsync.pending, (state) => {
        state.weatherData.loader = true;
        state.weatherData.yearChartData.loader = true;
        state.weatherData.tempBandChartData.loader = true;
      })
      .addCase(getWetherInitialDataAsync.fulfilled, (state, action) => {
        state.weatherData.loader = false;
        state.weatherData.yearChartData.loader = false;
        state.weatherData.tempBandChartData.loader = false;
        state.weatherData.filters = {
          temp_bands: action?.payload?.data?.temp_bands || [],
          product_types: action?.payload?.data?.product_types || [],
          scenario_years: action?.payload?.data?.scenario_years || [],
          load_zones: action?.payload?.data?.load_zones || [],
          markets: action?.payload?.data?.markets || [],
          min_date: action?.payload?.data?.min_date,
          max_date: action?.payload?.data?.max_date,
        };
        state.weatherData.yearChartData.hourly_load =
          action?.payload?.data?.hourly_load || [];
        state.weatherData.tempBandChartData.hourly_load =
          action?.payload?.data?.hourly_load || [];
      })
      .addCase(getWetherInitialDataAsync.rejected, (state) => {
        state.weatherData.loader = false;
        state.weatherData.yearChartData.loader = false;
        state.weatherData.tempratureChartData.loader = false;
      })
      .addCase(getFilterScenarioYearData.pending, (state) => {
        state.weatherData.yearChartData.loader = true;
      })
      .addCase(getFilterScenarioYearData.fulfilled, (state, action) => {
        state.weatherData.yearChartData.loader = false;
        state.weatherData.yearChartData.hourly_load =
          action?.payload?.data?.hourly_load || [];
      })
      .addCase(getFilterScenarioYearData.rejected, (state) => {
        state.weatherData.yearChartData.loader = false;
      })
      .addCase(getFilterTempBandData.pending, (state) => {
        state.weatherData.tempBandChartData.loader = true;
      })
      .addCase(getFilterTempBandData.fulfilled, (state, action) => {
        state.weatherData.tempBandChartData.loader = false;
        state.weatherData.tempBandChartData.hourly_load =
          action?.payload?.data?.hourly_load || [];
      })
      .addCase(getFilterTempBandData.rejected, (state) => {
        state.weatherData.tempBandChartData.loader = false;
      })
      .addCase(getChurnInitialDataAsync.pending, (state) => {
        state.weatherData.churnData.loader = true;
      })
      .addCase(getChurnInitialDataAsync.fulfilled, (state, action) => {
        state.weatherData.churnData.loader = false;
        state.weatherData.filters.customer_type =
          action?.payload?.data?.customer_types || [];
        state.weatherData.churnData.hourly_load =
          action?.payload?.data?.hourly_load || [];
      })
      .addCase(getChurnInitialDataAsync.rejected, (state) => {
        state.weatherData.churnData.loader = false;
      })
      .addCase(getTemparatureBandInitialDataAsync.pending, (state) => {
        state.weatherData.tempBandChartData.loader = true;
      })
      .addCase(
        getTemparatureBandInitialDataAsync.fulfilled,
        (state, action) => {
          state.weatherData.tempBandChartData.loader = false;
          state.weatherData.filters.customer_type =
            action?.payload?.data?.customer_types || [];
          state.weatherData.tempBandChartData.hourly_load =
            action?.payload?.data?.hourly_load || [];
        }
      )
      .addCase(getTemparatureBandInitialDataAsync.rejected, (state) => {
        state.weatherData.tempBandChartData.loader = false;
      })
      .addCase(getFilteredChurnData.pending, (state) => {
        state.weatherData.churnData.loader = true;
      })
      .addCase(getFilteredChurnData.fulfilled, (state, action) => {
        state.weatherData.churnData.loader = false;
        state.weatherData.churnData.hourly_load =
          action?.payload?.data?.hourly_load || [];
      })
      .addCase(getFilteredChurnData.rejected, (state) => {
        state.weatherData.churnData.loader = false;
      })
      .addCase(getAttritionPercentageData.pending, (state) => {
        state.attrionData.loader = true;
      })
      .addCase(getAttritionPercentageData.fulfilled, (state, action) => {
        state.attrionData.loader = false;
        state.attrionData.data = action?.payload?.data?.attrition_info || [];
      })
      .addCase(getAttritionPercentageData.rejected, (state) => {
        state.attrionData.loader = false;
      });
  },
});
export const { changeTab } = wetherScenarioSlice.actions;
export default wetherScenarioSlice.reducer;
