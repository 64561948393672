import userIcon from "../../../assets/images/undraw_profile.svg";
import logo from "../../../assets/images/Firefly.png";
import CustomTextField from "../../../components/customComponents/CustomTextField";
import useAuth from "app/hooks/useAuth";
import { useEffect, useState } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { navigations } from "features/config/routes/navigations";
import { useSelector, useDispatch } from "react-redux";
import CustomDialog from "features/components/customComponents/CustomDialog";
import SearchCustomer from "features/view/admin/Pricing/quote/components/Customer/SearchCustomer";
import {
  selectNavigation,
  selectSubmenuNavigation,
} from "app/redux/slices/navigation/sideNavigationSlice";

const Topbar = () => {
  const location = useLocation();

  let { isAuthenticated, logout, user, previleges } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const sideNavigationState = useSelector((state) => state.sideNavigation);
  const submenuActive = useSelector((state) => state.sideNavigation);
  const dispatch = useDispatch();
  const [dialogType, setDialogType] = useState("");

  const OpenDialog = (e, item) => {
    setDialogType(e);
  };
  const handleCloseButton = () => {
    setDialogType("");
  };
  let activeClassName = "active";
  let disabledClassName = "disabledNavBarLink";
  useEffect(() => {
    const findAllActiveTabName = (
      sideNavigationState?.active?.children || []
    ).filter((route) => {
      return window.location.href.includes(route.path);
    });
    const activeTab =
      findAllActiveTabName.length > 0
        ? findAllActiveTabName[findAllActiveTabName.length - 1]
        : (sideNavigationState?.active?.children || [])[0];

    dispatch(
      selectSubmenuNavigation({
        name: activeTab?.name || "",
      })
    );
  }, []);
  const handleLogout = async (values) => {
    setLoading(true);
    try {
      await logout();
      navigate(`${process.env.PUBLIC_URL}/session/signin`);
    } catch (e) {
      setLoading(false);
    }
  };

  const getSubChildMenu = (items, activeName, subActive) => {
    for (let item of items) {
      if (item.name === activeName && item.children) {
        for (let child of item.children) {
          if (child.name === subActive) {
            return child.child;
          }
        }
      }
    }
    return null;
  };
  const renderSubmenu = (items) => {
    const subMenus = getSubChildMenu(
      items,
      sideNavigationState?.active.name,
      submenuActive.submenuActive.name
    );
    dispatch(selectNavigation(sideNavigationState?.active));

    if (!subMenus?.length) return null;

    return subMenus.map((item, index) => {
      return (
        <>
          {" "}
          <NavLink
            onClick={(e) => {
              if (
                item.disable ||
                (item.privilege && !previleges?.includes(item.privilege))
              ) {
                e.preventDefault();
              }
            }}
            key={item.name}
            end
            className={({ isActive }) => {
              if (item.disable) {
                return disabledClassName;
              } else if (isActive) {
                return activeClassName;
              } else {
                return undefined;
              }
            }}
            to={`${process.env.PUBLIC_URL}/${item.path}`}
          >
            <span className="tabspan">{item.name}</span>
          </NavLink>
        </>
      );
    });
  };
  const renderNavigation = (items) => {
    let childLinks =
      items
        ?.filter((obj) => previleges?.includes(obj?.feature))
        ?.includes(
          items?.find((obj) => obj?.name === sideNavigationState?.active.name)
        ) ||
      items?.find((obj) => obj?.name === sideNavigationState?.active.name)
        ?.feature === true
        ? items?.find((obj) => obj?.name === sideNavigationState?.active.name)
            ?.children || []
        : [];
    if (
      childLinks[0]?.disable ||
      (childLinks[0]?.privilege &&
        !previleges?.includes(childLinks[0]?.privilege))
    ) {
      let activeTab = childLinks.find((tab) => {
        return (
          !tab.disable &&
          (!tab?.privilege || previleges?.includes(tab?.privilege))
        );
      });

      if (!location.pathname.includes(`${activeTab.path}`)) {
        navigate(`${activeTab?.path}`);
      }
    }
    return childLinks.map((item, index) => {
      return (
        <>
          <NavLink
            id={`nav${index}`}
            onClick={(e) => {
              if (
                item.disable ||
                (item.privilege && !previleges?.includes(item.privilege))
              ) {
                e.preventDefault();
              }
            }}
            key={item.name}
            end
            className={({ isActive }) => {
              if (submenuActive?.submenuActive?.name === item?.name) {
                return activeClassName;
              } else if (
                item.disable ||
                (item.privilege && !previleges?.includes(item.privilege))
              ) {
                return disabledClassName;
              } else if (isActive) {
                dispatch(selectSubmenuNavigation(item));
                return activeClassName;
              } else {
                return undefined;
              }
            }}
            to={`${process.env.PUBLIC_URL}/${item.path}`}
          >
            <span>{item.name}</span>
          </NavLink>
        </>
      );
    });
  };
  return (
    <>
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <nav className="navbar navbar-expand navbar-light bg-primary topbar mb-4 fixed-top">
            <a className="sidebar-brand d-flex align-items-center " href="#">
              <img
                src={logo}
                className="img-fluid "
                alt="Firefly"
                width="132px"
              />
            </a>
            <button
              id="sidebarToggleTop"
              className="btn btn-link d-md-none rounded-circle mr-3"
            >
              <i className="fa fa-bars"></i>
            </button>
            <div className="scrollmenu">{renderNavigation(navigations)}</div>{" "}
            {/* <form className="d-none d-sm-inline-block form-inline navbar-search">
              <div className="input-group">
                <CustomTextField
                  name="search"
                  label="Search"
                  variant="outlined"
                  size="small"
                  fullWidth
                  margin="normal"
                />
              </div>
            </form> */}{" "}
            <ul className="navbar-nav ml-auto">
              <li className="nav-item dropdown no-arrow d-sm-none">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="searchDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fas fa-search fa-fw"></i>
                </a>

                <div
                  className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
                  aria-labelledby="searchDropdown"
                >
                  <form className="form-inline mr-auto w-100 navbar-search">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control bg-light border-0 small"
                        placeholder="Search for..."
                        aria-label="Search"
                        aria-describedby="basic-addon2"
                      />

                      <div className="input-group-append">
                        <button className="btn btn-primary" type="button">
                          <i className="fas fa-search fa-sm"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </li>

              <li className="nav-item dropdown no-arrow">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="userDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img className="img-profile rounded-circle" src={userIcon} />
                </a>
                <div
                  className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                  aria-labelledby="userDropdown"
                >
                  <a className="dropdown-item" href="#">
                    <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                    {user["user-name"]}
                  </a>
                  <a className="dropdown-item" href="#">
                    <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                    {user["user-role"]}
                  </a>
                  <div className="dropdown-divider"></div>
                  <a
                    className="dropdown-item"
                    href="#"
                    data-toggle="modal"
                    data-target="#logoutModal"
                    onClick={handleLogout}
                  >
                    <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                    Logout
                  </a>
                </div>
              </li>
            </ul>{" "}
          </nav>{" "}
          <span
            style={{
              display: renderSubmenu(navigations) ? "" : "none",
            }}
          >
            <div id="content-wrapper" className="d-flex flex-column">
              <div className="container-fluid posi-cont">
                <div className="row">
                  <div className="col-md-12" style={{ marginTop: 10 }}>
                    <div className="card shadow ">
                      {" "}
                      <div
                        // style={{ marginTop: 100 }}
                        className="col-md-12 scrollmenusub "
                      >
                        {renderSubmenu(navigations)}
                      </div>
                      {/* <Tabs
                      variant="fullWidth"
                      // value={value}
                      // onChange={(e) => tabSelect(item, e)}
                      aria-label="position-details"
                      selectionFollowsFocus
                      centered
                    > */}
                      {/* {tabNames?.map((item, i) => {
                        return (
                          <Tab
                            style={{ textTransform: "capitalize" }}
                            label={item.name}
                            {...a11yProps(i)}
                            onClick={(e) => tabSelect(item, e)}
                          />
                        );
                      })} */}
                      {/* </Tabs> */}
                    </div>
                  </div>{" "}
                </div>
              </div>{" "}
            </div>
          </span>
          {/* <div style={{ marginTop: 100 }} className="col-md-12 scrollmenu ">
            {renderSubmenu(navigations)}
          </div>{" "} */}
        </div>
        <CustomDialog
          openDialog={dialogType === "SearchDialog"}
          dialogTitle="Search Customer"
          handleCloseButton={handleCloseButton}
          component={<SearchCustomer handleCloseButton={handleCloseButton} />}
        ></CustomDialog>{" "}
      </div>
    </>
  );
};
export default Topbar;
