import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import axios from "axios.js";

const initialState = {
  customerAccount: {
    commodityType: "",
    utility: "",
    utilityAccount: "",
    profile: "",
    estimatedStartDate: dayjs().date(1),
  },
};
export const postAddAccountCustomerAsync = createAsyncThunk(
  "customer/add/account",
  async (body) => {
    const response = await axios.post(
      `/api/v1/customer/add_commodity_service`,
      body
    );
    return response;
  }
);
export const updateAccountCustomerAsync = createAsyncThunk(
  "customer/update/account",
  async ({ body }) => {
    const response = await axios.put(
      `/api/v1/customer/update_commodity_service`,
      body
    );
    return response;
  }
);
export const deleteAccountCustomerAsync = createAsyncThunk(
  "customer/delete/account",
  async ({ clientCode, commodityServiceId }) => {
    const response = await axios.delete(
      `/api/v1/customer/delete_commodity_service?clientCode=${clientCode}&commodityServiceId=${commodityServiceId}`
    );
    return response;
  }
);

export const customerAddAccountSlice = createSlice({
  name: "customer/add",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postAddAccountCustomerAsync.pending, (state) => {})
      .addCase(postAddAccountCustomerAsync.fulfilled, (state, action) => {
        state.customerAccount = {} || {};
      })
      .addCase(postAddAccountCustomerAsync.rejected, (state, action) => {
        state.customerAccount = action.payload;
      });
  },
});

export default customerAddAccountSlice.reducer;
