import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosForecastInstance as axios } from "axios.js";

const INITIAL_STATE = {
  numberOfResidentialFilterData: {
    loader: false,
    dropDownListItems: {
      utilities: [],
      customer_types: [],
      markets: [],
    },
  },
  utility_zones_by_market: {},
  analyticsNoOfResidentialChartData: {
    loader: false,
    dataToPlot: [],
  },
};

export const getNumberOfResidentialInitialDataAsync = createAsyncThunk(
  "analytics/NumberOfResidentialsInitialData",
  async () => {
    const response = await axios.get(
      "/api/v1/get_meter_count_by_customer_type"
    );
    return response;
  }
);

export const getUpdatedNumberOfResidentialDataAsync = createAsyncThunk(
  "forecasting/getLongTermUpdatedData",
  async (data) => {
    const response = await axios.post(
      "/api/v1/get_filtered_meter_count_by_customer_type",
      data
    );
    return response;
  }
);

export const numberOfResidentialSlice = createSlice({
  name: "forecasting/longterm",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNumberOfResidentialInitialDataAsync.pending, (state) => {
        state.numberOfResidentialFilterData.loader = true;
        state.analyticsNoOfResidentialChartData.loader = true;
      })
      .addCase(
        getNumberOfResidentialInitialDataAsync.fulfilled,
        (state, action) => {
          state.numberOfResidentialFilterData.loader = false;
          state.analyticsNoOfResidentialChartData.loader = false;

          state.numberOfResidentialFilterData.dropDownListItems = {
            customer_types: action?.payload?.data?.customer_types || [],

            markets: action?.payload?.data?.markets || [],
            utilities: action?.payload?.data?.utilities || [],
          };
          state.utility_zones_by_market =
            action?.payload?.data?.utility_zones_by_market || {};

          state.analyticsNoOfResidentialChartData.dataToPlot =
            action?.payload?.data?.meter_count_by_customer_type || [];
        }
      )
      .addCase(getNumberOfResidentialInitialDataAsync.rejected, (state) => {
        state.numberOfResidentialFilterData.loader = false;
        state.analyticsNoOfResidentialChartData.loader = false;
      })
      .addCase(getUpdatedNumberOfResidentialDataAsync.pending, (state) => {
        state.analyticsNoOfResidentialChartData.loader = true;
      })
      .addCase(
        getUpdatedNumberOfResidentialDataAsync.fulfilled,
        (state, action) => {
          state.analyticsNoOfResidentialChartData.loader = false;
          state.analyticsNoOfResidentialChartData.dataToPlot =
            action?.payload?.data?.meter_count_by_customer_type?.length > 0
              ? action?.payload?.data?.meter_count_by_customer_type
              : state.analyticsNoOfResidentialChartData.dataToPlot;
        }
      )
      .addCase(getUpdatedNumberOfResidentialDataAsync.rejected, (state) => {
        state.analyticsNoOfResidentialChartData.loader = false;
      });
  },
});

export default numberOfResidentialSlice.reducer;
