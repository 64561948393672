import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios.js";

const initialState = {
  componentGroups: { list: [], loader: false },
  newComponent: { loader: false },
};

export const getComponentGroupListAsync = createAsyncThunk(
  "pricing/config/getComponent_groups",
  () => {
    const response = axios
      .get(`/api/v1/pricingmodel/get/component_groups`)
      .then((res) => {
        return res;
      });
    return response;
  }
);
export const updateFormulaAsync = createAsyncThunk(
  "pricing/config/formula/update",
  (body) => {
    const response = axios
      .post(`/api/v1/pricingmodel/component_group/update_formula`, body)
      .then((res) => {
        return res;
      });
    return response;
  }
);

export const postNewComponentCreationAsync = createAsyncThunk(
  "pricing/config/post/component/add",
  (requestBody) => {
    const response = axios
      .post(`/api/v1/pricingmodel/add/component`, requestBody)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  }
);

export const pricingConfigNewComponentSlice = createSlice({
  name: "pricing/config/component/new",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getComponentGroupListAsync.pending, (state) => {
        state.componentGroups.loader = true;
      })
      .addCase(getComponentGroupListAsync.fulfilled, (state, action) => {
        state.componentGroups.loader = false;

        state.componentGroups.list = action?.payload?.data?.data || [];
      })
      .addCase(getComponentGroupListAsync.rejected, (state, action) => {
        state.componentGroups.loader = false;
        state.componentGroups.list = [];
      })
      .addCase(postNewComponentCreationAsync.pending, (state) => {
        state.newComponent.loader = true;
      })
      .addCase(postNewComponentCreationAsync.fulfilled, (state, action) => {
        state.newComponent.loader = false;
      })
      .addCase(postNewComponentCreationAsync.rejected, (state, action) => {
        state.newComponent.loader = false;
      })
      .addCase(updateFormulaAsync.pending, (state) => {})
      .addCase(updateFormulaAsync.fulfilled, (state, action) => {})
      .addCase(updateFormulaAsync.rejected, (state, action) => {});
  },
});

// Action creators are generated for each case reducer function
export default pricingConfigNewComponentSlice.reducer;
