import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosForecastInstance as axios } from "axios.js";

const INITIAL_STATE = {
  accountsData: {
    loader: false,
    data: {},
    dataByProduct: {}
  },
};

export const getdashboardAccountsDataAsync = createAsyncThunk(
  "forecasting/getdashboardAccountsData",
  async (data) => {
    const response = await axios.get(
      `/api/v1/get_meter_count/${data.market}/${data.zone}`
    );
    return response;
  }
);

export const dashboardAccountsSlice = createSlice({
  name: "forecasting/dashboard/accounts",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getdashboardAccountsDataAsync.pending,
        (state) => {
          state.accountsData.loader = true;
        }
      )
      .addCase(
        getdashboardAccountsDataAsync.fulfilled,
        (state, action) => {
          state.accountsData.loader = false;
          state.accountsData.data[`${action.meta.arg.market}-${action.meta.arg.zone}`] =
            action?.payload?.data?.data || [];
          state.accountsData.dataByProduct[`${action.meta.arg.market}-${action.meta.arg.zone}`] =
            action?.payload?.data?.meter_count_by_iso_and_product || [];
        }
      )
      .addCase(
        getdashboardAccountsDataAsync.rejected,
        (state) => {
          state.accountsData.loader = false;
        }
      )
  },
});

export default dashboardAccountsSlice.reducer;
