import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosForecastInstance as axios } from "axios.js";

const INITIAL_STATE = {
  dataToPlot: {
    loading: false,
    annuaLoadDetails: [],
  },
  dropDownFilterData: {
    loading: false,
    dropDownListItems: {
      customerTypes: [],
      markets: [],
      utilities: [],
    },
  },
};

const TOKEN = localStorage.getItem("accessToken");
const HEADER = { headers: { Authorization: `Bearer ${TOKEN}` } };

export const getAnnualVolumeGraphSliceAsync = createAsyncThunk(
  "analytics/annualvolumeInitialData",
  async (body) => {
    const response = await axios.get("/api/v1/get_annual_load", HEADER);
    return response;
  }
);
export const getUpdatedAnnualVolumeGraphSliceAsync = createAsyncThunk(
  "analytics/annualvolumeFilteredData",
  async (body) => {
    const response = await axios.get(
      "/api/v1/get_filtered_annual_load",
      HEADER
    );
    return response;
  }
);

export const annualVolumeSlice = createSlice({
  name: "analytics/annualvolume",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAnnualVolumeGraphSliceAsync.pending, (state) => {
        state.dataToPlot.loading = true;
        state.dropDownFilterData.loading = true;
      })
      .addCase(getAnnualVolumeGraphSliceAsync.fulfilled, (state, action) => {
        state.dataToPlot.loading = false;
        state.dropDownFilterData.loading = false;

        state.dataToPlot.annuaLoadDetails = action.payload.data
          ? action.payload.data
          : {};
        state.dropDownFilterData.dropDownListItems.customerTypes = action
        .payload.data.customerTypes
        ? action.payload.data.customerTypes
        : [];
        state.dropDownFilterData.dropDownListItems.markets = action
        .payload.data.markets
        ? action.payload.data.markets
        : [];
        state.dropDownFilterData.dropDownListItems.utilities = action
        .payload.data.utilities
        ? action.payload.data.utilities
        : [];

      })
      .addCase(getAnnualVolumeGraphSliceAsync.rejected, (state, action) => {
        state.dropDownFilterData.loading = false;
        state.dataToPlot.loading = false;
      });
  },
});

// Action creators are generated for each case reducer function
export default annualVolumeSlice.reducer;
