import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axiosForProduct, { axiosConfigManagement } from "axios.js";

const initialState = {
  configDomains: { configDomainData: [], loader: false },
  configDomainParams: { params: {} },
  addNewConfigDomain: { loader: false },
  product: { list: [], loader: false },
};

export const getProductListForConfigDomainAsync = createAsyncThunk(
  "admin/configDomain/getProductListAsync",
  async (client_code) => {
    const response = axiosForProduct
      .get(`/api/v1/product/get/all?client_code=${client_code}`)
      .then((res) => {
        return res;
      });
    return response;
  }
);

export const getConfigDomainAsync = createAsyncThunk(
  "admin/configDomain/getCongigDomaineList",
  async () => {
    const response = await axiosConfigManagement.get(
      `/api/v1/configuration/config-domain/get`
    );
    return response;
  }
);

export const postAddConfigDomainData = createAsyncThunk(
  "admin/configDomain/add",
  async (body) => {
    const response = await axiosConfigManagement.post(
      `/api/v1/configuration/config-domain/add`,
      body?.payload
    );
    return response;
  }
);
export const getConfigDomainParamsAsync = createAsyncThunk(
  "admin/configDoamain/congigDomainParam",
  async (data) => {
    const { param_name } = data;
    const response = await axiosConfigManagement.get(
      `/api/v1/configuration/${param_name}/get`
    );
    return { response, param_name };
  }
);
export const deleteConfigDomainAsync = createAsyncThunk(
  "admin/delete/configDomain",
  async ({ clientCode, wholesale_trade_uuid }) => {
    const response = await axiosConfigManagement.delete(
      `/api/v1/trade-capture/${clientCode}/delete?identifier=${wholesale_trade_uuid}`
    );
    return response;
  }
);
export const postEditConfigDomainData = createAsyncThunk(
  "admin/configDomain/edit",
  async (body) => {
    const response = await axiosConfigManagement.put(
      `/api/v1/configuration/config-domain/update`,
      body?.payload
    );
    return response;
  }
);
export const configDomainSlice = createSlice({
  name: "admin/configDomain",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getConfigDomainAsync.pending, (state) => {
        state.configDomains.loader = true;
      })
      .addCase(getConfigDomainAsync.fulfilled, (state, action) => {
        state.configDomains.loader = false;
        state.configDomains.configDomainData = action?.payload?.data?.data;
      })
      .addCase(getConfigDomainAsync.rejected, (state) => {
        state.configDomains.loader = false;
        state.configDomains.configDomainData = [];
      })
      .addCase(postEditConfigDomainData.pending, (state) => {
        state.addNewConfigDomain.loader = true;
      })
      .addCase(postEditConfigDomainData.fulfilled, (state, action) => {
        state.addNewConfigDomain.loader = false;
        //state.tardeCaptureParams.list = action?.payload?.data?.data;
      })
      .addCase(postEditConfigDomainData.rejected, (state) => {
        state.addNewConfigDomain.loader = false;
      })
      .addCase(getProductListForConfigDomainAsync.pending, (state) => {
        state.product.loader = true;
      })
      .addCase(
        getProductListForConfigDomainAsync.fulfilled,
        (state, action) => {
          state.product.loader = false;
          state.product.list = action?.payload?.data?.data || [];
        }
      )
      .addCase(getProductListForConfigDomainAsync.rejected, (state, action) => {
        state.product.loader = false;
        state.product.list = [];
      })
      .addCase(getConfigDomainParamsAsync.fulfilled, (state, action) => {
        state.configDomainParams.params = {
          ...state.configDomainParams.params,
          [action?.payload?.param_name]: action?.payload?.response?.data?.data,
        };
      })
      .addCase(getConfigDomainParamsAsync.rejected, (state) => {
        state.configDomainParams.params = {};
      });
  },
});

export default configDomainSlice.reducer;
