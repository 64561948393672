import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios.js";

const initialState = {
  data: { list: [], loader: false },
  accounts: { list: [], loader: false },
  contract: { list: [], loader: false },
};

export const getRunsDataAsync = createAsyncThunk(
  "pricing/run/status/data",
  ({ clientCode, requestData }) => {
    const response = axios
      .post(`api/v1/pricing/run/data?client_code=${clientCode}`, requestData)
      .then((res) => {
        return res;
      });
    return response;
  }
);
export const getAccountsAsync = createAsyncThunk(
  "pricing/run/status/accounts",
  (sessionId) => {
    const response = axios
      .get(`api/v1/pricing/run/accounts?sessionId=${sessionId}`)
      .then((res) => {
        return res;
      });
    return response;
  }
);
export const getContractAsync = createAsyncThunk(
  "pricing/run/status/contract",
  (sessionId) => {
    const response = axios
      .get(
        `api/v1/pricing/run/result/term_vs_price_chart?sessionId=${sessionId}`
      )
      .then((res) => {
        return res;
      });
    return response;
  }
);
export const postContractStatusUpdateAsync = createAsyncThunk(
  "pricing/run/status/contract/complete",
  (sessionId) => {
    const response = axios
      .post(`/api/v1/pricing/contract/complete?sessionId=${sessionId}`)
      .then((res) => {
        return res;
      });
    return response;
  }
);
export const getRerunCalcAsync = createAsyncThunk(
  "pricing/run/status/reRun",
  (data) => {
    const response = axios
      .post(`/api/v1/pricing/rerun?sessionId=${data?.sessionId}`)
      .then((res) => {
        return res;
      });
    return response;
  }
);

export const pricingRunsDataSlice = createSlice({
  name: "pricing/run/datalist",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRunsDataAsync.pending, (state) => {
        state.data.loader = true;
      })
      .addCase(getRunsDataAsync.fulfilled, (state, action) => {
        state.data.loader = false;
        state.data.list = action?.payload?.data?.data || [];
      })
      .addCase(getRunsDataAsync.rejected, (state, action) => {
        state.data.loader = false;
        state.data.list = [];
      })
      .addCase(getAccountsAsync.pending, (state) => {
        state.accounts.loader = true;
      })
      .addCase(getAccountsAsync.fulfilled, (state, action) => {
        state.accounts.loader = false;
        state.accounts.list = action?.payload?.data?.data || [];
      })
      .addCase(getAccountsAsync.rejected, (state, action) => {
        state.accounts.loader = false;
        state.accounts.list = [];
      })
      .addCase(getContractAsync.pending, (state) => {
        state.contract.loader = true;
      })
      .addCase(getContractAsync.fulfilled, (state, action) => {
        state.contract.loader = false;
        state.contract.list = action?.payload?.data?.data || [];
      })
      .addCase(getContractAsync.rejected, (state, action) => {
        state.contract.loader = false;
        state.contract.list = [];
      })
      .addCase(postContractStatusUpdateAsync.pending, (state) => {
        // state.contract.loader = true;
      })
      .addCase(postContractStatusUpdateAsync.fulfilled, (state, action) => {
        // state.contract.loader = false;
        // state.contract.list = action?.payload?.data?.data || [];
      })
      .addCase(postContractStatusUpdateAsync.rejected, (state, action) => {
        // state.contract.loader = false;
        // state.contract.list = [];
      })
      .addCase(getRerunCalcAsync.pending, (state) => {})
      .addCase(getRerunCalcAsync.fulfilled, (state, action) => {})
      .addCase(getRerunCalcAsync.rejected, (state, action) => {});
  },
});
// Action creators are generated for each case reducer function
export default pricingRunsDataSlice.reducer;
