import { Routes, Route, Navigate } from "react-router-dom";
import AuthGuard from "../../../app/auth/AuthGuard";
import AppLayout from "../../layouts/AppLayout";
import { lazy } from "react";
import Loadable from "../../components/customComponents/Loadable";

//const Admin = Loadable(lazy(() => import("../../view/admin/Admin")));
const ConfigDomain = Loadable(
  lazy(() =>
    import("../../view/admin/Admin/components/ConfigMangement/configDomain")
  )
);
const ConfigElement = Loadable(
  lazy(() =>
    import("../../view/admin/Admin/components/ConfigMangement/configElement")
  )
);
const ConfigAttribute = Loadable(
  lazy(() =>
    import("../../view/admin/Admin/components/ConfigMangement/configAttribute")
  )
);
const MatrixPricing = Loadable(
  lazy(() => import("../../view/admin/Pricing/quote/metrixIndex"))
);
const PriceCurve = Loadable(
  lazy(() => import("../../view/admin/Pricing/downloads/PriceCurve"))
);

const Reports = Loadable(lazy(() => import("../../view/admin/Reports")));
const TradeCapture = Loadable(
  lazy(() => import("../../view/admin/Risk/components/TradeCapture"))
);
const AssetValuationPage = Loadable(
  lazy(() => import("../../view/admin/AssetValuation"))
);

const MarkToMarketPage = Loadable(
  lazy(() => import("../../view/admin/AssetValuation/components/MarkToMarket"))
);

const ForecastingReportPage = Loadable(
  lazy(() => import("../../view/admin/Forecasting/reportsPage"))
);
const ForecastingDashboard = Loadable(
  lazy(() => import("../../view/admin/Forecasting/dashBoardPage"))
);
const AnalaticsPage = Loadable(
  lazy(() => import("../../view/admin/Analatics"))
);

const RiskReportPage = Loadable(lazy(() => import("../../view/admin/Risk")));
const ExistingCustomerPage = Loadable(
  lazy(() => import("../../view/admin/customer/ExistingCustomerManagement"))
);
const AddComponentPage = Loadable(
  lazy(() =>
    import("../../view/admin/Pricing/configuration/components/AddComponent")
  )
);
const PricingPage = Loadable(
  lazy(() => import("../../view/admin/Pricing/quote"))
);
const PricingConfigurationPage = Loadable(
  lazy(() => import("../../view/admin/Pricing/configuration"))
);
const PricingReportPage = Loadable(
  lazy(() => import("../../view/admin/Pricing/reports"))
);
const PricingRunPage = Loadable(
  lazy(() => import("../../view/admin/Pricing/runs"))
);
const PricingMetrixRunPage = Loadable(
  lazy(() => import("../../view/admin/Pricing/runs/metrixStatus"))
);
const PricingDownloadMetrix = Loadable(
  lazy(() => import("../../view/admin/Pricing/runs/metrixDownload"))
);
const PricingStatusPage = Loadable(
  lazy(() => import("../../view/admin/Pricing/runs/status"))
);
const PricingDownloadsPage = Loadable(
  lazy(() => import("../../view/admin/Pricing/downloads"))
);

const PricingUploadPage = Loadable(
  lazy(() => import("../../view/admin/Pricing/uploads"))
);
const LoginPage = Loadable(lazy(() => import("../../view/admin/Login")));
const CustomerManagement = Loadable(
  lazy(() => import("../../view/admin/customer/CustomerManagement"))
);
const SettlementScreen = Loadable(
  lazy(() => import("../../view/admin/Settlement/volumetric/index"))
);
const FinancialSettlementScreen = Loadable(
  lazy(() => import("../../view/admin/Settlement/Financials/index"))
);
const ServiceProfilePage = Loadable(
  lazy(() => import("../../view/admin/Admin/components/ServiceProfile/index"))
);
const NotFound = Loadable(lazy(() => import("../../view/admin/NotFound")));

const RoutePage = () => {
  return (
    <Routes>
      <Route
        path={`${process.env.PUBLIC_URL}/`}
        element={
          <AuthGuard>
            <AppLayout />
          </AuthGuard>
        }
      >
        <Route path="pricing">
          <Route index path="quote" element={<PricingPage />} />
          <Route index path="reports" element={<PricingReportPage />} />
          <Route path="runs" element={<PricingRunPage />}>
            <Route path=":sessionId" element={<PricingRunPage />} />
          </Route>
          <Route index path="curve" element={<PriceCurve />}></Route>
          <Route path="matrix">
            <Route
              index
              path="status"
              element={<PricingMetrixRunPage />}
            ></Route>
            <Route
              index
              path="configuration"
              element={<PricingConfigurationPage isMetrix={true} />}
            />
            <Route index path="download" element={<PricingDownloadMetrix />} />
            <Route index path="*" element={<MatrixPricing />} />
          </Route>
          <Route index path="status" element={<PricingStatusPage />}></Route>
          <Route
            index
            path="downloads"
            element={<PricingDownloadsPage />}
          ></Route>
          <Route
            index
            path="configuration"
            element={<PricingConfigurationPage />}
          />
          <Route
            index
            path="configuration/new"
            element={<AddComponentPage />}
          />
          <Route index path="uploads" element={<PricingUploadPage />} />
          <Route index path="*" element={<PricingPage />} />
        </Route>

        <Route path="datamanagement">
          <Route index path="datamanagement" element={<CustomerManagement />} />
          <Route
            index
            path="customer/data/uploads"
            element={<PricingUploadPage />}
          />
          <Route
            index
            path="customer/existing"
            element={<ExistingCustomerPage />}
          />
          <Route index path="*" element={<CustomerManagement />} />
        </Route>

        <Route path="riskreport">
          <Route index path="riskreport" element={<RiskReportPage />} />
          <Route index path="trade" element={<TradeCapture />} />{" "}
          <Route index path="analysis" element={<NotFound />} />
          <Route index path="*" element={<RiskReportPage />} />
        </Route>

        <Route path="forecasting">
          <Route index path="forecasting" element={<ForecastingDashboard />} />
          <Route index path="reports" element={<ForecastingReportPage />} />
          <Route index path="run" element={<NotFound />} />
          <Route index path="config" element={<NotFound />} />
          <Route index path="*" element={<ForecastingDashboard />} />
        </Route>

        <Route path="analytics" element={<AnalaticsPage />} />
        <Route path="renewable" element={<NotFound />} />
        <Route path="risk" element={<NotFound />} />
        <Route path="settlement">
          <Route index path="settlement" element={<SettlementScreen />} />

          <Route path="financial">
            <Route index path="*" element={<FinancialSettlementScreen />} />
          </Route>
          <Route index path="*" element={<SettlementScreen />} />
        </Route>

        <Route path="asset">
          <Route index path="*" element={<AssetValuationPage />} />
          <Route index path="marktomarket" element={<MarkToMarketPage />} />
        </Route>
        <Route path="reports">
          <Route index path="reports" element={<Reports />} />

          <Route index path="*" element={<Reports />} />
        </Route>
        <Route path="admin">
          <Route index path="*" element={<NotFound />} />
          <Route path="config-domain">
            <Route path="element">
              <Route path=":domainId">
                <Route path="attribute" element={<ConfigAttribute />}>
                  <Route
                    index
                    path=":elementId"
                    element={<ConfigAttribute />}
                  />
                </Route>
                <Route index path="*" element={<ConfigElement />} />
              </Route>
              <Route index path="*" element={<ConfigElement />} />
            </Route>

            <Route index path="*" element={<ConfigDomain />} />

            {/*  */}
          </Route>
          <Route path="service-profile">
            <Route index path="*" element={<ServiceProfilePage />} />

            {/*  */}
          </Route>

          <Route index path="*" element={<NotFound />} />
        </Route>

        <Route
          path="*"
          element={
            <Navigate to={`${process.env.PUBLIC_URL}/datamanagement`} replace />
          }
        />
      </Route>
      <Route
        path={`${process.env.PUBLIC_URL}/session/signin`}
        element={<LoginPage />}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/*`}
        element={
          <Navigate to={`${process.env.PUBLIC_URL}/datamanagement`} replace />
        }
      />
    </Routes>
  );
};
export default RoutePage;
