import { Skeleton } from "@mui/material";

export const FieldSkeliton = (props) => {
  const {
    height = 37,
    variant = "rounded",
    animation = "wave",
    width = "100%",
  } = props;
  return (
    <>
      <Skeleton
        variant={variant}
        animation={animation}
        height={height}
        width={width}
      />
    </>
  );
};
export const ListSkeleton = () => {
  return Array(5).map((item, i) => (
    <>
      <tbody key={i}>
        <tr>
          <td className="col">
            <Skeleton />
          </td>
        </tr>
      </tbody>
    </>
  ));
};
