import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { axiosConfigManagement } from "axios.js";

const initialState = {
  brokerFee: 0,
  marginFee: 0,
  quoteComponents: { list: [], loader: false },
  utility: { list: [], loader: false },
  brokerName: {
    list: [
      "No Broker",
      "B & B Associates",
      "Optimum Energy Services",
      "Power Star",
    ],
    selected: null,
  },
};

export const getQuoteComponentsAsync = createAsyncThunk(
  "pricing/fee/components",
  async (pricing_model_id) => {
    const response = await axios.get(
      `/api/v1/pricingmodel/get/quote/component/${pricing_model_id}`
    );
    return response;
  }
);

export const getUtilityAsync = createAsyncThunk(
  "pricing/utility/list",
  async () => {
    const response =
      await axiosConfigManagement.get(`api/v1/configuration/utility/get
    `);
    return response;
  }
);
export const pricingFeeSlice = createSlice({
  name: "pricing/fee",
  initialState,
  reducers: {
    setBrokerFee: (state, action) => {
      state.brokerFee = action.payload;
    },
    setMarginFee: (state, action) => {
      state.marginFee = action.payload;
    },
    setQuoteComponents: (state, action) => {
      state.quoteComponents.list = action.payload;
    },
    setBrokerName: (state, action) => {
      state.brokerName.selected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getQuoteComponentsAsync.pending, (state) => {
        state.quoteComponents.loader = true;
        state.quoteComponents.list = [];
      })
      .addCase(getQuoteComponentsAsync.fulfilled, (state, action) => {
        state.quoteComponents.loader = false;
        state.quoteComponents.list = action?.payload?.data?.data || [];
      })
      .addCase(getQuoteComponentsAsync.rejected, (state, action) => {
        state.quoteComponents.loader = false;
        state.quoteComponents.list = [];
      })
      .addCase(getUtilityAsync.pending, (state) => {
        state.utility.loader = true;
        state.utility.list = [];
      })
      .addCase(getUtilityAsync.fulfilled, (state, action) => {
        state.utility.loader = false;
        state.utility.list = action?.payload?.data?.data || [];
      })
      .addCase(getUtilityAsync.rejected, (state, action) => {
        state.utility.loader = false;
        state.utility.list = [];
      });
  },
});
export const { setBrokerFee, setMarginFee, setQuoteComponents, setBrokerName } =
  pricingFeeSlice.actions;
export default pricingFeeSlice.reducer;
