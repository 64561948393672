import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosForIp from "axios";
import { axiosRiskInstance as axios } from "axios.js";

const initialState = {
  tradeCaptureDownloadbleData: { loader: false, data: [] },

  tradeCaptures: { tradecaptureData: {}, loader: false },
  tardeCaptureParams: { params: {} },
  addNewTradeCapture: { loader: false },
  uploadTradeCapture: { loader: false },
  // FileUploadGuid: "",
  IP: {},
};

export const getTradeCaptureAsync = createAsyncThunk(
  "risk/tradeCapture/getTradeCaptureTardeList",
  async (data) => {
    const { page, pagesize, client_code } = data;
    const response = await axios.get(
      `/api/v1/trade-capture/${client_code}/get?page=${page}&pagesize=${pagesize}`
    );
    return response;
  }
);
export const getTradeCaptureParamsAsync = createAsyncThunk(
  "risk/tradeCapture/getTradeCaptureParams",
  async (data) => {
    const { param_name } = data;
    const response = await axios.get(`/api/v1/configuration/${param_name}/get`);
    return { response, param_name };
  }
);
export const getTradeCaptureDeliverpointCode = createAsyncThunk(
  "risk/tradeCapture/getTradeCaptureDeliveryPoint",
  async (data) => {
    const { iso_code, delivery_point_type_code } = data;
    const response = await axios.get(
      `/api/v1/configuration/delivery-point-code/${iso_code}/${delivery_point_type_code}`
    );
    return response;
  }
);
export const postAddTradeCaptureData = createAsyncThunk(
  "risk/tradeCapture/add",
  async (body) => {
    const response = await axios.post(
      `/api/v1/trade-capture/add`,
      body?.payload
    );
    return response;
  }
);
export const postExportTradeCaptureData = createAsyncThunk(
  "risk/tradeCapture/exportTradeCaptureData",
  async (body) => {
    const response = await axios.post(
      `/api/v1/trade-capture/${body.client_code}/load`,
      body?.payload
    );
    return response;
  }
);

export const getIPTradeCaptureAsync = createAsyncThunk(
  "tardeCapture/upload/new/IP",
  async () => {
    const response = await axiosForIp
      .get("https://www.cloudflare.com/cdn-cgi/trace")
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    return response;
  }
);
export const deleteTradeAsync = createAsyncThunk(
  "tardeCapture/delete/trade",
  async ({ clientCode, wholesale_trade_uuid }) => {
    const response = await axios.delete(
      `/api/v1/trade-capture/${clientCode}/delete?identifier=${wholesale_trade_uuid}`
    );
    return response;
  }
);
export const postEditTradeCaptureData = createAsyncThunk(
  "risk/tradeCapture/edit",
  async (body) => {
    const response = await axios.put(
      `/api/v1/trade-capture/update`,
      body?.payload
    );
    return response;
  }
);
export const getTradeCaptureDownloadableFile = createAsyncThunk(
  "tradeCapture/download",
  async (client_code) => {
    // try {
    const response = await axios.get(
      `/api/v1/trade-capture/${client_code}/get_data_for_download`
    );
    return response;
  }
);
export const tradeCaptureSlice = createSlice({
  name: "risk/tradeCapture",
  initialState,
  reducers: {
    // UpdateFileUploadGuidForTradeCapture: (state, action) => {
    //   state.FileUploadGuid = action?.payload;
    // },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getTradeCaptureAsync.pending, (state) => {
        state.tradeCaptures.loader = true;
      })
      .addCase(getTradeCaptureAsync.fulfilled, (state, action) => {
        state.tradeCaptures.loader = false;
        state.tradeCaptures.tradecaptureData = action?.payload?.data?.data;
      })
      .addCase(getTradeCaptureAsync.rejected, (state) => {
        state.tradeCaptures.loader = false;
        state.tradeCaptures.tradecaptureData = {};
      })

      .addCase(getTradeCaptureParamsAsync.fulfilled, (state, action) => {
        state.tardeCaptureParams.params = {
          ...state.tardeCaptureParams.params,
          [action?.payload?.param_name]: action?.payload?.response?.data?.data,
        };
      })
      .addCase(getTradeCaptureParamsAsync.rejected, (state) => {
        state.tardeCaptureParams.params = {};
      })
      .addCase(getTradeCaptureDeliverpointCode.pending, (state) => {
        state.tardeCaptureParams.params = {
          ...state.tardeCaptureParams.params,
          "delivery-point-code": [],
        };
      })
      .addCase(getTradeCaptureDeliverpointCode.fulfilled, (state, action) => {
        state.tardeCaptureParams.params = {
          ...state.tardeCaptureParams.params,
          "delivery-point-code": action?.payload?.data?.data,
        };
      })
      .addCase(getTradeCaptureDeliverpointCode.rejected, (state) => {
        state.tardeCaptureParams.params = {
          ...state.tardeCaptureParams.params,
          "delivery-point-code": [],
        };
      })
      .addCase(postAddTradeCaptureData.pending, (state) => {
        state.addNewTradeCapture.loader = true;
      })
      .addCase(postAddTradeCaptureData.fulfilled, (state, action) => {
        state.addNewTradeCapture.loader = false;
        state.tardeCaptureParams.list = action?.payload?.data?.data;
      })
      .addCase(postAddTradeCaptureData.rejected, (state) => {
        state.addNewTradeCapture.loader = false;
      })
      .addCase(postExportTradeCaptureData.pending, (state) => {
        state.uploadTradeCapture.loader = true;
      })
      .addCase(postExportTradeCaptureData.fulfilled, (state, action) => {
        state.uploadTradeCapture.loader = false;
        state.tardeCaptureParams.list = action?.payload?.data?.data;
      })
      .addCase(postExportTradeCaptureData.rejected, (state) => {
        state.uploadTradeCapture.loader = false;
      })
      .addCase(getIPTradeCaptureAsync.pending, (state) => {})
      .addCase(getIPTradeCaptureAsync.fulfilled, (state, action) => {
        const ipPattern = /ip=([^\n]+)/;
        const match = action?.payload?.data?.match(ipPattern);

        if (match && match.length >= 2) {
          const ipAddress = match[1];
          state.IP = ipAddress;
        } else {
          console.log("No IP address found.");
          state.IP = "";
        }
      })

      .addCase(getIPTradeCaptureAsync.rejected, (state) => {})
      .addCase(getTradeCaptureDownloadableFile.rejected, (state, action) => {
        state.tradeCaptureDownloadbleData.loader = false;
        state.tradeCaptureDownloadbleData.data = [];

        // changes
      })
      .addCase(getTradeCaptureDownloadableFile.pending, (state, action) => {
        state.tradeCaptureDownloadbleData.loader = true;
        state.tradeCaptureDownloadbleData.data = {};

        // changes
      })
      .addCase(getTradeCaptureDownloadableFile.fulfilled, (state, action) => {
        state.tradeCaptureDownloadbleData.loader = false;
        state.tradeCaptureDownloadbleData.data = action?.payload?.data
          ? action?.payload?.data
          : {};

        // changes
      });
  },
});
// export const { UpdateFileUploadGuidForTradeCapture } =
//   tradeCaptureSlice.actions;
export default tradeCaptureSlice.reducer;
