import "../../../assets/styles/css/firefly.min.css";
import "../../../assets/styles/css/style.css";
import { Link, NavLink } from "react-router-dom";
import { navigations } from "features/config/routes/navigations";
import { useSelector, useDispatch } from "react-redux";
import { selectNavigation } from "app/redux/slices/navigation/sideNavigationSlice";
import useAuth from "app/hooks/useAuth";
import { Tooltip } from "@mui/material";
import Fade from "@mui/material/Fade";

const Sidebar = () => {
  let { previleges } = useAuth();
  const dispatch = useDispatch();
  const handleClick = (item) => {
    dispatch(selectNavigation(item));
  };
  let activeClassName = "nav-link active";
  const renderNavigation = (items) => {
    return items.map((item) => {
      return (
        <li key={item.name} className="nav-item">
          {typeof item.type === "undefined" && (
            <NavLink
              className={({ isActive }) => {
                if (
                  previleges?.includes(item.feature) ||
                  item.feature === true
                ) {
                  if (isActive) {
                    dispatch(selectNavigation(item));
                    return activeClassName;
                  }
                  return "nav-link";
                } else {
                  return "nav-link";
                }
              }}
              to={
                previleges?.includes(item.feature) || item.feature === true
                  ? `${process.env.PUBLIC_URL}/${item.path}`
                  : ""
              }
              onClick={() =>
                previleges?.includes(item.feature) || item.feature === true
                  ? ""
                  : handleClick(item)
              }
            >
              {" "}
              <Tooltip
                placement="bottom-end"
                arrow
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                title={
                  previleges?.includes(item.feature) || item.feature === true
                    ? ""
                    : "Feature not enabled."
                }
              >
                <div
                  style={{
                    opacity:
                      previleges?.includes(item.feature) ||
                      item.feature === true
                        ? ""
                        : 0.3,
                  }}
                >
                  <i className={item?.icon}></i> <span>{item?.name}</span>
                </div>
              </Tooltip>
            </NavLink>
          )}
          {typeof item.type !== "undefined" && (
            <>
              <a target="_blank" className="nav-link" href={item.path}>
                <i className={item.icon}></i>
                <span>{item.name}</span>
              </a>
            </>
          )}
        </li>
      );
    });
  };

  return (
    <ul
      className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
      id="accordionSidebar"
    >
      <div className="nav-sub">{renderNavigation(navigations)}</div>
    </ul>
  );
};
export default Sidebar;
