import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axiosForProduct, { axiosConfigManagement as axios } from "axios.js";

const initialState = {
  configattributes: { configattributeData: [], loader: false },
  configAttributeParams: { params: {} },
  addNewConfigattribute: { loader: false },
  product: { list: [], loader: false },
};

export const getProductListForConfigattributeAsync = createAsyncThunk(
  "admin/configattribute/getProductListAsync",
  async (client_code) => {
    const response = axiosForProduct
      .get(`/api/v1/product/get/all?client_code=${client_code}`)
      .then((res) => {
        return res;
      });
    return response;
  }
);

export const getConfigattributeAsync = createAsyncThunk(
  "admin/configattribute/getCongigattributeeList",
  async (data) => {
    const { config_element_id } = data;
    const response = await axios.get(
      `/api/v1/configuration/config-element/${config_element_id}/get-attributes`
    );
    return response;
  }
);

export const postAddConfigattributeData = createAsyncThunk(
  "admin/configattribute/add",
  async (body) => {
    const response = await axios.post(
      `/api/v1/configuration/config-attribute/add`,
      body?.payload
    );
    return response;
  }
);

export const deleteConfigattributeAsync = createAsyncThunk(
  "admin/delete/configattribute",
  async ({ config_attribute_id }) => {
    const response = await axios.delete(
      `/api/v1/configuration/config-attribute/delete?id=${config_attribute_id}`
    );
    return response;
  }
);

export const postEditConfigattributeData = createAsyncThunk(
  "admin/configattribute/edit",
  async (body) => {
    const response = await axios.put(
      `/api/v1/configuration/config-attribute/update`,
      body?.payload
    );
    return response;
  }
);
export const getConfigAttributeParamsAsync = createAsyncThunk(
  "admin/configAttribute/congigDomainParam",
  async (data) => {
    const { param_name } = data;
    const response = await axios.get(`/api/v1/configuration/${param_name}/get`);
    return { response, param_name };
  }
);
export const configAttributeSlice = createSlice({
  name: "admin/configattribute",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getConfigattributeAsync.pending, (state) => {
        state.configattributes.loader = true;
      })
      .addCase(getConfigattributeAsync.fulfilled, (state, action) => {
        state.configattributes.loader = false;
        state.configattributes.configattributeData =
          action?.payload?.data?.data;
      })
      .addCase(getConfigattributeAsync.rejected, (state) => {
        state.configattributes.loader = false;
        state.configattributes.configattributeData = [];
      })
      .addCase(postEditConfigattributeData.pending, (state) => {
        state.addNewConfigattribute.loader = true;
      })
      .addCase(postEditConfigattributeData.fulfilled, (state, action) => {
        state.addNewConfigattribute.loader = false;
        //state.tardeCaptureParams.list = action?.payload?.data?.data;
      })
      .addCase(postEditConfigattributeData.rejected, (state) => {
        state.addNewConfigattribute.loader = false;
      })
      .addCase(getProductListForConfigattributeAsync.pending, (state) => {
        state.product.loader = true;
      })
      .addCase(
        getProductListForConfigattributeAsync.fulfilled,
        (state, action) => {
          state.product.loader = false;
          state.product.list = action?.payload?.data?.data || [];
        }
      )
      .addCase(
        getProductListForConfigattributeAsync.rejected,
        (state, action) => {
          state.product.loader = false;
          state.product.list = [];
        }
      )
      .addCase(getConfigAttributeParamsAsync.fulfilled, (state, action) => {
        state.configAttributeParams.params = {
          ...state.configAttributeParams.params,
          [action?.payload?.param_name]: action?.payload?.response?.data?.data,
        };
      })
      .addCase(getConfigAttributeParamsAsync.rejected, (state) => {
        state.configAttributeParams.params = {};
      });
  },
});

export default configAttributeSlice.reducer;
