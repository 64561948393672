import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosForecastInstance as axios } from "axios.js";

const INITIAL_STATE = {
  get_filtered_data: false,
  dateOne: "",
  dateTwo: "",
  shortTermForecastingFilterData: {
    loader: false,
    dropDownListItems: {
      batch_dates: [],
      zones: [],
      utility_code: [],
      markets: [],
      product_risk: [],
      load_types: [],
    },
  },
  filterStatus: false,

  dropDownList: {
    batch_date: "",
    load_type: "",
    congestion_zone: [],
    markets: [],
    product_risk: [],
    utility_code: [],
  },
  utility_zones_by_market: {},
  shortTermForecastingConsumptionChartData: {
    loader: false,
    averageConsumtionDataToPlot: {},
    totalConsumtionDataToPlot: [],
    dailyBlockData: {},
    cunsumptionDeltaLoad: {},
    shortTermDeltaBlockDaily: {},
  },
};

export const getShortTermForecastInitialDataAsync = createAsyncThunk(
  "forecasting/getShortTermInitialData",
  async (data) => {
    const response = await axios.get("/api/v1/getShortTermForecastData", data);
    return response;
  }
);

export const getUpdatedShortTermForecastDataAsync = createAsyncThunk(
  "forecasting/getShortTermUpdatedData",
  async (data) => {
    const response = await axios.post(
      "/api/v1/getShortTermForecastDataWithFilter",
      data
    );
    return response;
  }
);

export const shortTermForecastSlice = createSlice({
  name: "forecasting/shortterm",
  initialState: INITIAL_STATE,
  reducers: {
    dateOneChange: (state, action) => {
      state.dateOne = action.payload;
    },
    dateTwoChange: (state, action) => {
      state.dateTwo = action.payload;
    },
    setFilterStatus: (state, action) => {
      state.filterStatus = action.payload;
    },
    marketOnChange: (state, action) => {
      let load_zone = [];
      let utility = [];
      if (action?.payload.length) {
        for (let val of action?.payload) {
          load_zone.push(...state.utility_zones_by_market[val]?.zones);
          utility.push(...state.utility_zones_by_market[val]?.utilities);
        }
      }
      state.shortTermForecastingFilterData.dropDownListItems.zones = load_zone;
      state.shortTermForecastingFilterData.dropDownListItems.utility_code =
        utility;

      state.dropDownList.markets = action.payload || [];
      state.dropDownList.congestion_zone = load_zone || [];
      state.dropDownList.utility_code = utility || [];
    },
    setDropDownListChangeNew: (state, action) => {
      const values = action?.payload?.values || [];
      const type = action?.payload?.type;
      if (type) {
        state.dropDownList[type] = values;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getShortTermForecastInitialDataAsync.pending, (state) => {
        state.get_filtered_data = false;

        state.shortTermForecastingFilterData.loader = true;
        state.shortTermForecastingConsumptionChartData.loader = true;
      })
      .addCase(
        getShortTermForecastInitialDataAsync.fulfilled,
        (state, action) => {
          state.shortTermForecastingFilterData.loader = false;
          state.shortTermForecastingConsumptionChartData.loader = false;

          let { allZones, allUtilities } = Object.values(
            action?.payload?.data?.utility_zones_by_market
          ).reduce(
            (tot, cur) => {
              return {
                allZones: [...tot.allZones, ...cur.zones],
                allUtilities: [...tot.allUtilities, ...cur.utilities],
              };
            },
            { allZones: [], allUtilities: [] }
          );
          state.shortTermForecastingFilterData.dropDownListItems = {
            batch_dates: action?.payload?.data?.batch_dates || [],
            zones: allZones || [],
            load_types: ["Wholesale load", "Metered load"],
            utility_code: allUtilities || [],
            markets: action?.payload?.data?.markets || [],
            product_risk:
              action?.payload?.data?.product_risk.sort((first, second) => {
                return first.localeCompare(second);
              }) || [],
          };
          state.utility_zones_by_market =
            action?.payload?.data?.utility_zones_by_market || {};

          // state.shortTermForecastingConsumptionChartData.averageConsumtionDataToPlot =
          //   action?.payload?.data?.short_term_block_data || {};

          state.shortTermForecastingConsumptionChartData.dailyBlockData =
            action?.payload?.data?.short_term_block_data_daily || {};
          state.shortTermForecastingConsumptionChartData.cunsumptionDeltaLoad =
            action?.payload?.data?.delta_load || {};
          state.shortTermForecastingConsumptionChartData.shortTermDeltaBlockDaily =
            action?.payload?.data?.short_term_delta_block_daily || {};

          state.shortTermForecastingConsumptionChartData.totalConsumtionDataToPlot =
            action?.payload?.data?.total_load || [];
          state.dateTwo = action?.payload?.data?.batch_dates[0] || "";
          state.dateOne = action?.payload?.data?.batch_dates[1] || "";
          state.get_filtered_data = true;
        }
      )
      .addCase(getShortTermForecastInitialDataAsync.rejected, (state) => {
        state.get_filtered_data = false;

        state.shortTermForecastingFilterData.loader = false;
        state.shortTermForecastingConsumptionChartData.loader = false;
      })
      .addCase(getUpdatedShortTermForecastDataAsync.pending, (state) => {
        state.shortTermForecastingConsumptionChartData.loader = true;
      })
      .addCase(
        getUpdatedShortTermForecastDataAsync.fulfilled,
        (state, action) => {
          state.shortTermForecastingConsumptionChartData.loader = false;
          // state.shortTermForecastingConsumptionChartData.averageConsumtionDataToPlot = action?.payload?.data?.short_term_data;
          state.shortTermForecastingConsumptionChartData.dailyBlockData =
            action?.payload?.data?.short_term_block_data_daily;
          state.shortTermForecastingConsumptionChartData.cunsumptionDeltaLoad =
            action?.payload?.data?.delta_load || {};
          state.shortTermForecastingConsumptionChartData.shortTermDeltaBlockDaily =
            action?.payload?.data?.short_term_delta_block_daily || {};

          state.shortTermForecastingConsumptionChartData.totalConsumtionDataToPlot =
            action?.payload?.data?.total_load;
        }
      )
      .addCase(getUpdatedShortTermForecastDataAsync.rejected, (state) => {
        state.shortTermForecastingConsumptionChartData.loader = false;
      });
  },
});

export const {
  marketOnChange,
  setDropDownListChangeNew,
  dateOneChange,
  dateTwoChange,
  setFilterStatus,
} = shortTermForecastSlice.actions;
export default shortTermForecastSlice.reducer;
