import React from "react";
import "./App.css";
import { AuthProvider } from "./app/contexts/JWTAuthContext";
import RoutePage from "./features/config/routes/RoutePage";
import { SyncfusionLicenseKey } from "features/CustomDatas";
import { registerLicense } from "@syncfusion/ej2-base";

registerLicense(SyncfusionLicenseKey);

function App() {
  return (
    <AuthProvider>
      <RoutePage />
    </AuthProvider>
  );
}

export default App;
