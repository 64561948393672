import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axiosForProduct, { axiosConfigManagement as axios } from "axios.js";

const initialState = {
  configElement: { configElementData: [], loader: false },
  configElementParams: {},
  addNewConfigElement: { loader: false },
  product: { list: [], loader: false },
};

export const getProductListForConfigElementAsync = createAsyncThunk(
  "admin/configElement/getProductListAsync",
  async (client_code) => {
    const response = axiosForProduct
      .get(`/api/v1/product/get/all?client_code=${client_code}`)
      .then((res) => {
        return res;
      });
    return response;
  }
);

export const getConfigElementAsync = createAsyncThunk(
  "admin/configElement/getCongigElementList",
  async (data) => {
    const { config_domain_id } = data;
    const response = await axios.get(
      `/api/v1/configuration/config-domain/${config_domain_id}/get-elements`
    );
    return response;
  }
);

export const postAddConfigElementData = createAsyncThunk(
  "admin/configElement/add",
  async (body) => {
    const response = await axios.post(
      `/api/v1/configuration/config-element/add`,
      body?.payload
    );
    return response;
  }
);

export const deleteConfigElementAsync = createAsyncThunk(
  "admin/delete/configElement",
  async ({ config_element_id }) => {
    const response = await axios.delete(
      `/api/v1/configuration/config-element/delete?id=${config_element_id}`
    );
    return response;
  }
);
export const postEditConfigElementData = createAsyncThunk(
  "admin/configElement/edit",
  async (body) => {
    const response = await axios.put(
      `/api/v1/configuration/config-element/update`,
      body?.payload
    );
    return response;
  }
);
export const getConfigElementParamsAsync = createAsyncThunk(
  "admin/configElement/congigDomainParam",
  async (data) => {
    const { param_name } = data;
    const response = await axios.get(`/api/v1/configuration/${param_name}/get`);
    return { response, param_name };
  }
);
export const configElementSlice = createSlice({
  name: "admin/configElement",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getConfigElementAsync.pending, (state) => {
        state.configElement.loader = true;
      })
      .addCase(getConfigElementAsync.fulfilled, (state, action) => {
        state.configElement.loader = false;
        state.configElement.configElementData = action?.payload?.data?.data;
      })
      .addCase(getConfigElementAsync.rejected, (state) => {
        state.configElement.loader = false;
        state.configElement.configElementData = [];
      })
      .addCase(postEditConfigElementData.pending, (state) => {
        state.addNewConfigElement.loader = true;
      })
      .addCase(postEditConfigElementData.fulfilled, (state, action) => {
        state.addNewConfigElement.loader = false;
        //state.tardeCaptureParams.list = action?.payload?.data?.data;
      })
      .addCase(postEditConfigElementData.rejected, (state) => {
        state.addNewConfigElement.loader = false;
      })
      .addCase(getProductListForConfigElementAsync.pending, (state) => {
        state.product.loader = true;
      })
      .addCase(
        getProductListForConfigElementAsync.fulfilled,
        (state, action) => {
          state.product.loader = false;
          state.product.list = action?.payload?.data?.data || [];
        }
      )
      .addCase(
        getProductListForConfigElementAsync.rejected,
        (state, action) => {
          state.product.loader = false;
          state.product.list = [];
        }
      )
      .addCase(getConfigElementParamsAsync.fulfilled, (state, action) => {
        state.configElementParams.params = {
          ...state.configElementParams.params,
          [action?.payload?.param_name]: action?.payload?.response?.data?.data,
        };
      })
      .addCase(getConfigElementParamsAsync.rejected, (state) => {
        state.configElementParams.params = {};
      });
  },
});

export default configElementSlice.reducer;
