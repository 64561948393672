import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios.js";

const initialState = {
  results: {
    runStatus: { status: false, loader: false },
    summary: { data: {}, loader: true },
    termData: { data: [], loader: true },
    usageChartData: { data: {}, loader: false },
    modelDetails: { data: [], loader: false },
    monthlyData:{data:[],loader:false},
    breakUp: { data: {}, loader: false },
  },
};

export const getRunStatusAsync = createAsyncThunk(
  "pricing/run/status/check",
  (runId) => {
    if (typeof runId === "undefined" || runId === null || runId.length === 0) {
      return Promise.resolve({
        data: { data: true },
        status: 200,
        statusText: 'OK',
      });
    }
    const response = axios
      .get(`api/v1/pricing/run/status?runId=${runId}`)
      .then((res) => {
        return res;
      });
    return response;
  }
);
export const getRunResultSummaryAsync = createAsyncThunk(
  "pricing/run/result/summary",
  (sessionId) => {
    if (typeof sessionId === "undefined" || sessionId.length === 0) {
      return Promise.resolve({
        data: {
          data: {
            accountDetails: [],
            brokerFeeIncluded: false,
            estimateAnnual: 1500,
            numberOfAccounts: 2,
            product: "Fixed",
            quoteId: "NKD54k",
            quoteDate: new Date(),
            startDate: (new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1))
          },
          status: 200,
          statusText: 'OK'
        }
      })
    }
    else {
      const response = axios
        .get(`api/v1/pricing/run/result/summary?sessionId=${sessionId}`)
        .then((res) => {
          return res;
        });
      return response;
    }
  }
);
export const getRunResultTermDataAsync = createAsyncThunk(
  "pricing/run/result/term/data",
  (sessionId) => {
    if (typeof sessionId === "undefined" || sessionId.length === 0) {
      return Promise.resolve({
        data: {
          data: [{
            term: 12,
            price: 51.49
          }, {
            term: 24,
            price: 51.45
          }, {
            term: 36,
            price: 51.68
          }]
        },
        status: 200,
        statusText: 'OK',
      })
    }
    else {
      const response = axios
        .get(`api/v1/pricing/run/result/data?sessionId=${sessionId}`)
        .then((res) => {
          return res;
        });
      return response;
    }
  }
);
export const getRunResultUsageChartDataAsync = createAsyncThunk(
  "pricing/run/result/usage/chart/data",
  (sessionId) => {
    const response = axios
      .get(`api/v1/pricing/run/result/usage/chartupdated?sessionId=${sessionId}`)
      .then((res) => {
        return res;
      });
    return response;
  }
);

export const getRunResultModelDataAsync = createAsyncThunk(
  "pricing/run/result/model/data",
  (sessionId) => {
    const response = axios
      .get(`api/v1/pricing/run/model/${sessionId}`)
      .then((res) => {
        return res;
      });
    return response;
  }
);

export const getRunResultModelDatMonthlyAsync = createAsyncThunk(
  "pricing/run/result/run/result/compnent/monthly",
  (sessionId) => {
    const response = axios
      .get(`api/v1/pricing/run/result/compnent/monthly/?sessionId=${sessionId}`)
      .then((res) => {
        return res;
      });
    return response;
  }
);

export const getRunResultModelDataBreakUpAsync = createAsyncThunk(
  "pricing/run/result/run/result/compnent/breakup",
  (sessionId) => {
    const response = axios
      .get(`api/v1/pricing/run/result/compnent/breakup/?sessionId=${sessionId}`)
      .then((res) => {
        return res;
      });
    return response;
  }
);

export const DownloadContractAsync = createAsyncThunk(
  "pricing/run/result/run/result/DownloadContractAsync",
  ({sessionId, term}) => {
    const response = axios
      .get(`api/v1/pricing/run/result/downloadcontract/?pricing_request_uuid=${sessionId}&term=${term}`, {
        responseType: 'blob'
      })
      .then((res) => {
        return res;
      });
    return response;
  }
);

export const pricingRunResultSlice = createSlice({
  name: "pricing/run/result",
  initialState,
  reducers: { updateBreakupGroup: (state, action) => {
    state.results.breakUp.data.groups = action.payload;
  },},
  extraReducers: (builder) => {
    builder
      .addCase(getRunStatusAsync.pending, (state) => {
        state.results.runStatus.loader = true;
        state.results.runStatus.status = false;
      })
      .addCase(getRunStatusAsync.fulfilled, (state, action) => {
        state.results.runStatus.loader = false;
        state.results.runStatus.status = action?.payload?.data?.data || false;
      })
      .addCase(getRunStatusAsync.rejected, (state, action) => {
        state.results.runStatus.loader = false;
        state.results.runStatus.status = false;
      })
      .addCase(getRunResultSummaryAsync.pending, (state) => {
        state.results.summary.loader = true;
      })
      .addCase(getRunResultSummaryAsync.fulfilled, (state, action) => {
        state.results.summary.loader = false;
        state.results.summary.data = action?.payload?.data?.data || {};
      })
      .addCase(getRunResultSummaryAsync.rejected, (state, action) => {
        state.results.summary.loader = false;
        state.results.summary.data = {};
      })
      .addCase(getRunResultTermDataAsync.pending, (state) => {
        state.results.termData.loader = true;
      })
      .addCase(getRunResultTermDataAsync.fulfilled, (state, action) => {
        state.results.termData.loader = false;
        state.results.termData.data = action?.payload?.data?.data || [];
      })
      .addCase(getRunResultTermDataAsync.rejected, (state, action) => {
        state.results.termData.loader = false;
        state.results.termData.data = [];
      })
      .addCase(getRunResultUsageChartDataAsync.pending, (state) => {
        state.results.usageChartData.loader = true;
        state.results.usageChartData.data = {};
      })
      .addCase(getRunResultUsageChartDataAsync.fulfilled, (state, action) => {
        state.results.usageChartData.loader = false;
        state.results.usageChartData.data = action?.payload?.data?.data || {};
      })
      .addCase(getRunResultUsageChartDataAsync.rejected, (state, action) => {
        state.results.usageChartData.loader = false;
        state.results.usageChartData.data = {};
      })
      .addCase(getRunResultModelDataAsync.pending, (state) => {
        state.results.modelDetails.loader = true;
        state.results.modelDetails.data = [];
      })
      .addCase(getRunResultModelDataAsync.fulfilled, (state, action) => {
        state.results.modelDetails.loader = false;
        state.results.modelDetails.data = action?.payload?.data?.data || [];
      })
      .addCase(getRunResultModelDataAsync.rejected, (state, action) => {
        state.results.modelDetails.loader = false;
        state.results.modelDetails.data = [];
      })
      .addCase(getRunResultModelDatMonthlyAsync.pending, (state) => {
        state.results.monthlyData.loader = true;
        state.results.monthlyData.data = [];
      })
      .addCase(getRunResultModelDatMonthlyAsync.fulfilled, (state, action) => {
        state.results.monthlyData.loader = false;
        state.results.monthlyData.data = action?.payload?.data?.data || [];
      })
      .addCase(getRunResultModelDatMonthlyAsync.rejected, (state, action) => {
        state.results.monthlyData.loader = false;
        state.results.monthlyData.data = [];
      })
      .addCase(getRunResultModelDataBreakUpAsync.pending, (state) => {
        state.results.breakUp.loader = true;
        state.results.breakUp.data = {};
      })
      .addCase(getRunResultModelDataBreakUpAsync.fulfilled, (state, action) => {
        state.results.breakUp.loader = false;
        state.results.breakUp.data = action?.payload?.data?.data || {};
      })
      .addCase(getRunResultModelDataBreakUpAsync.rejected, (state, action) => {
        state.results.breakUp.loader = false;
        state.results.breakUp.data = {};
      }).addCase(DownloadContractAsync.pending, (state) => {
      
      })
      .addCase(DownloadContractAsync.fulfilled, (state, action) => {
       
      })
      .addCase(DownloadContractAsync.rejected, (state, action) => {
       
      });
  },
});
export const { updateBreakupGroup } = pricingRunResultSlice.actions;
// Action creators are generated for each case reducer function
export default pricingRunResultSlice.reducer;
